import React, { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";

import { Box, Grid } from "@mui/material";
import { HeaderContentBar } from "components/Layout";
import { AlertMessage, InputDropDown } from "components/UI";
import RePrintMode from "./partials/RePrintMode";
import NewPrintMode from "./partials/NewPrintMode";
import HistoryMode from "./partials/HistoryMode";
import { useGetBarcodeMode } from "service/barcodeprint";
import { userProfile } from "constant";

export const BarcodePrintScreen = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [mode, setMode] = useState("");
  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);

  // api
  const { data: barcodeModeData } = useGetBarcodeMode({
    dataOwner: userProfile.dataOwner,
  });

  useEffect(() => {
    setMsgAlert([]);
    setMsgError([]);
    setMode(queryParams.get("mode"));
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  return (
    <Box sx={{ padding: "1rem" }}>
      {/* Header */}
      <HeaderContentBar title="WDN910C0 : Barcode Print Screen" />
      {/* Alert Message */}
      <AlertMessage type={"warning"} message={msgError} />
      <AlertMessage type={"success"} message={msgAlert} />
      <Grid container sx={{ alignItems: "center" }}>
        <Grid item xs={5} style={{ display: "flex" }}>
          <strong style={{ margin: "auto 0px", width: "325px" }}>*Mode</strong>
          <InputDropDown
            id="select_mode"
            value={mode}
            onChange={e => setMode(e.target.value)}
            memu={barcodeModeData?.map(val => ({
              key: val?.cd,
              value: val?.value,
            }))}
            disabled
            placeholder="<All>"
            defaultValue=""
            required
          />
        </Grid>

        {/* New Print */}
        {mode === "N" && (
          <NewPrintMode mode={mode} setMsgError={setMsgError} setMsgAlert={setMsgAlert} functionId={"WDN910C0"} />
        )}

        {/* Re Print */}
        {mode === "R" && (
          <RePrintMode mode={mode} setMsgError={setMsgError} setMsgAlert={setMsgAlert} functionId={"WDN910C0"} />
        )}

        {/* History */}
        {mode === "H" && (
          <HistoryMode printType={mode} setMsgError={setMsgError} setMsgAlert={setMsgAlert} functionId={"WDN910C0"} />
        )}
      </Grid>
    </Box>
  );
};

export default BarcodePrintScreen;
