import { useCustomQuery } from "shared/services/base.service";
const BaseServicePath = process.env.REACT_APP_API_REPORT;

const DeliveryPlanActualApiService = () => ({
  useGetRPackageMakerCompanyListQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/report-common/get-maker-company`,
      method: "POST",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
      body: {
        dataOwner: "TMATH",
      },
    });
    return data;
  },
});

export const { useGetRPackageMakerCompanyListQuery } = DeliveryPlanActualApiService();
