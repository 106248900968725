import { useMutateData, useCustomQuery, useMutateDownload } from "shared/services/base.service";
import { COMMON_ENUM } from "shared/constants";
const BaseServicePath = process.env.REACT_APP_API_EXPORT_INVOICE;
const BaseServiceMaster = process.env.REACT_APP_API_PREFIX;

const ExportInvoiceCreationApiService = () => ({
  useExportInvoiceCategoryQuery: () => {
    const data = useCustomQuery({
      key: "export-invoice-category",
      url: `${BaseServicePath}/category/export-invoice/company/${COMMON_ENUM.COMPANY_CTG_CD_IMPORTER}/all`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useExportInvoiceReportFormatQuery: () => {
    const data = useCustomQuery({
      key: "export-invoice-report-format",
      url: `${BaseServiceMaster}/category/${COMMON_ENUM.C_SM_CTG_TMAP_EM}/${COMMON_ENUM.SUB_CAT_REPORT_FORMAT}/ASC`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useExportInvoiceOrderTypeQuery: () => {
    const data = useCustomQuery({
      key: "export-invoice-order-type",
      url: `${BaseServiceMaster}/category/${COMMON_ENUM.C_SM_CTG_COMMON}/${COMMON_ENUM.C_SM_SUB_CTG_ORDER_TYPE}/ASC`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useExportInvoiceImpCompanyQuery: () => {
    const data = useCustomQuery({
      key: "export-invoice-imp-company",
      url: `${BaseServicePath}/category/export-invoice/importer`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useExportInvoiceExpCompanyQuery: userLocation => {
    const data = useCustomQuery({
      key: "export-invoice-exp-company",
      url: `${BaseServicePath}/category/export-invoice/exporter/${userLocation}`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useExportInvoiceCategoryVanningPlantQuery: ({ etdDt, impCompanyId, expCd }) => {
    const data = useCustomQuery({
      key: ["export-invoice-vanning-plant", etdDt, impCompanyId, expCd],
      url: `${BaseServicePath}/category/export-invoice/vanning/plant`,
      method: "POST",
      body: {
        etdDt,
        impCompanyId,
        expCd,
      },
      enabled: true,
      select: data => data.result,
      keepPreviousData: false,
    });
    return data;
  },
  useExportInvoiceCategoryBrokerQuery: companyId => {
    const data = useCustomQuery({
      key: "export-invoice-category",
      url: `${BaseServicePath}/category/export-invoice/company/${COMMON_ENUM.COMPANY_CTG_CD_BROKER}/${companyId}`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useExportInvoiceCategoryCurrencyQuery: () => {
    const data = useCustomQuery({
      key: "export-invoice-category",
      url: `${BaseServiceMaster}/category/${COMMON_ENUM.C_SM_CTG_TMAP_EM}/${COMMON_ENUM.SUB_CAT_CURRENCY_CD}/ASC`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },

  useExportInvoiceIsAPMCUserQuery: companyAbbr => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/category/export-invoice/apmc-user/${companyAbbr}`,
      method: "GET",
      enabled: !!companyAbbr,
      select: data => data.result,
      keepPreviousData: false,
    });
    return data;
  },
  useExportInvoicePackingMonthQuery: ({ etdDt, impCompanyId, expCd }) => {
    const data = useCustomQuery({
      key: ["export-invoice-packing-month", etdDt, impCompanyId, expCd],
      url: `${BaseServicePath}/category/export-invoice/packing/month`,
      body: {
        etdDt,
        impCompanyId,
        expCd,
      },
      method: "POST",
      enabled: true,
      select: data => data.result,
      keepPreviousData: false,
    });

    return data;
  },

  useExportInvoiceShipmentEtdMutation: (impCompanyId, exporterCd, loginUserCompanyAbbr) => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async (
      body = {
        impCompanyId,
        exporterCd,
        loginUserCompanyAbbr,
      }
    ) => {
      return await mutate({
        url: `${BaseServicePath}/category/export-invoice/shipment-etd`,
        data: body,
        method: "POST",
      });
    };
  },
  useExportInvoiceShipmentCarrierMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async body => {
      return await mutate({
        url: `${BaseServicePath}/category/export-invoice/shipment-carrier`,
        data: body,
        method: "POST",
      });
    };
  },
  useExportInvoiceSearchMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async searchData => {
      return await mutate({
        url: `${BaseServicePath}/export-invoice/search`,
        data: searchData,
        method: "POST",
      });
    };
  },
  useExportInvoiceDownloadExcelMutation: () => {
    const { mutate } = useMutateDownload({
      invalidateKeys: [],
    });
    return async body => {
      return await mutate({
        url: `${BaseServicePath}/export-invoice/generateReport`,
        method: "POST",
        data: body,
      });
    };
  },
  useExportInvoiceSearchSubScreenMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async searchData => {
      return await mutate({
        url: `${BaseServicePath}/export-invoice-detail/search`,
        data: searchData,
        method: "POST",
      });
    };
  },
  useExportInvoiceCreateDetailVanningResultSubScreenMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async body => {
      return await mutate({
        url: `${BaseServicePath}/export-invoice-detail/create`,
        method: "POST",
        data: body,
      });
    };
  },
  useExportInvoiceEditDetailVanningResultSubScreenMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async (body, invoiceId) => {
      return await mutate({
        url: `${BaseServicePath}/export-invoice-detail/edit/${invoiceId}`,
        method: "PATCH",
        data: body,
      });
    };
  },
  useExportInvoiceCheckActionTypeQueryMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async (dataOwner, nonCommInvHId) => {
      return await mutate({
        url: `${BaseServicePath}/export-invoice-detail/edit/check-action-type/${dataOwner}/${nonCommInvHId}`,
        method: "GET",
      });
    };
  },
  useExportInvoiceSearchCancelVanningResultSubScreenMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async searchData => {
      return await mutate({
        url: `${BaseServicePath}/cancel-vanning/search`,
        data: searchData,
        method: "POST",
      });
    };
  },
  useExportInvoiceCancelCancelVanningResultSubScreenMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async body => {
      return await mutate({
        url: `${BaseServicePath}/cancel-vanning/cancel`,
        method: "POST",
        data: body,
      });
    };
  },
  //DELETE Invoice DELETE
  useExportInvoiceCancelInvoiceMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async (invoiceId, deleteData) => {
      return await mutate({
        url: `${BaseServicePath}/export-invoice/cancel/${invoiceId}`,
        data: deleteData,
        method: "DELETE",
      });
    };
  },
});

export const {
  //get
  useExportInvoiceCategoryQuery,
  useExportInvoiceReportFormatQuery,
  useExportInvoiceOrderTypeQuery,
  useExportInvoiceImpCompanyQuery,
  useExportInvoiceExpCompanyQuery,
  useExportInvoicePackingMonthQuery,
  useExportInvoiceCategoryVanningPlantQuery,
  useExportInvoiceCategoryBrokerQuery,
  useExportInvoiceCategoryCurrencyQuery,
  useExportInvoiceIsAPMCUserQuery,
  // post
  useExportInvoiceSearchMutation,
  useExportInvoiceDownloadExcelMutation,
  useExportInvoiceSearchSubScreenMutation,
  useExportInvoiceSearchCancelVanningResultSubScreenMutation,
  useExportInvoiceCancelCancelVanningResultSubScreenMutation,
  useExportInvoiceEditDetailVanningResultSubScreenMutation,
  useExportInvoiceCreateDetailVanningResultSubScreenMutation,
  useExportInvoiceShipmentCarrierMutation,
  useExportInvoiceShipmentEtdMutation,
  useExportInvoiceCheckActionTypeQueryMutation,
  // delete
  useExportInvoiceCancelInvoiceMutation,
} = ExportInvoiceCreationApiService();
