export const REPORT_STATUS = {
  ON_QUEUE: "On Queue",
  PROCESS: "Processing",
  DOWNLOAD: "Download",
  CANCELED_USER: "Canceled (User)",
  CANCELED_SYSTEM: "Canceled (Time Limit)",
  DELETE: "Deleted",
  ERROR_OCCUR: "Error Occurred",
  NO_DATA: "No data found",
};
