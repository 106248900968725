import { useCustomQuery } from "shared/services/base.service";
const BaseServicePath = process.env.REACT_APP_API_REPORT;

const ReportAgingStockApiService = () => ({
  useReportGetAvailableRPackageStatusQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/report-common/get-r-package-status-aging-stock`,
      method: "POST",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useReportGetAvailablePackStatusQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/report-common/get-available-pack-status`,
      method: "POST",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useReportGetDefaultMaximumAgingDayQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/report-common/get-default-maximum-aging-day`,
      method: "POST",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useReportGetDefaultTargetLineForWIP1EmptyQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/report-common/get-default-target-line-for-wip1-empty`,
      method: "POST",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useReportGetDefaultTargetLineForWIP2EmptyQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/report-common/get-default-target-line-for-wip2-empty`,
      method: "POST",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useReportGetDefaultTargetLineForFGEmptyQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/report-common/get-default-target-line-for-fg-empty`,
      method: "POST",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useReportGetDefaultTargetLineForFGPackQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/report-common/get-default-target-line-for-fg-pack`,
      method: "POST",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useReportGetDefaultTargetLineForNGEmptyQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/report-common/get-default-target-line-for-ng-empty`,
      method: "POST",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useReportGetDefaultTargetLineForNGPackQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/report-common/get-default-target-line-for-ng-pack`,
      method: "POST",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
});

export const {
  useReportGetAvailableRPackageStatusQuery,
  useReportGetAvailablePackStatusQuery,
  useReportGetDefaultMaximumAgingDayQuery,
  useReportGetDefaultTargetLineForWIP2EmptyQuery,
  useReportGetDefaultTargetLineForFGEmptyQuery,
  useReportGetDefaultTargetLineForFGPackQuery,
  useReportGetDefaultTargetLineForNGEmptyQuery,
  useReportGetDefaultTargetLineForNGPackQuery,
  useReportGetDefaultTargetLineForWIP1EmptyQuery,
} = ReportAgingStockApiService();
