import React, { Fragment, useEffect, useMemo, useState } from "react";
// Components
import { HeaderContentBar } from "components/Layout";
import { AlertMessage, InputButton } from "components/UI";
import { Box, Grid } from "@mui/material";
import CriteriaBar from "pages/Common/Monitoring/LogMonitoringScreen/LogMonitoringDetailScreen/components/CriteriaBar";
import ButtonBar from "pages/Common/Monitoring/LogMonitoringScreen/LogMonitoringDetailScreen/components/ButtonBar";
import FilterButtonBar from "pages/Common/Monitoring/LogMonitoringScreen/LogMonitoringDetailScreen/components/FilterButtonBar";
import TableBar from "pages/Common/Monitoring/LogMonitoringScreen/LogMonitoringDetailScreen/components/TableBar";
// Utils
import { responseErrors, messageDisplay, responseDownloadFileErrors } from "utils";
// import "dayjs/locale/en";
import { isEmpty } from "lodash";
//Hooks
import useLocalStorage from "shared/hooks/useLocalStorage";
import useColumn from "pages/Common/Monitoring/LogMonitoringScreen/LogMonitoringDetailScreen/hooks/useColumn";
//Type
import {
  DEFAULT_FORM,
  DEFAULT_STATE_DISPLAY,
} from "pages/Common/Monitoring/LogMonitoringScreen/constants/logMonitoring";
import { API_LOG_MONITORING } from "shared/constants/api-name/common/logMonitoring";
import { MSG_TYPE, ModeAction } from "state/enum";
import {
  BUTTON_VALUE,
  DEFAULT_LOCAL_STATE,
  DEFAULT_LOCAL_STATE_SUMMARY,
  FIRST_PAGE,
  PAGINATION,
  ROW_PER_PAGE,
} from "shared/constants";
// Service
import {
  useLogMonitorGetModuleQuery,
  useLogMonitorGetFunctionQuery,
  useLogMonitorGetLogStatusDetailQuery,
  useLogMonitorGetLevelQuery,
  useLogDetailSearchMutation,
  useLogMonitorDownloadExcelMutation,
} from "shared/services/common/monitoring";
import { userProfile } from "constant";
import dayjs from "dayjs";
import { useLoadingContext } from "shared/contexts/LoadingContext";
import { getLocalDate } from "utils/init-config-date";

const LogMonitoringDetailScreen = () => {
  const functionId = "WSTD3011";

  const { startLoading, stopLoading } = useLoadingContext();

  const [logDetail, setLogDetail] = useLocalStorage("logDetail", DEFAULT_LOCAL_STATE);
  const [logSummary, setLogSummary] = useLocalStorage("logSummary", DEFAULT_LOCAL_STATE_SUMMARY);
  // const navigate = useNavigate();
  // table
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  //from
  const [mode, setMode] = useState(ModeAction.VIEW);
  const [onSearch, setOnSearch] = useState(false);
  const [onRefresh, setOnRefresh] = useState(false);
  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);

  const [displayColumns, setDisplayColumns] = useState(DEFAULT_STATE_DISPLAY);
  const [columnsHidden, setColumnsHidden] = useState(DEFAULT_STATE_DISPLAY);
  const [searchForm, setSearchForm] = useState({
    [API_LOG_MONITORING.DATA_OWNER]: userProfile.dataOwner,
    [API_LOG_MONITORING.MODULE_ID]: "",
    [API_LOG_MONITORING.FUNCTION_ID]: "",
    [API_LOG_MONITORING.STATUS_ID]: "",
    [API_LOG_MONITORING.LEVEL_ID]: "",
    [API_LOG_MONITORING.USER_ID]: "",
    [API_LOG_MONITORING.APP_ID]: "",
    [API_LOG_MONITORING.DATE_FORM]: getLocalDate().format("DD/MM/YYYY"),
    [API_LOG_MONITORING.DATE_TO]: getLocalDate().format("DD/MM/YYYY"),
  });

  // pagination
  const [pagination, setPagination] = useState({});
  const [pageNumber, setPageNumber] = useState(1);

  // api
  const { data: moduleData } = useLogMonitorGetModuleQuery();
  const { data: functionData } = useLogMonitorGetFunctionQuery(searchForm?.moduleId ? searchForm?.moduleId : "all");
  const { data: logStatusDetailData } = useLogMonitorGetLogStatusDetailQuery();
  const searchLogMonitoring = useLogDetailSearchMutation();
  const deleteLog = useLogMonitorDownloadExcelMutation();

  const cdOrder = { PS: 1, I: 2, W: 3, E: 4, F: 5 };
  const { data: levelData } = useLogMonitorGetLevelQuery();
  levelData?.sort((a, b) => cdOrder[a.statusId] - cdOrder[b.statusId]);

  const handleSearchForm = async val => {
    try {
      if (!val.moduleId) {
        setSearchForm(DEFAULT_FORM);
        return;
      }
      setSearchForm({
        [API_LOG_MONITORING.DATA_OWNER]: isEmpty(val?.dataOwner) ? searchForm?.dataOwner : val?.dataOwner,
        [API_LOG_MONITORING.MODULE_ID]: isEmpty(val?.moduleId) ? "" : val?.moduleId,
        [API_LOG_MONITORING.FUNCTION_ID]: isEmpty(val?.functionId) ? "" : val?.functionId,
        [API_LOG_MONITORING.STATUS_ID]: "",
        [API_LOG_MONITORING.LEVEL_ID]: val?.levelId,
        [API_LOG_MONITORING.USER_ID]: val?.userId,
        [API_LOG_MONITORING.APP_ID]: val?.appId,
        [API_LOG_MONITORING.DATE_FORM]: !val?.dateFrom ? getLocalDate().format("DD/MM/YYYY") : val?.dateFrom,
        [API_LOG_MONITORING.DATE_TO]: !val?.dateTo ? getLocalDate().format("DD/MM/YYYY") : val?.dateTo,
      });
      const body = {
        [API_LOG_MONITORING.DATA_OWNER]: isEmpty(val?.dataOwner) ? searchForm?.dataOwner : val?.dataOwner,
        [API_LOG_MONITORING.MODULE_ID]: isEmpty(val?.moduleId) ? "" : val?.moduleId,
        funtionId: isEmpty(val?.functionId) ? "" : val?.functionId,
        [API_LOG_MONITORING.STATUS_ID]: "",
        [API_LOG_MONITORING.LEVEL_ID]: val?.levelId,
        [API_LOG_MONITORING.USER_ID]: val.userId,
        [API_LOG_MONITORING.APP_ID]: val?.appId,
        [API_LOG_MONITORING.DATE_FORM]: !val?.dateFrom ? "" : val?.dateFrom,
        [API_LOG_MONITORING.DATE_TO]: !val?.dateTo ? "" : val?.dateTo,
        [PAGINATION.PAGE_NUMBER]: FIRST_PAGE,
        [PAGINATION.ROW_PER_PAGE]: ROW_PER_PAGE,
      };

      // validate
      startLoading();
      const { searchData, data } = await getSearch(body);
      stopLoading();
      if (!data.length) {
        setMode(ModeAction.VIEW);
        setMsgError([messageDisplay({ type: MSG_TYPE.ERROR, code: "MSTD0059AERR", msg: "" })]);
        setRows([]);
        setOnSearch(false);
        setPagination({});
        setPageNumber(FIRST_PAGE);
        setColumnsHidden({
          appId: !displayColumns.appId,
          moduleId: !displayColumns.moduleId,
          functionId: !displayColumns.functionId,
          status: !displayColumns.status,
          level: !displayColumns.levelId,
          userId: !displayColumns.userId,
        });
        return;
      }
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
      setColumnsHidden({
        appId: !displayColumns.appId,
        moduleId: !displayColumns.moduleId,
        functionId: !displayColumns.functionId,
        status: !displayColumns.status,
        level: !displayColumns.levelId,
        userId: !displayColumns.userId,
      });
      setOnSearch(true);

      return;
    } catch (error) {
      stopLoading();
      console.error(error);
    }
  };
  const handleDownload = async () => {
    try {
      setMsgAlert([]);
      setMsgError([]);
      const body = {
        [API_LOG_MONITORING.DATA_OWNER]: searchForm?.dataOwner,
        [API_LOG_MONITORING.MODULE_ID]: isEmpty(searchForm?.moduleId) ? "" : searchForm?.moduleId,
        [API_LOG_MONITORING.FUNCTION_ID]: isEmpty(searchForm?.functionId) ? "" : searchForm?.functionId,
        [API_LOG_MONITORING.STATUS_ID]: "",
        [API_LOG_MONITORING.LEVEL_ID]: searchForm?.levelId,
        [API_LOG_MONITORING.USER_ID]: searchForm?.userId,
        [API_LOG_MONITORING.APP_ID]: searchForm?.appId,
        [API_LOG_MONITORING.DATE_FORM]: !searchForm?.dateFrom ? "" : searchForm?.dateFrom,
        [API_LOG_MONITORING.DATE_TO]: !searchForm?.dateTo ? "" : searchForm?.dateFrom,
      };
      startLoading();
      await deleteLog(body);
      stopLoading();
    } catch (error) {
      stopLoading();
      const err = responseDownloadFileErrors(error);
      setMsgError(err);
      window.scroll(0, 0);
      return;
    }
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks, react-hooks/exhaustive-deps
  const columns = useMemo(() => useColumn({ mode, rows }), [mode, rows, onRefresh]);
  const getSearch = async body => {
    try {
      startLoading();
      const searchData = await searchLogMonitoring(body);
      stopLoading();
      const data =
        searchData?.result?.data?.map((item, index) => ({
          no: index + 1,
          [API_LOG_MONITORING.ROW_NUMBER]: item.rowNumber,
          [API_LOG_MONITORING.DATE]: item.date,
          [API_LOG_MONITORING.APP_ID]: item.aplId,
          [API_LOG_MONITORING.MODULE_ID]: item.moduleId,
          [API_LOG_MONITORING.LEVEL_ID]: item.messageType,
          [API_LOG_MONITORING.FUNCTION_ID]: item.functionId,
          [API_LOG_MONITORING.STATUS]: item.status,
          [API_LOG_MONITORING.MESSAGE_TYPE]: item.messageType,
          [API_LOG_MONITORING.MESSAGE]: item.message,
          [API_LOG_MONITORING.USER_ID]: item.userId,
        })) ?? [];
      return { searchData, data };
    } catch (error) {
      stopLoading();
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };
  const handleBack = () => {
    try {
      // const logState = {
      //   dataOwner: logDetail.dataOwner,
      //   moduleId: logDetail?.moduleId,
      //   functionId: logSummary.functionId ? logSummary.functionId : "",
      //   statusId: logSummary.statusId ? logSummary.statusId : "",
      //   userId: logSummary?.userId ? logSummary?.userId : "",
      //   appId: logSummary?.appId ? logSummary?.appId : "",
      //   dateFrom: logSummary?.dateFrom ? logSummary?.dateFrom : "",
      //   dateTo: logSummary?.dateTo ? logSummary?.dateTo : "",
      // };
      setLogDetail(DEFAULT_LOCAL_STATE);
      setLogSummary(DEFAULT_LOCAL_STATE_SUMMARY);
      window.close();
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    handleSearchForm(logDetail);

    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logDetail]);
  return (
    <Fragment>
      <Box sx={{ padding: "1rem" }}>
        <HeaderContentBar title="WSTD3011 : Log Monitoring Detail Screen" />
        <AlertMessage type={"warning"} message={msgError} />
        <AlertMessage type={"success"} message={msgAlert} />
        <CriteriaBar
          searchForm={searchForm}
          setSearchForm={setSearchForm}
          dataList={{ moduleData, functionData, logStatusDetailData, levelData }}
        />
      </Box>
      <Box sx={{ padding: "0 1rem 1rem 1rem" }}>
        <ButtonBar
          setRows={setRows}
          setMode={setMode}
          setOnSearch={setOnSearch}
          searchForm={searchForm}
          setSearchForm={setSearchForm}
          getSearch={getSearch}
          column={{ setColumnsHidden, displayColumns, setDisplayColumns }}
          setMsg={{ setMsgAlert, setMsgError }}
          rowSelection={{ setRowSelectionModel, setRowModesModel }}
          pagination={{ setPagination, setPageNumber }}
          functionId={functionId}
        />
      </Box>
      <Box border="1px solid gray" padding={2}>
        <FilterButtonBar column={{ setColumnsHidden, displayColumns, setDisplayColumns, setOnRefresh }} />
      </Box>
      <>
        <Box sx={{ padding: "1rem" }} marginTop="24px">
          <Grid container spacing={2}>
            {onSearch && (
              <Grid item xs={12}>
                <TableBar
                  mode={mode}
                  setMode={setMode}
                  rows={rows}
                  setRows={setRows}
                  columns={columns}
                  searchForm={searchForm}
                  onSearch={onSearch}
                  columnsHidden={columnsHidden}
                  getSearch={getSearch}
                  setMsg={{ setMsgAlert, setMsgError }}
                  rowSelection={{ rowSelectionModel, setRowSelectionModel, rowModesModel, setRowModesModel }}
                  pagination={{ pagination, setPagination, pageNumber, setPageNumber }}
                />
              </Grid>
            )}
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
              <InputButton id="btn_back" value={BUTTON_VALUE.BACK} onClick={handleBack} name={`${functionId}Back`} />
              {onSearch && (
                <InputButton
                  id="btn_download"
                  value={BUTTON_VALUE.DOWNLOAD}
                  onClick={handleDownload}
                  name={`${functionId}Download`}
                />
              )}
            </Grid>
          </Grid>
        </Box>
      </>
    </Fragment>
  );
};

export default LogMonitoringDetailScreen;
