import { Divider, Grid } from "@mui/material";
import { InputButton } from "components/UI";
import React from "react";
import { BUTTON_VALUE } from "shared/constants";

export default function ButtonAction(props) {
  const { handleDownload, setFrom, defaultFrom, disabled, setDisabled, clearMsg, firstField } = props;

  const handleClear = () => {
    setFrom(defaultFrom);
    setDisabled(false);
    clearMsg();
  };

  return (
    <Grid container spacing={1} sx={{ alignItems: "center", mt: 2 }}>
      <Grid item xs={12} textAlign="end">
        <InputButton
          id="generate-reports"
          value={BUTTON_VALUE.GENERATE_REPORTS}
          onClick={handleDownload}
          disabled={disabled}
          noRoleRequired={true}
        />
        <InputButton
          id="button-clear"
          value={BUTTON_VALUE.CLEAR}
          disabled={false}
          onClick={handleClear}
          style={{ marginRight: "0" }}
          onKeyDown={e => {
            if (e.key === "Tab") {
              e.preventDefault();
              document.getElementById(firstField).focus();
            }
          }}
          noRoleRequired={true}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider style={{ paddingBottom: "8px" }} />
      </Grid>
    </Grid>
  );
}
