import { Box } from "@mui/material";
import { HeaderContentBar } from "components/Layout";
import { AlertMessage, PopupDialog } from "components/UI";
import { userProfile } from "constant";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  useExportInvoiceCategoryQuery,
  useExportInvoiceReportFormatQuery,
  useExportInvoiceSearchMutation,
  useExportInvoiceIsAPMCUserQuery,
} from "shared/services/invoice/export-invoice-creation";
import { ModeAction } from "state/enum";
import CriterialSection from "./components/CriterialSection";
import { DEFAULT_EXPORT_INVOICE_INFO, DEFAULT_STATE_WDN95011, DEFAULT_STATE_WDN95012 } from "./constants/constants";
import ButtonCriterial from "./components/ButtonCriterial";
import { COMMON_ENUM, FIRST_PAGE, PAGINATION, ROW_PER_PAGE } from "shared/constants";
import ButtonAction from "./components/ButtonAction";
import { useColumn } from "./hooks/useColumn";
import { useButtonMode } from "./hooks/useButtonMode";
import TableSection from "./components/TableSection";
import CreateInvoiceSubScreen from "./components/CreateInvoiceSubScreen/CreateInvoiceSubScreen";
import { transformData } from "./hooks/tranformData";
import { responseErrors } from "utils";
import CancelVanningResultSubScreen from "./components/CancelVanningResultSubScreen/CancelVanningResultSubScreen";
import { useSelector } from "react-redux";

const ExportInvoiceCreationMaintenanceScreen = () => {
  const userInfo = useSelector(state => state.auth.user);

  // Form
  const [form, setForm] = useState(DEFAULT_EXPORT_INVOICE_INFO);
  const [formSubScreen, setFormSubScreen] = useState(DEFAULT_STATE_WDN95011);
  const [formCancelSubScreen, setFormCancelSubScreen] = useState(DEFAULT_STATE_WDN95012);
  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);
  const [mode, setMode] = useState(ModeAction.VIEW);
  const [onSearch, setOnSearch] = useState(false);
  const [actionMode, setActionMode] = useState();

  // Table
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  //Button State
  const [searchBtn, setSearchBtn] = useState(false);
  const [clearBtn, setClearBtn] = useState(false);
  const [addBtn, setAddBtn] = useState(false);
  const [copyBtn, setCopyBtn] = useState(false);
  const [editBtn, setEditBtn] = useState(false);
  const [deleteBtn, setDeleteBtn] = useState(false);

  const [pagination, setPagination] = useState({});
  const [pageNumber, setPageNumber] = useState(FIRST_PAGE);

  // API
  const { data: importerData } = useExportInvoiceCategoryQuery();
  const { data: reportFormatData } = useExportInvoiceReportFormatQuery();
  const searchExportInfo = useExportInvoiceSearchMutation();
  const { data: checkIsAPMCUser } = useExportInvoiceIsAPMCUserQuery(userInfo.companyAbbr);
  // State sub screen
  const [openPopupSub, setOpenPopupSub] = useState({
    open: false,
    isEdit: false,
    isAPMCUser: false,
    data: {},
    actionType: COMMON_ENUM.SC_MODE_VIEW,
  });
  const [openPopupCancelSub, setOpenPopupCancelSub] = useState(false);

  // columns
  const columns = useMemo(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useColumn({
      rows,
      mode,
      dataList: {},
    });
  }, [rows, mode]);

  useEffect(
    () =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useButtonMode({
        mode,
        stateButton: {
          setSearchBtn,
          setClearBtn,
          setAddBtn,
          setCopyBtn,
          setEditBtn,
          setDeleteBtn,
        },
      }),
    [mode]
  );

  const refreshRowsTable = async importerId => {
    try {
      if (openPopupSub.actionType === COMMON_ENUM.SC_MODE_CREATE_INVOICE) {
        setForm(prev => ({ ...prev, importerId: importerId }));
      }

      const body = {
        dataOwner: userProfile.dataOwner, // TODO: mock constants here
        importerId: importerId ? importerId : form?.importerId,
        vesselName: form?.vesselName,
        invoiceNo: form?.invoiceNo,
        etdFrom: form?.etdFrom,
        etdTo: form?.etdTo,
        loginUserCompanyAbbr: userInfo.companyAbbr,
        [PAGINATION.PAGE_NUMBER]: FIRST_PAGE,
        [PAGINATION.ROW_PER_PAGE]: ROW_PER_PAGE,
      };
      const searchData = await searchExportInfo(body);

      if (!searchData?.result?.data?.length) {
        setOnSearch(false);
        setPageNumber(FIRST_PAGE);
        setMode(ModeAction.VIEW);
        setRows([]);
        setRowSelectionModel([]);
        setRowModesModel({});
        setPagination({});
        return false;
      }
      setRowSelectionModel([]);
      setRowModesModel({});
      setMode(ModeAction.VIEW);
      const data = transformData(searchData?.result?.data);
      setRows(data);
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setOnSearch(true);
      return true;
    } catch (error) {
      // filter when error is no data found no need to display
      const { response } = error;
      if (response && response?.data?.errors?.length > 0) {
        const code = response?.data?.errors[0].code;
        if (code === "MSTD0059AERR") {
          setOnSearch(false);
          setPageNumber(FIRST_PAGE);
          setMode(ModeAction.VIEW);
          setRows([]);
          setRowSelectionModel([]);
          setRowModesModel({});
          setPagination({});
          return false;
        }
      }
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return false;
    }
  };

  return (
    <Fragment>
      <Box sx={{ padding: "1rem" }}>
        <HeaderContentBar title="WDN95010 : Export Invoice Creation Screen" />
        <AlertMessage type={"warning"} message={msgError} />
        <AlertMessage type={"success"} message={msgAlert} />
      </Box>
      <Box sx={{ padding: "0 2rem 1rem 2rem" }}>
        <CriterialSection
          form={form}
          setForm={setForm}
          mode={mode}
          dataList={{
            importerData,
          }}
        />
        <ButtonCriterial
          form={form}
          setForm={setForm}
          onSearch={onSearch}
          setOnSearch={setOnSearch}
          mode={mode}
          setMode={setMode}
          rows={rows}
          setRows={setRows}
          stateButton={{ searchBtn, clearBtn }}
          setMsg={{ setMsgAlert, setMsgError }}
          rowSelection={{
            rowModesModel,
            setRowModesModel,
            rowSelectionModel,
            setRowSelectionModel,
          }}
          pagination={{ pagination, setPagination, pageNumber, setPageNumber }}
          functionId={"WDN95010"}
        />
      </Box>
      {/* {onSearch && ( */}
      <Box>
        <ButtonAction
          form={form}
          setForm={setForm}
          mode={mode}
          setMsg={{ setMsgAlert, setMsgError }}
          setOpenPopupSub={setOpenPopupSub}
          setOpenPopupCancelSub={setOpenPopupCancelSub}
          dataList={{
            reportFormatData,
          }}
          rows={rows}
          rowSelection={{
            rowModesModel,
            setRowModesModel,
            rowSelectionModel,
            setRowSelectionModel,
          }}
          onSearch={onSearch}
          refreshRowsTable={refreshRowsTable}
          setFormSubScreen={setFormSubScreen}
          isAPMCUser={checkIsAPMCUser?.isAPMCUser}
          functionId={"WDN95010"}
          actionMode={actionMode}
          setActionMode={setActionMode}
        />
      </Box>
      {/* )} */}
      {onSearch && (
        <Box sx={{ pr: "1rem", pl: "1rem" }}>
          <TableSection
            mode={mode}
            setMode={setMode}
            rows={rows}
            setRows={setRows}
            columns={columns}
            form={form}
            refreshRowsTable={refreshRowsTable}
            setOnSearch={setOnSearch}
            setMsg={{ setMsgAlert, setMsgError }}
            rowSelection={{
              rowModesModel,
              setRowModesModel,
              rowSelectionModel,
              setRowSelectionModel,
            }}
            pagination={{
              pagination,
              setPagination,
              pageNumber,
              setPageNumber,
            }}
            dataList={{
              importerData,
              // consigneeList,
              // brokerList,
            }}
          />
        </Box>
      )}
      <PopupDialog
        id="container"
        maxWidth="xl"
        onFooter={true}
        onOpen={openPopupSub.open}
        content={
          <CreateInvoiceSubScreen
            alertMain={{ setMsgAlert, setMsgError }}
            onOpen={openPopupSub}
            setOnOpen={setOpenPopupSub}
            form={formSubScreen}
            setForm={setFormSubScreen}
            // form={form}
            // setForm={setForm}
            refreshRowsTableMain={refreshRowsTable}
          />
        }
      />
      <PopupDialog
        id="container"
        maxWidth="xl"
        onFooter={true}
        onOpen={openPopupCancelSub}
        content={
          <CancelVanningResultSubScreen
            alertMain={{ setMsgAlert, setMsgError }}
            onOpen={openPopupCancelSub}
            setOnOpen={setOpenPopupCancelSub}
            form={formCancelSubScreen}
            setForm={setFormCancelSubScreen}
          />
        }
      />
    </Fragment>
  );
};

export default ExportInvoiceCreationMaintenanceScreen;
