import React from "react";
import { Grid } from "@mui/material";
import { InputButton } from "components/UI";
//Util
import { responseErrors, messageDisplay } from "utils";
// Type
import { MSG_TYPE, MessageType, ModeAction } from "state/enum";
import {
  API_LOG_MONITORING,
  COMMON_ENUM,
  DEFAULT_LOCAL_STATE,
  FIRST_PAGE,
  PAGINATION,
  ROW_PER_PAGE,
} from "shared/constants";
// Service
import { useLogSummarySearchMutation } from "shared/services/common/monitoring";
import { validationSearchForm } from "utils/validation";
import { userProfile } from "constant";
import { useLoadingContext } from "shared/contexts/LoadingContext";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
export default function ButtonBar(props) {
  const {
    setMode,
    setRows,
    searchForm,
    setSearchForm,
    setLogDetail,
    onSearch,
    setOnSearch,
    setRowSelectionModel,
    setRowModesModel,
    setMsg: { setMsgError, setMsgAlert },
    pagination: { pagination, setPagination, pageNumber, setPageNumber },
    functionId,
  } = props;
  const { startLoading, stopLoading } = useLoadingContext();
  const searchSummary = useLogSummarySearchMutation();
  const dateTime = useSelector(state => state.common.dateTime);
  const handleSearch = async e => {
    try {
      e.preventDefault();
      setRows([]);
      setMsgError([]);
      setMsgAlert([]);
      const body = {
        [API_LOG_MONITORING.DATA_OWNER]: searchForm?.dataOwner,
        [API_LOG_MONITORING.MODULE_ID]: searchForm?.moduleId,
        funtionId: searchForm?.functionId,
        [API_LOG_MONITORING.STATUS_ID]: searchForm?.statusId,
        [API_LOG_MONITORING.USER_ID]: searchForm?.userId,
        [API_LOG_MONITORING.APP_ID]: searchForm?.appId,
        [API_LOG_MONITORING.DATE_FORM]: !searchForm?.dateFrom ? "" : searchForm?.dateFrom,
        [API_LOG_MONITORING.DATE_TO]: !searchForm?.dateTo ? "" : searchForm?.dateTo,
        [PAGINATION.PAGE_NUMBER]: FIRST_PAGE,
        [PAGINATION.ROW_PER_PAGE]: ROW_PER_PAGE,
      };

      // validate
      startLoading();
      const searchData = await searchSummary(body);
      stopLoading();
      const data =
        searchData?.result?.data?.map((item, index) => ({
          no: index + 1,
          [API_LOG_MONITORING.ROW_NUMBER]: item?.rowNumber,
          [API_LOG_MONITORING.APP_ID]: item?.aplId,
          [API_LOG_MONITORING.MODULE_ID]: item?.moduleId,
          [API_LOG_MONITORING.FUNCTION_ID]: item?.functionId,
          [API_LOG_MONITORING.START_DATE]: item?.startDate,
          [API_LOG_MONITORING.END_DATE]: item?.endDate,
          [API_LOG_MONITORING.START_TIME]: item?.startDateT,
          [API_LOG_MONITORING.END_TIME]: item?.endDateT,
          [API_LOG_MONITORING.USER_ID]: item?.userId,
          [API_LOG_MONITORING.STATUS]: item?.status,
          [API_LOG_MONITORING.MESSAGE]: item?.message,
          [API_LOG_MONITORING.FUNCTION_NAME]: item?.funtionName,
          [API_LOG_MONITORING.MODULE_NAME]: item?.moduleName,
        })) ?? [];

      if (!data.length) {
        setMsgError([messageDisplay({ type: MSG_TYPE.ERROR, code: "MSTD0059AERR", msg: "" })]);
        return;
      }
      setRows(data);
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setOnSearch(true);
    } catch (error) {
      stopLoading();
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };
  //   handleClear
  const handleClear = () => {
    setMsgError([]);
    setMsgAlert([]);
    setMode(ModeAction.VIEW);
    setOnSearch(false);
    setSearchForm({
      [API_LOG_MONITORING.DATA_OWNER]: userProfile.dataOwner,
      [API_LOG_MONITORING.MODULE_ID]: "",
      [API_LOG_MONITORING.FUNCTION_ID]: "",
      [API_LOG_MONITORING.STATUS_ID]: "",
      [API_LOG_MONITORING.LEVEL_ID]: "",
      [API_LOG_MONITORING.USER_ID]: "",
      [API_LOG_MONITORING.APP_ID]: "",
      [API_LOG_MONITORING.DATE_FORM]: dayjs(dateTime, COMMON_ENUM.DATETIME_FORMAT).format("DD/MM/YYYY"),
      [API_LOG_MONITORING.DATE_TO]: dayjs(dateTime, COMMON_ENUM.DATETIME_FORMAT).format("DD/MM/YYYY"),
    });
    setPageNumber(FIRST_PAGE);
    setPagination({});
    setRowSelectionModel([]);
    setRowModesModel({});
    setRows([]);

    setLogDetail(DEFAULT_LOCAL_STATE);
    // setLogSummary(DEFAULT_LOCAL_STATE_SUMMARY);
    document.getElementById("select_moduleId").focus();
  };

  return (
    <Grid container spacing={2} sx={{ mb: 4 }}>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
        <InputButton id="btn_search" value={"Search"} onClick={handleSearch} name={`${functionId}Search`} />
        <InputButton
          id="btn_clear"
          value={"Clear"}
          onClick={handleClear}
          onKeyDown={e => {
            if (!onSearch && e.key === "Tab") {
              e.preventDefault();
              document.getElementById("select_moduleId").focus();
            }
          }}
          name={`${functionId}Search`}
        />
      </Grid>
    </Grid>
  );
}
