import React, { useMemo } from "react";
import { Box, Pagination } from "@mui/material";
import { ModeAction } from "state/enum";
import { isEmpty } from "lodash";
const PaginationTable = ({ pagination, pageNumber, handleChangePagination, mode = ModeAction.VIEW }) => {
  useMemo(() => pagination, [pagination]);

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>Total record: {pagination?.total || 0}</Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Pagination
          count={isEmpty(pagination) ? 1 : Math.ceil((pagination?.total || 0) / (pagination?.rowsPerPage || 0))}
          page={pageNumber}
          onChange={handleChangePagination}
          showFirstButton
          showLastButton
          disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
        />
      </Box>
    </>
  );
};

export default PaginationTable;
