// import React, { createContext, useContext, useEffect, useState } from "react";
// import { useMsal } from "@azure/msal-react";

// const AuthContext = createContext();

// const AuthProvider = ({ children }) => {
//   const { instance, accounts } = useMsal();
//   const [isInitialized, setIsInitialized] = useState(false);

//   useEffect(() => {
//     const initializeMsal = async () => {
//       await instance.initialize();
//       setIsInitialized(true);
//     };

//     initializeMsal();
//   }, [instance]);

//   if (!isInitialized) {
//     return null; // or a loading spinner
//   }

//   if (accounts.length === 0) {
//     instance.loginRedirect();
//   }

//   const user = accounts[0] || null;

//   return <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>;
// };
// export { AuthProvider };
// export const useAuth = () => {
//   return useContext(AuthContext);
// };
import React, { createContext, useState, useContext } from "react";

const AuthContext = createContext();

export const AuthProvider = async ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  console.log("AuthProvider");

  const login = () => {
    try {
      setIsAuthenticated(true);
    } catch (error) {
      Promise.reject(error);
    }
  };

  const logout = () => {
    setIsAuthenticated(false);
  };

  return <AuthContext.Provider value={{ isAuthenticated, login, logout }}>{children}</AuthContext.Provider>;
};

// export const useAuth = () => useContext(AuthContext);
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
