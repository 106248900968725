import { AppBar, Box, Toolbar, styled } from "@mui/material";
import React from "react";
// import Icon from "assets/images/icon-toyota.png";
// import Toyota from "assets/images/white-toyota.jpg";
import Toyota from "assets/images/red-toyota.png";
const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  backgroundColor: "#000",
  position: "relative",
  [theme.breakpoints.up("xs")]: {
    minHeight: "4rem",
  },
}));

// const Search = styled("div")(({ theme }) => ({
//   backgroundColor: "white",
//   padding: "0 20px",
//   borderRadius: theme.shape.borderRadius,
//   width: "40%",
// }));

const Navbar = () => {
  return (
    <AppBar position="fixed" sx={{ zIndex: 1201 }}>
      <StyledToolbar>
        <Box ml={2} columnGap={1.5} sx={{ display: { xs: "none", sm: "flex" } }}>
          <a href="/" style={{ position: "relative" }}>
            {/* <img src={Icon} alt="logoIcon" style={{ position: "absolute", height: "30px", top: "-16px" }} /> */}
            <img src={Toyota} alt="logoName" style={{ position: "absolute", height: "69px", top: "-35px" }} />
          </a>
        </Box>
      </StyledToolbar>
    </AppBar>
  );
};

export default Navbar;
