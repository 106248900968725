import * as React from "react";
import { Box, List, styled, Drawer, ListItem, CircularProgress } from "@mui/material";
import IconButton from "assets/images/icon-toyota.png";
import { MenuItem } from "components/UI";
// import { useNavigate } from "react-router-dom";
// import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
// import { clearSession } from "shared/stores/reducers/authSlice";

const openedMixin = theme => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  width: 240,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + auto)`,
  },
});

const closedMixin = theme => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: prop => prop !== "open",
})(({ theme, open }) => ({
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Sidebar({ items }) {
  const [open] = React.useState(true);
  // const navigate = useNavigate();
  // const dispatch = useDispatch();

  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  // const handleLogout = e => {
  //   e.preventDefault();
  //   console.log("logout");
  //   dispatch(clearSession());
  //   navigate("/");
  // };

  return (
    <Box flex={2}>
      <StyledDrawer variant="permanent" open={open}>
        <DrawerHeader>
          <img alt="logo" src={IconButton} height={"32px"} />
        </DrawerHeader>
        <Box
          sx={{
            display: "flex",
            maxWidth: "290px",
            height: "100vh",
            justifyContent: "space-between",
            flexDirection: "column",
          }}>
          {!isAuthenticated ? (
            <CircularProgress />
          ) : (
            <List dense>
              <ListItem
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                }}
              />
              {items?.map((item, key) => (
                <MenuItem key={key} item={item} />
              ))}
            </List>
          )}
          {/* <form onSubmit={handleLogout}>
            <Button type="submit" variant="contained" color="secondary" fullWidth sx={{ marginTop: 2 }}>
              Logout
            </Button>
          </form> */}
        </Box>
      </StyledDrawer>
    </Box>
  );
}
