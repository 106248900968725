import React from "react";
import { AppBar, Typography } from "@mui/material";
import { userProfile } from "constant";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { useGetDateTimeQuery } from "shared/services/common/service-date-time";
import { setCommonDateTime } from "shared/stores/reducers/commonSlice";
import { COMMON_ENUM } from "shared/constants";

export const HeaderContentBar = ({ title = "", isAdmin = false, setIsAdmin = undefined, isHideHeader = false }) => {
  const style = {
    areaHeader: {
      display: "flex",
      justifyContent: "space-between",
      height: 35,
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    },
    bgUserNameBar: {
      backgroundColor: "#e2dfdf",
      border: "1px solid #e2dfdf",
    },
    bgTitleBar: {
      backgroundColor: "#969191",
      border: "1px solid #969191",
      color: "#ffff",
    },
    shadowNone: {
      boxShadow: "none",
    },
    appBar: {
      marginBottom: 15,
    },
  };

  const styleAppBar = {
    ...style.shadowNone,
    ...style.appBar,
  };

  const styleUserBar = {
    ...style.areaHeader,
    ...style.bgUserNameBar,
  };

  const styleTitleBar = {
    ...style.areaHeader,
    ...style.bgTitleBar,
  };
  const { data: dateTime } = useGetDateTimeQuery();

  const handleRole = () => {
    if (setIsAdmin) setIsAdmin(!isAdmin);
  };
  const getDateTime = () => {
    let date = dayjs().format("DD MMM YYYY HH:mm");
    try {
      if (dateTime && dateTime?.dayjsFormat) {
        dispatch(setCommonDateTime(dateTime?.dayjsFormat));
        return dayjs(dateTime?.dayjsFormat, COMMON_ENUM.DATETIME_FORMAT).format("DD MMM YYYY HH:mm");
      }
      return date;
    } catch (error) {
      console.log(error);
      return date;
    }
  };
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const USER = `User: ${user?.displayName ?? userProfile.displayName}`;
  const ADMIN = `Admin: ${user?.displayName ?? userProfile.displayName}`;
  return (
    <AppBar position="static" color="secondary" style={styleAppBar} enableColorOnDark>
      <div style={styleUserBar}>
        {!isHideHeader ? (
          <>
            <div id="hdlb" style={{ color: "red", fontWeight: "bold", margin: "auto 15px" }}>
              POLYGON
            </div>
            <Typography
              component="button"
              onClick={handleRole}
              sx={{ border: "none", backgroundColor: "#e2dfdf", margin: "auto 15px" }}>
              {isAdmin ? ADMIN : USER}
            </Typography>
          </>
        ) : null}
      </div>
      <div style={styleTitleBar}>
        <div style={{ margin: "auto 15px" }}>{title}</div>
        <div style={{ margin: "auto 15px" }}>{getDateTime()}</div>
      </div>
    </AppBar>
  );
};
