import { loginRequest, graphConfig, msalConfig } from "../msalConfig";
import { PublicClientApplication } from "@azure/msal-browser";

export const msalInstance = new PublicClientApplication(msalConfig);
export const callMsGraph = async () => {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    // msalInstance.loginRedirect();
    throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
  }

  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    scopes: [process.env.REACT_APP_SCOPES],
    account: account,
  });
  // .then(response => {
  //   console.log("Token acquired silently:", response.accessToken);
  // })
  // .catch(error => {
  //   console.error("Silent token acquisition failed:", error);
  //   // Fallback to redirect login if silent acquisition fails
  //   msalInstance.loginRedirect();
  // });

  const headers = new Headers();
  const bearer = `Bearer ${response.accessToken}`;
  // console.log("response.accessToken :>> ", response.accessToken);
  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(graphConfig.graphMeEndpoint, options)
    .then(response => response.json())
    .catch(error => console.log(error));
};
// Ensure MSAL handles redirects
// msalInstance
//   .handleRedirectPromise()
//   .then(response => {
//     if (response) {
//       console.log("Redirect login successful:", response);
//     }
//   })
//   .catch(error => {
//     console.error("Redirect error:", error);
//   });
