import React from "react";
// Components
import { Grid } from "@mui/material";
import { DatePickerField, DropDown, InputTextField } from "components/UI";

// Constant
import { API_NAME_ON_DEMAND_EXCEL } from "shared/constants";
import { ModeAction } from "state/enum";
export default function FilterBar(props) {
  const { form, setForm, reportStatusList, mode } = props;

  return (
    <Grid container spacing={1} sx={{ alignItems: "center" }}>
      <Grid item xs={4} style={{ display: "flex", justifyContent: "space-between" }}>
        <strong style={{ margin: "auto 10px", minWidth: "120px" }}>*Request Date:</strong>
        <DatePickerField
          id="select_request_date"
          required
          fullWidth={true}
          autoFocus={true}
          value={form?.requestDate}
          onChange={date => {
            setForm(prev => ({
              ...prev,
              [API_NAME_ON_DEMAND_EXCEL.REQUEST_DATE]: date,
            }));
          }}
        />
      </Grid>
      <Grid item xs={8}></Grid>
      <Grid item xs={5} style={{ display: "flex", justifyContent: "space-between" }}>
        <strong style={{ margin: "auto 10px", minWidth: "120px" }}>Report Status:</strong>
        <DropDown
          id="select_report_status"
          value={form.reportStatus}
          onChange={e => {
            setForm(prev => ({
              ...prev,
              [API_NAME_ON_DEMAND_EXCEL.REPORT_STATUS]: e.target.value,
            }));
          }}
          menu={reportStatusList?.map(val => ({
            key: val?.statusId,
            value: val?.status,
          }))}
          disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
          defaultValue=""
          placeholder="<All>"
        />
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={5} style={{ display: "flex", justifyContent: "space-between" }}>
        <strong style={{ margin: "auto 10px", minWidth: "120px" }}>Report Name:</strong>
        <InputTextField
          id="input_report_name"
          fullWidth
          value={form.reportName}
          maxLength={100}
          onChange={e => {
            setForm(prev => ({
              ...prev,
              [API_NAME_ON_DEMAND_EXCEL.REPORT_NAME]: e.target.value,
            }));
          }}
        />
      </Grid>
      <Grid item xs={1}></Grid>
    </Grid>
  );
}
