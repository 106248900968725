import { useMutateData, useCustomQuery } from "shared/services/base.service";
// import { saveAs } from "utils/download-file";
const BaseServicePath = process.env.REACT_APP_API_INVENTORY_FORECAST;

const ApmcApiService = () => ({
  // GET APMC IMPORTER EXPORTER
  useApmcImporterExporterQuery: userCompany => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/category/apmc/importer-exporter/${userCompany}`,
      method: "GET",
      enabled: !!userCompany,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  //POST APMC SEARCH
  useApmcSearchMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async body => {
      return await mutate({
        url: `${BaseServicePath}/apmc/search`,
        method: "POST",
        data: body,
      });
    };
  },
  //POST APMC CREATE
  useApmcCreateMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async createData => {
      return await mutate({
        url: `${BaseServicePath}/apmc/create`,
        data: createData,
        method: "POST",
        headers: {
          "content-type": "multipart/form-data",
        },
      });
    };
  },
  //POST APMC EDIT
  useApmcUpdateMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async (fileUploadId, updateData) => {
      return await mutate({
        url: `${BaseServicePath}/apmc/edit/${fileUploadId}`,
        data: updateData,
        method: "PATCH",
        headers: {
          "content-type": "multipart/form-data",
        },
      });
    };
  },
  //DELETE APMC DELETE
  useApmcDeleteMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async (fileUploadId, deleteData) => {
      return await mutate({
        url: `${BaseServicePath}/apmc/delete/${fileUploadId}`,
        data: deleteData,
        method: "DELETE",
      });
    };
  },
});

export const {
  useApmcImporterExporterQuery,
  useApmcSearchMutation,
  useApmcCreateMutation,
  useApmcUpdateMutation,
  useApmcDeleteMutation,
} = ApmcApiService();
