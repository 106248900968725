import React, { useEffect, useMemo, useState, useRef } from "react";
import { Grid } from "@mui/material";
import { InputDropDown } from "components/UI";
import useLocalStorage from "shared/hooks/useLocalStorage";
import { useUploadSearch, useDownloadTemplateExcel, useUploadFileMutation } from "service/downloadAndUpload";
import { messageTypeDisplay, responseDownloadFileErrors, responseErrors, responseSuccess } from "utils";
import { DEFAULT_LOCAL_STATE } from "shared/constants";
import { MessageType } from "state/enum";
import { userProfile } from "constant";
import renderUpload from "./renderUpload";
import { getLocalDate } from "utils/init-config-date";
import { useLoadingContext } from "shared/contexts/LoadingContext";
import { useSelector } from "react-redux";
export default function UploadMaster({
  searchForm,
  setSearchForm,
  downloadAndUploadMasterData,
  setMsgAlert,
  setMsgError,
}) {
  const userInfo = useSelector(state => state.auth.user);
  const [logDetail, setLogDetail] = useLocalStorage("logDetail", DEFAULT_LOCAL_STATE);
  const { startLoading, stopLoading } = useLoadingContext();
  const { mutateAsync: searchUploadAsync } = useUploadSearch();
  const downloadTemplateExcel = useDownloadTemplateExcel();

  const uploadFile = useUploadFileMutation();
  const [selectedFile, setSelectedFile] = useState(null);

  const fileInputRef = useRef(null);

  // handle upload
  const handleUpload = async () => {
    try {
      setMsgAlert([]);
      setMsgError([]);

      if (!selectedFile) {
        const msg = messageTypeDisplay(MessageType.EMPTY, ["File"]);
        setMsgError([msg]);
        window.scrollTo(0, 0);
        return;
      }

      const formData = new FormData();
      formData.append("functionName", searchForm.upload);
      formData.append("userId", userInfo.userName);
      formData.append("dataOwner", userProfile.dataOwner);
      formData.append("cd", searchForm.upload);
      formData.append("file", selectedFile);
      startLoading();
      const response = await uploadFile(formData);
      stopLoading();
      console.log("response upload:", response);
      const msg = responseSuccess(response);
      setMsgAlert([msg]);

      const { result } = await searchUploadAsync({
        dataOwner: userProfile.dataOwner,
        cd: searchForm.upload,
      });

      setSearchForm(old => ({
        ...old,
        dataTableSearch: result,
      }));
      document.getElementById("upload-file").value = null;
      setSelectedFile(null);
      return true;
    } catch (error) {
      const errors = error.response.data.errors?.map(({ code, message }) => `${code}: ${message}`);
      setMsgError(errors);
      // const errors = responseErrors(error);
      // setMsgError(errors);
      window.scroll(0, 0);
      stopLoading();
      return false;
    }
  };

  const handleTabLogDetail = row => {
    try {
      const logData = {
        dataOwner: userProfile.dataOwner,
        moduleId: row?.moduleId,
        functionId: row?.functionId,
        statusId: row?.statusId,
        userId: row?.updateBy,
        appId: row?.aplId,
        dateFrom: row?.updateDt ? getLocalDate(row?.updateDt, "DD/MM/YYYY").format("DD/MM/YYYY") : "",
        dateTo: row?.updateDt ? getLocalDate(row?.updateDt, "DD/MM/YYYY").format("DD/MM/YYYY") : "",
        levelId: "",
      };
      setLogDetail(logData);
      window.open("/common/monitoring/log-monitoring-detail", "_blank");
    } catch (error) {
      console.error(error);
    }
  };

  // handle change upload dropdown
  const handleChangeUpload = async e => {
    setMsgAlert([]);
    setMsgError([]);
    setSelectedFile(null);
    try {
      setSearchForm(old => ({
        ...old,
        upload: e.target.value,
      }));

      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }

      if (e.target.value !== "") {
        const { result } = await searchUploadAsync({
          dataOwner: userProfile.dataOwner,
          cd: e.target.value,
        });

        setSearchForm(old => ({
          ...old,
          dataTableSearch: result,
        }));
      }
      return;
    } catch (error) {
      console.error("err searchUploadAsync : ", error);
      const msgErr = responseErrors(error);
      setMsgError(msgErr);
    }
  };
  // handle upload template excel
  const handleDownloadTemplateExcel = async () => {
    try {
      setMsgAlert([]);
      setMsgError([]);

      let filename = "";
      switch (searchForm.upload) {
        case "BDN910E0":
          filename = "Working_Calendar";
          break;
        case "BDN910F0":
          filename = "Transportation_Leadtime";
          break;
        case "BDN910G0":
          filename = "Custom_Stock_Leadtime";
          break;
        case "BDN910H0":
          filename = "SOP";
          break;
        case "BDN910I0":
          filename = "Katashiki_Subseries";
          break;
        case "BDN910L0":
          filename = "Leadtime";
          break;
        case "BDN910M0":
          filename = "Average_Leadtime_Group";
          break;
        default:
          break;
      }
      const body = {
        dataOwner: userProfile.dataOwner,
        cd: searchForm.upload,
      };

      await downloadTemplateExcel(body, filename);
    } catch (error) {
      const errors = responseDownloadFileErrors(error);
      setMsgError([errors]);
      return;
    }
  };
  const uploadMasterList = useMemo(() => {
    if (downloadAndUploadMasterData) {
      downloadAndUploadMasterData?.sort((a, b) => {
        return a?.value.toLowerCase().localeCompare(b?.value.toLowerCase());
      });
      return downloadAndUploadMasterData;
    }
    return downloadAndUploadMasterData;
  }, [downloadAndUploadMasterData]);

  useEffect(() => {
    document.getElementById("select_upload").focus();
  }, []);
  return (
    <Grid container spacing={0} sx={{ alignItems: "center" }}>
      <Grid item xs={5} style={{ display: "flex" }}>
        <strong style={{ margin: "auto 0px", width: "325px" }}>*Upload Master : </strong>
        <InputDropDown
          id="select_upload"
          required
          value={searchForm.upload}
          onChange={handleChangeUpload}
          memu={uploadMasterList?.map(val => ({
            key: val.cd,
            value: val.value,
          }))}
        />
      </Grid>
      <Grid item xs={1}></Grid>
      {renderUpload({
        searchForm,
        setSearchForm,
        setSelectedFile,
        setMsgAlert,
        setMsgError,
        handleUpload,
        handleTabLogDetail,
        handleDownloadTemplateExcel,
        fileInputRef,
      })}
    </Grid>
  );
}
