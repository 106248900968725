import React from "react";
import { TextField, styled } from "@mui/material";

const StyledTextField = styled(TextField)(({ theme, error, textalign }) => ({
  "& .MuiInput-root": {
    border: `1.5px solid ${error ? theme.palette.primary.main : theme.palette.secondary.medium}`,
    backgroundColor: theme.palette.primary.light,
    borderRadius: ".3rem",
    "& .MuiInputBase-input": {
      textAlign: textalign,
      padding: "4px 8px !important",
    },
  },
  "& .MuiInput-root.Mui-focused": {
    border: `1.5px solid black !important`,
    borderRadius: ".3rem",
  },
}));

export default function InputTextField({
  fullWidth = false,
  id,
  placeholder = "",
  value,
  onChange = undefined,
  required = false,
  style = {},
  type = "text",
  disabled,
  maxLength,
  autoFocus = false,
  regularExp,
  multiline = false,
  textalign = null,
  ...props
}) {
  const handleChange = event => {
    const trimmedValue = event.target.value;

    if (regularExp && !regularExp?.test(trimmedValue)) {
      return;
    }

    if (onChange) {
      const newEvent = {
        ...event,
        target: { ...event.target, value: trimmedValue },
      };
      onChange(newEvent);
    }
  };

  const handleBlur = e => {
    try {
      const trimmedValue = e.target.value.trim();
      if (trimmedValue) {
        // Create a new event with the trimmed value
        const newEvent = {
          ...e,
          target: { ...e.target, value: trimmedValue },
        };
        onChange(newEvent);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <StyledTextField
      {...props}
      data-testid={id}
      id={id}
      margin="dense"
      // size="small"
      variant="standard"
      inputProps={{
        maxLength: maxLength,
      }}
      InputProps={{
        sx: {
          paddingLeft: "12px",
          fontSize: "12px",
          ...style,
        },
        disableUnderline: true,
        required: required,
      }}
      multiline={multiline}
      autoFocus={autoFocus}
      type={type}
      fullWidth={fullWidth}
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      required={required}
      disabled={disabled}
      onBlur={handleBlur}
      textalign={textalign}
    />
  );
}
