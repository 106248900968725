import React, { useMemo } from "react";
import { Grid } from "@mui/material";
import { GridRowModes } from "@mui/x-data-grid";
import { InputButton } from "../../components/UI";
import { ModeAction } from "state/enum";
import { useConfirmDialogContext } from "context/confirmDialogContext";

const SubmitBar = ({
  mode,
  rows,
  setMode,
  setRows,
  setRowModesModel,
  setMsgError,
  rowModesModel,
  rowSelectionModel,
  setRowSelectionModel,
  functionId,
  routePointCategoryInTable,
  setRoutePointCategoryInTable = null,
}) => {
  const confirmDialogCtx = useConfirmDialogContext();

  useMemo(() => mode, [mode]);

  const handleSaveClick = async () => {
    document.getElementById("btn_save").value = "save";

    const saveConfirm = await confirmDialogCtx.success({ type: "confirmSave" });
    if (!saveConfirm) {
      return;
    }

    if (mode === ModeAction.ADD) {
      //To find maximum rowNumber
      const latestKey = !rows || rows.length === 0 ? 1 : Math.max(...rows.map(item => item.rowNumber));

      setRowModesModel({
        ...rowModesModel,
        [latestKey]: { mode: GridRowModes.View },
      });
    } else {
      let tempRowModesModel = rowModesModel;

      const rowSelect = rowSelectionModel.map(val => {
        tempRowModesModel = {
          ...tempRowModesModel,
          [val]: { mode: GridRowModes.View },
        };
      });

      rowSelect && setRowModesModel(tempRowModesModel);
    }
  };

  const handleCancelClick = async () => {
    const confirm = await confirmDialogCtx.success({ type: "confirmCancel" });
    if (!confirm) {
      return;
    }

    setMsgError([]);
    if (mode === ModeAction.ADD) {
      //To find maximum rowNumber
      const latestKey = !rows || rows.length === 0 ? 1 : Math.max(...rows.map(item => item.rowNumber));

      setRowModesModel({
        ...rowModesModel,
        [latestKey]: { mode: GridRowModes.View, ignoreModifications: true },
      });

      setRows(rows.filter(row => !row.isNew)); //Keep only not new
    } else {
      let tempRowModesModel = rowModesModel;

      const rowSelect = rowSelectionModel.map(val => {
        tempRowModesModel = {
          ...tempRowModesModel,
          [val]: { mode: GridRowModes.View, ignoreModifications: true },
        };
      });

      rowSelect && setRowModesModel(tempRowModesModel);
    }
    // setSearchButton(false);
    // setOnSearch(false);
    setMode(ModeAction.VIEW);
    setRowSelectionModel([]);
    if (setRoutePointCategoryInTable !== null) {
      setRoutePointCategoryInTable("");
    }
  };

  return (
    <>
      {[ModeAction.ADD, ModeAction.EDIT].includes(mode) && (
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <InputButton id="btn_save" value={"Save"} onClick={handleSaveClick} name={`${functionId}Save`} />
          <InputButton id="btn_cancel" value={"Cancel"} onClick={handleCancelClick} name={`${functionId}Cancel`} />
        </Grid>
      )}
    </>
  );
};

export default SubmitBar;
