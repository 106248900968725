import dayjs from "dayjs";
import { isEmpty } from "lodash";

export const initConfigDate = diffConfig => {
  console.log("========= start initConfigDate =========");

  console.log(new Date(), " <--- new Date() before");
  console.log(dayjs().format(), " <--- dayjs().format() before");
};

export const getLocalDateStr = () => {
  return dayjs().format("DD/MM/YYYY");
};

export const getLocalDate = (date, format = "DD/MM/YYYY") => {
  if (!isEmpty(date)) {
    return dayjs(date, format);
  }
  return dayjs();
};
export const getLocalDateForValidate = (date, format = "DD/MM/YYYY", strickCheck) => {
  if (!isEmpty(date)) {
    return dayjs(date, format, strickCheck).isValid();
  }
  return false;
};
