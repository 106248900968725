import { useMutateData, useCustomQuery, useMutateDownload } from "shared/services/base.service";

const BaseServicePath = process.env.REACT_APP_API_REPORT;

const ReportCommonApiService = () => ({
  useReportCommonGetReportQuery: reportId => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/report-common/get-report`,
      method: "POST",
      enabled: !!reportId,
      select: data => data.result,
      keepPreviousData: true,
      body: {
        cd: reportId,
      },
    });
    return data;
  },
  useReportCommonGetRPackageOwnerQuery: () => {
    const data = useCustomQuery({
      key: ["r-package-owner"],
      url: `${BaseServicePath}/report-common/get-r-package-owner`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useReportCommonGetRPackageTypeQuery: rPackageOwner => {
    const data = useCustomQuery({
      key: ["r-package-type", rPackageOwner],
      url: `${BaseServicePath}/report-common/get-r-package-type`,
      method: "GET",
      enabled: !!rPackageOwner,
      select: data => data.result,
      keepPreviousData: false,
      params: { rPackageOwner },
    });
    return data;
  },

  useReportCommonGetRPackageTypeCategoryQuery: (rPackageOwner, groupCd) => {
    const data = useCustomQuery({
      key: ["r-package-type-by-category", rPackageOwner, groupCd],
      url: `${BaseServicePath}/report-common/get-r-package-type-by-category`,
      method: "POST",
      enabled: !!rPackageOwner && !!groupCd,
      select: data => data.result,
      keepPreviousData: false,
      body: {
        rPackageOwner: rPackageOwner,
        groupCd: groupCd,
      },
    });
    return data;
  },
  useReportCommonGetLocationNameListQuery: dataOwner => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/report-common/get-location-name-list-master`,
      key: ["location-Name", dataOwner],
      method: "POST",
      enabled: !!dataOwner,
      select: data => data.result,
      body: {
        dataOwner,
      },
    });
    return data;
  },
  useReportCommonGetLocationStockInOutQuery: payload => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/report-common/get-location-name-list-master`,
      key: ["location-Name", payload],
      method: "POST",
      enabled: !!payload,
      select: data => data.result,
      body: payload,
    });
    return data;
  },
  useReportCommonGetCountryListQuery: (countryCd, userLocation) => {
    const data = useCustomQuery({
      key: ["country-list", countryCd, userLocation],
      url: `${BaseServicePath}/report-common/get-country-list`,
      method: "POST",
      enabled: !!countryCd && !!userLocation,
      select: data => data.result,
      keepPreviousData: false,
      body: {
        countryCd: countryCd,
        loginUserCompanyAbbr: userLocation,
      },
    });
    return data;
  },
  useReportCommonGetWarehouseListQuery: (countryCd, userLocation) => {
    const { data, refetch } = useCustomQuery({
      key: ["report-warehouse", countryCd],
      url: `${BaseServicePath}/report-common/get-warehouse-monthly-forecast`,
      method: "POST",
      enabled: !!countryCd && !!userLocation,
      select: data => data.result,
      body: {
        countryCd: countryCd,
        loginUserCompanyAbbr: userLocation,
      },
    });
    return { data, refetch };
  },
  useReportCommonRPackageCategoryQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/report-common/get-available-r-package-category`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useReportCommonRPackageCategoryListQuery: () => {
    const data = useCustomQuery({
      key: ["r-package-category"],
      url: `${BaseServicePath}/report-common/get-available-r-package-category-list`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useReportCommonTransactionCategoryListQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/report-common/get-transaction-report`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useExcelDownloadOnlineJavaMutation: () => {
    const { mutate } = useMutateDownload({
      invalidateKeys: [],
    });
    return async (body, pathReport) => {
      return await mutate({
        url: `${BaseServicePath}/report-common/${pathReport}`,
        method: "POST",
        data: body,
      });
    };
  },
  useExcelDownloadOnlineMutation: () => {
    const { mutate } = useMutateDownload({
      invalidateKeys: [],
    });
    return async (body, pathReport) => {
      return await mutate({
        url: `${BaseServicePath}/report-common/${pathReport}`,
        method: "POST",
        data: body,
      });
    };
  },
  useExcelDownloadOnDemandMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async (body, pathReport) => {
      return await mutate({
        url: `${BaseServicePath}/report-common/${pathReport}`,
        method: "POST",
        data: body,
      });
    };
  },
});

export const {
  useReportCommonGetReportQuery,
  useReportCommonGetRPackageOwnerQuery,
  useReportCommonGetRPackageTypeQuery,
  useReportCommonGetRPackageTypeCategoryQuery,
  useReportCommonGetLocationNameListQuery,
  useReportCommonGetLocationStockInOutQuery,
  useReportCommonGetCountryListQuery,
  useReportCommonGetWarehouseListQuery,
  useReportCommonRPackageCategoryQuery,
  useReportCommonRPackageCategoryListQuery,
  useReportCommonTransactionCategoryListQuery,
  useExcelDownloadOnDemandMutation,
  useExcelDownloadOnlineMutation,
  useExcelDownloadOnlineJavaMutation,
} = ReportCommonApiService();
