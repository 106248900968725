import { callMsGraph } from "shared/libs/azure-msal";
import { useAuth } from "shared/contexts/AuthContext";
import { useQuery } from "@tanstack/react-query";
import { useMutateData } from "shared/services/base.service";

const BaseServicePathStandard = process.env.REACT_APP_API_STANDARD;
const BaseServicePathCommon = process.env.REACT_APP_API_COMMON;

const AuthService = () => ({
  // GET BUSINESS DATA
  Login: () => {
    const { user } = useAuth();
    const { data, error, isLoading } = useQuery({
      queryKey: ["graphData", user?.username], // Query key includes the username to refetch if the user changes
      queryFn: async () => callMsGraph(), // The function to call
      options: {
        enabled: !!user, // Only run the query if the user is authenticated
        retry: false, // Disable retry if the user is not authenticated
        onError: err => {
          console.error("Error fetching data from MS Graph:", err);
        },
      },
    });
    const response = { data, error, isLoading };
    return response;
  },
  LoginInfoTest: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async (userId, givenName) => {
      return await mutate({
        url: `${BaseServicePathStandard}/user/info`,
        method: "POST",
        data: { userId, accountName: givenName },
      });
    };
  },
  useGetRoleUser: () => {
    const { mutate } = useMutateData({
      invalidateKeys: ["user-role"],
    });
    return async userId => {
      return await mutate({
        url: `${BaseServicePathCommon}/access-control/function`,
        method: "POST",
        data: { userId },
      });
    };
  },
});
export const { Login, LoginInfoTest, useGetRoleUser } = AuthService();
