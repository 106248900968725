import React, { Fragment } from "react";
import { Grid } from "@mui/material";
import { DropDown, Typography } from "components/UI";

//ENUM
import { ModeAction } from "state/enum";
import { API_MONTHLY } from "shared/constants/api-name/forecast/monthly";
export default function HeaderSection({
  setMode,
  setRows,
  onSearch,
  setOnSearch,
  setOnOpen,
  searchForm,
  setSearchForm,
  setRowSelectionModel,
  setPagination,
  setPageNumber,
  forecastTypeList,
  mode,
  setMsg: { setMsgError, setMsgAlert },
}) {
  const handleChange = e => {
    setMsgError([]);
    setMsgAlert([]);
    setSearchForm(prev => ({
      ...prev,
      [API_MONTHLY.FORECAST_TYPE_CD]: e.target.value,
      [API_MONTHLY.FORECAST_MONTH]: "",
      [API_MONTHLY.MODE_CD]: "",
      [API_MONTHLY.DATA_TYPE_CD]: "",
      [API_MONTHLY.UPLOAD_STATUS_CD]: "",
      [API_MONTHLY.FILE_UPLOAD_ID]: "",
      [API_MONTHLY.ORG_FILE_NAME]: "",
      file: {},
    }));

    setMode(ModeAction.VIEW);
    setOnSearch(false);
    setOnOpen(false);
    setRowSelectionModel([]);
    setRows([]);
    setPagination({});
    setPageNumber(1);
  };

  const handleFocus = e => {
    if (searchForm.forecastTypeCd === "" || searchForm.forecastTypeCd === "Y") {
      const handleTabKey = event => {
        if (event.key === "Tab") {
          event.preventDefault();
        }
      };

      e.target.addEventListener("keydown", handleTabKey);
      e.target.addEventListener("blur", () => {
        e.target.removeEventListener("keydown", handleTabKey);
      });
    }
  };

  return (
    <Fragment>
      <Grid container sx={{ alignItems: "center" }}>
        <Grid item xs={5} style={{ display: "flex" }}>
          <Typography
            id="label-forecast-type"
            content="Forecast Type:"
            sx={{
              color: "#000",
              fontFamily: "Kanit",
              fontWeight: "bold",
              mr: 2,
            }}
            noWrap={false}
            style={{ margin: "auto 0px", width: "325px" }}
          />
          <DropDown
            id="select-forecast-type"
            required
            value={searchForm.forecastTypeCd}
            onChange={handleChange}
            onFocus={handleFocus}
            menu={forecastTypeList?.map(val => ({
              key: val.cd,
              value: val.value,
            }))}
            autoFocus={true}
            defaultValue=""
            placeholder="<Select>"
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
          />
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    </Fragment>
  );
}
