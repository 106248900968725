import { useCustomQuery } from "shared/services/base.service";
const BaseServicePath = process.env.REACT_APP_API_REPORT;

const MonthlyForecastApiService = () => ({
  useGetDetailDataTypeListQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/report-common/get-detail-data-type`,
      method: "POST",
      enabled: true,
      select: data => data.result,
      body: {},
    });
    return data;
  },
});

export const { useGetDetailDataTypeListQuery } = MonthlyForecastApiService();
