import { useCustomQuery } from "shared/services/base.service";
const BaseServicePath = process.env.REACT_APP_API_REPORT;

const RPackageCustomsBalanceApiService = () => ({
  useGetDestinationCountryListQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/report-common/get-destination-country`,
      method: "POST",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
      // body: {},
    });
    return data;
  },
  useGetSourceCountryListQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/report-common/get-source-country`,
      method: "POST",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
      // body: {},
    });
    return data;
  },
  useGetDestinationCompanyListQuery: countryCd => {
    const data = useCustomQuery({
      key: ["destination-company", countryCd],
      url: `${BaseServicePath}/report-common/get-destination-company`,
      method: "GET",
      enabled: !!countryCd,
      params: { countryCd: countryCd },
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useGetSourceCompanyListQuery: countryCd => {
    const data = useCustomQuery({
      key: ["source-company", countryCd],
      url: `${BaseServicePath}/report-common/get-source-company`,
      method: "GET",
      enabled: !!countryCd,
      params: { countryCd: countryCd },
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useGetAvailableStatusListQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/report-common/get-available-status`,
      method: "POST",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
      body: {},
    });
    return data;
  },
  useGetNumOfRecordListQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/report-common/get-number-of-record`,
      method: "POST",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
      body: {},
    });
    return data;
  },
});

export const {
  useGetDestinationCountryListQuery,
  useGetSourceCountryListQuery,
  useGetDestinationCompanyListQuery,
  useGetSourceCompanyListQuery,
  useGetAvailableStatusListQuery,
  useGetNumOfRecordListQuery,
} = RPackageCustomsBalanceApiService();
