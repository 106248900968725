import React, { useState, useEffect, useCallback } from "react";
import { List, ListItemButton, ListItemText, Collapse, useTheme } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import MenuItem from "components/UI/MenuItem/MenuItem";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useGetRoleUser } from "shared/services/auth/auth.service";

export default function MultipleLevel(props) {
  const { item, depthStep = 2, depth = 0 } = props;
  const { pathname } = useLocation();
  const theme = useTheme();
  const { subItems: children } = item;

  const [collapsed, setCollapsed] = useState(false);
  const [itemsWithPermissions, setItemsWithPermissions] = useState([]);
  const getPermissionScreen = useGetRoleUser();

  const userInfo = useSelector(state => state.auth.user);

  const handleClick = () => {
    setCollapsed(prev => !prev);
  };

  useEffect(() => {
    if (children.length > 0) {
      if (children.find(v => v.path && v.path.split("?")[0] === pathname)) {
        setCollapsed(true);
      } else if (children.some(v => v.subItems && v.subItems.find(sub => sub.path === pathname))) {
        setCollapsed(true);
      } else {
        setCollapsed(false);
      }
    }
  }, [children, pathname]);

  const getRoles = useCallback(
    async user_id => {
      try {
        const {
          result: { functions },
        } = await getPermissionScreen(user_id);

        const updatedItems = item.subItems.map(subItem => {
          if (subItem.functionId === "Monitoring" && subItem.subItems) {
            const updatedSubItems = subItem.subItems.map(monitoringSubItem => {
              const matchedFunction = functions.find(({ screen }) => screen?.id === monitoringSubItem.functionId);
              return {
                ...monitoringSubItem,
                hasPermission: !!matchedFunction,
              };
            });
            return {
              ...subItem,
              hasPermission: updatedSubItems.some(subItem => subItem.hasPermission),
              subItems: updatedSubItems,
            };
          }

          const matchedFunction = functions.find(({ screen }) => screen?.id === subItem.functionId);
          return {
            ...subItem,
            hasPermission: !!matchedFunction,
          };
        });

        setItemsWithPermissions(updatedItems);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [getPermissionScreen, item?.subItems]
  );

  useEffect(() => {
    if (userInfo) {
      getRoles(userInfo?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  const hasVisibleItems = itemsWithPermissions.some(subItem => subItem?.hasPermission);

  return (
    <List disablePadding dense sx={{ display: "block" }}>
      {hasVisibleItems && (
        <ListItemButton
          onClick={handleClick}
          sx={{
            borderRadius: 2,
            margin: 1,
            "& .MuiTypography-root": { fontWeight: "bold", fontSize: "16px" },
          }}>
          <ListItemText primary={item?.title} sx={{ ml: depth * depthStep }} />
          {collapsed ? (
            <ExpandLess
              sx={{
                color: theme.palette.primary.light,
                "&:hover": { color: "red" },
              }}
            />
          ) : (
            <ExpandMore sx={{ color: theme.palette.primary.light }} />
          )}
        </ListItemButton>
      )}
      <Collapse in={collapsed} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {itemsWithPermissions
            .filter(child => child.hasPermission)
            .map((child, key) => (
              <MenuItem item={child} key={key} depth={depth + 1} depthStep={depthStep} />
            ))}
        </List>
      </Collapse>
    </List>
  );
}
