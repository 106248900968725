// src/store.js
import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./reducers/counterSlice";
import authReducer, { sessionMiddleware } from "shared/stores/reducers/authSlice";
import commonReducer from "shared/stores/reducers/commonSlice";

const store = configureStore({
  reducer: {
    counter: counterReducer,
    auth: authReducer,
    common: commonReducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(sessionMiddleware),
});

export default store;
