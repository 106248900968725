import React, { Fragment } from "react";
import { Grid } from "@mui/material";
import { InputTextField, Typography } from "components/UI";
import { API_EXCEL } from "shared/constants";

export default function OptionsBar(props) {
  const { form, setForm, onSearch } = props;
  return (
    <Fragment>
      <Grid container item sx={{ alignItems: "center" }}>
        <Grid item xs={2} sm={2}>
          <Typography
            content="OPTIONS: "
            sx={{ color: "#000", fontFamily: "Kanit", fontWeight: "bold" }}
            noWrap={false}
          />
        </Grid>
        <Typography
          content="Report Name: "
          sx={{ color: "#000", fontFamily: "Kanit", fontWeight: "bold", mr: 2 }}
          noWrap={false}
        />
        <Grid item xs={4}>
          <InputTextField
            id="input-report-name"
            fullWidth
            value={form.reportName}
            onChange={(e) => {
              setForm((prev) => ({
                ...prev,
                [API_EXCEL.REPORT_NAME]: e.target.value,
              }));
            }}
            // regularExp={/^[a-zA-Z0-9]*$/}
            maxLength={60}
          />
        </Grid>
        <Typography
          content="Start Row: "
          sx={{
            color: "#000",
            fontFamily: "Kanit",
            fontWeight: "bold",
            mr: 2,
            ml: 2,
          }}
          noWrap={false}
        />
        <Grid item xs={1} sx={{ ml: 3 }}>
          <InputTextField
            id="input-start-row"
            fullWidth
            value={form.startRow}
            onChange={(e) => {
              setForm((prev) => ({
                ...prev,
                [API_EXCEL.START_ROW]: e.target.value,
              }));
            }}
            regularExp={/^[\d.]*$/}
            maxLength={4}
          />
        </Grid>
      </Grid>

      <Grid container item sx={{ alignItems: "center" }}>
        <Grid item xs={2}></Grid>
        <Typography
          content="Report Title: "
          sx={{ color: "#000", fontFamily: "Kanit", fontWeight: "bold", mr: 3 }}
          noWrap={false}
        />
        <Grid item xs={4}>
          <InputTextField
            id="input-report-title"
            fullWidth
            value={form.reportTitle}
            onChange={(e) => {
              setForm((prev) => ({
                ...prev,
                [API_EXCEL.REPORT_TITLE]: e.target.value,
              }));
            }}
            // regularExp={/^[a-zA-Z0-9]*$/}
            maxLength={60}
          />
        </Grid>
        <Typography
          content="Start Column: "
          sx={{
            color: "#000",
            fontFamily: "Kanit",
            fontWeight: "bold",
            mr: 2,
            ml: 2,
          }}
          noWrap={false}
        />
        <Grid item xs={1}>
          <InputTextField
            id="input-start-column"
            fullWidth
            value={form.startColumn}
            onChange={(e) => {
              setForm((prev) => ({
                ...prev,
                [API_EXCEL.START_COLUMN]: e.target.value,
              }));
            }}
            regularExp={/^[\d.]*$/}
            maxLength={4}
            onKeyDown={(e) => {
              if (!onSearch && e.key === "Tab") {
                e.preventDefault();
                document.getElementById("select-table-view").focus();
              }
            }}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}
