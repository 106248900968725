import dayjs from "dayjs";
import { API_SHIPMENT, COMMON_ENUM } from "shared/constants";

const formatRowsGroup = dataRaw => {
  const data = [];
  let rPkg = [];
  if (!dataRaw.length) {
    console.log("data not found");
    return;
  }
  dataRaw.forEach((val, index) => {
    const planData = {
      no: index + 1,
      rowSpan: 2,
      [API_SHIPMENT.ROW_NUMBER]: val.rowNumber,
      [API_SHIPMENT.SHIPMENT_SUMMARY_H_ID]: val.shipmentSummaryHId,
      [API_SHIPMENT.FORECAST_MONTH]: val.forecastPeriod,
      [API_SHIPMENT.VANNING_MONTH]: val.vanningMonth,
      [API_SHIPMENT.EXPORTER]: val.exporterCd,
      [API_SHIPMENT.IMPORTER]: val.importerCd,
      [API_SHIPMENT.SHIPMENT_CODE]: val.shipmentCd,
      [API_SHIPMENT.EXP_COMPANY_ID]: val.exporterCompanyId,
      [API_SHIPMENT.IMP_COMPANY_ID]: val.importerCompanyId,
      [API_SHIPMENT.R_RKG_OWNER]: val.rPkgOwnerCompAbbr,
      [API_SHIPMENT.PLAN_VAN_FROM]: val.planVanningFrom,
      [API_SHIPMENT.PLAN_VAN_TO]: val.planVanningTo,
      [API_SHIPMENT.ACTUAL_VAN_FROM]: val.actlVanningFrom,
      [API_SHIPMENT.INVOICE_NO]: val.invoiceNo,
      [API_SHIPMENT.TRANSPORT_LT_ID]: val.transportLtId,
      //ETD FROM
      [API_SHIPMENT.ETD_DATE]: val.planEtdDt,
      //ETA FROM
      [API_SHIPMENT.ETA_DATE]: val.planEtaDt,
      // Actual file
      [API_SHIPMENT.ORG_ACTL_FILE_NAME]: val?.orgActlFileName,
      // NVC
      [API_SHIPMENT.ORG_NCV_FILE_NAME]: val.orgNcvFileName,
      [API_SHIPMENT.NCV_FILE_NAME]: val.ncvFileName,
      //BI
      [API_SHIPMENT.ORG_BL_FILE_NAME]: val.orgBlFileName,
      [API_SHIPMENT.BL_FILE_NAME]: val.blFileName,
      [API_SHIPMENT.SHIPMENT_STATUS_ID]: val.shipmentStsId,
      [API_SHIPMENT.SHIPMENT_STATUS]: val.shipmentSts,
      [API_SHIPMENT.CANCEL_FLAG]: val.cancelFlag,
      [API_SHIPMENT.UPDATE_DATE]: dayjs(val.updateDt, COMMON_ENUM.DATETIME_FORMAT_YYYYMMDDHHMMSS).format(
        "DD/MM/YYYY HH:mm:ss"
      ),
      [API_SHIPMENT.CARRIER]: val.carrier,
      [API_SHIPMENT.ETD_SORT]: val.etdSort,
      [API_SHIPMENT.PLAN_ACTL]: "Plan",
    };

    const actualData = {
      no: index + 1,
      [API_SHIPMENT.ROW_NUMBER]: val.rowNumber,
      [API_SHIPMENT.SHIPMENT_SUMMARY_H_ID]: val.shipmentSummaryHId,
      [API_SHIPMENT.FORECAST_MONTH]: val.forecastPeriod,
      [API_SHIPMENT.VANNING_MONTH]: val.vanningMonth,
      [API_SHIPMENT.EXPORTER]: val.exporterCd,
      [API_SHIPMENT.IMPORTER]: val.importerCd,
      [API_SHIPMENT.SHIPMENT_CODE]: val.shipmentCd,
      [API_SHIPMENT.EXP_COMPANY_ID]: val.exporterCompanyId,
      [API_SHIPMENT.IMP_COMPANY_ID]: val.importerCompanyId,
      [API_SHIPMENT.R_RKG_OWNER]: val.rPkgOwnerCompAbbr,
      [API_SHIPMENT.PLAN_VAN_FROM]: val.planVanningFrom,
      [API_SHIPMENT.PLAN_VAN_TO]: val.planVanningTo,
      [API_SHIPMENT.ACTUAL_VAN_FROM]: val.actlVanningFrom,
      [API_SHIPMENT.ACTUAL_VAN_TO]: val.actlVanningTo,
      [API_SHIPMENT.INVOICE_NO]: val.invoiceNo,
      [API_SHIPMENT.TRANSPORT_LT_ID]: val.transportLtId,
      //ETD TO
      [API_SHIPMENT.ETD_DATE]: val.actlEtdDt,
      //ETA TO
      [API_SHIPMENT.ETA_DATE]: val.actlEtaDt,
      // Actual file
      [API_SHIPMENT.ORG_ACTL_FILE_NAME]: val?.orgActlFileName,
      // NVC
      [API_SHIPMENT.ORG_NCV_FILE_NAME]: val.orgNcvFileName,
      [API_SHIPMENT.NCV_FILE_NAME]: val.ncvFileName,
      //BI
      [API_SHIPMENT.ORG_BL_FILE_NAME]: val.orgBlFileName,
      [API_SHIPMENT.BL_FILE_NAME]: val.blFileName,
      [API_SHIPMENT.SHIPMENT_STATUS_ID]: val.shipmentStsId,
      [API_SHIPMENT.SHIPMENT_STATUS]: val.shipmentSts,
      [API_SHIPMENT.CANCEL_FLAG]: val.cancelFlag,
      [API_SHIPMENT.UPDATE_DATE]: dayjs(val.updateDt, COMMON_ENUM.DATETIME_FORMAT_YYYYMMDDHHMMSS).format(
        "DD/MM/YYYY HH:mm:ss"
      ),
      [API_SHIPMENT.CARRIER]: val.carrier,
      [API_SHIPMENT.ETD_SORT]: val.etdSort,
      [API_SHIPMENT.PLAN_ACTL]: "Actual",
    };

    val.plan.forEach(item => {
      planData[item.key] = item.qty
      if (!rPkg.includes(item.key)) return rPkg.push(item.key);
    });

    val.actual.forEach(item => {
      actualData[item.key] = item.qty
    });
    data.push(planData, actualData);
  });

  return { data, rPkg };
};

const formatRowsMapping = dataRaw => {
  console.log('dataRaw :>> ', dataRaw);
  const data = [];
  let rPkg = [];
  if (!dataRaw.length) {
    console.log("data not found");
    return;
  }
  dataRaw.forEach((val, index) => {
    const planData = {
      no: index + 1,
      rowSpan: 2,
      [API_SHIPMENT.ROW_NUMBER]: val.rowNumber,
      [API_SHIPMENT.SHIPMENT_SUMMARY_H_ID]: val.shipmentSummaryHId,
      [API_SHIPMENT.FORECAST_MONTH]: val.forecastPeriod,
      [API_SHIPMENT.VANNING_MONTH]: val.vanningMonth,
      [API_SHIPMENT.EXPORTER]: val.exporterCd,
      [API_SHIPMENT.IMPORTER]: val.importerCd,
      [API_SHIPMENT.SHIPMENT_CODE]: val.shipmentCd,
      [API_SHIPMENT.EXP_COMPANY_ID]: val.exporterCompanyId,
      [API_SHIPMENT.IMP_COMPANY_ID]: val.importerCompanyId,
      [API_SHIPMENT.R_RKG_OWNER]: val.rPkgOwnerCompAbbr,
      [API_SHIPMENT.PLAN_VAN_FROM]: val.planVanningFrom,
      [API_SHIPMENT.PLAN_VAN_TO]: val.planVanningTo,
      [API_SHIPMENT.ACTUAL_VAN_FROM]: val.actlVanningFrom,
      [API_SHIPMENT.INVOICE_NO]: val.invoiceNo,
      [API_SHIPMENT.TRANSPORT_LT_ID]: val.transportLtId,
      //ETD
      [API_SHIPMENT.ETD_DATE]: val.planEtdDt,
      //ETA
      [API_SHIPMENT.ETA_DATE]: val.planEtaDt,
      // Actual file
      [API_SHIPMENT.ORG_ACTL_FILE_NAME]: val?.orgActlFileName,
      // NVC
      [API_SHIPMENT.ORG_NCV_FILE_NAME]: val.orgNcvFileName,
      [API_SHIPMENT.NCV_FILE_NAME]: val.ncvFileName,
      //BI
      [API_SHIPMENT.ORG_BL_FILE_NAME]: val.orgBlFileName,
      [API_SHIPMENT.BL_FILE_NAME]: val.blFileName,
      [API_SHIPMENT.SHIPMENT_STATUS_ID]: val.shipmentStsId,
      [API_SHIPMENT.SHIPMENT_STATUS]: val.shipmentSts,
      [API_SHIPMENT.CANCEL_FLAG]: val.cancelFlag,
      [API_SHIPMENT.UPDATE_DATE]: dayjs(val.updateDt, COMMON_ENUM.DATETIME_FORMAT_YYYYMMDDHHMMSS).format(
        "DD/MM/YYYY HH:mm:ss"
      ),
      [API_SHIPMENT.CARRIER]: val.carrier,
      [API_SHIPMENT.ETD_SORT]: val.etdSort,
      [API_SHIPMENT.PLAN_ACTL]: "Plan",
    };

    const actualData = {
      // no: index + 1,
      [API_SHIPMENT.ROW_NUMBER]: val.rowNumber,
      [API_SHIPMENT.SHIPMENT_SUMMARY_H_ID]: val.shipmentSummaryHId,
      [API_SHIPMENT.FORECAST_MONTH]: val.forecastPeriod,
      [API_SHIPMENT.VANNING_MONTH]: val.vanningMonth,
      [API_SHIPMENT.EXPORTER]: val.exporterCd,
      [API_SHIPMENT.IMPORTER]: val.importerCd,
      [API_SHIPMENT.SHIPMENT_CODE]: val.shipmentCd,
      [API_SHIPMENT.EXP_COMPANY_ID]: val.exporterCompanyId,
      [API_SHIPMENT.IMP_COMPANY_ID]: val.importerCompanyId,
      [API_SHIPMENT.R_RKG_OWNER]: val.rPkgOwnerCompAbbr,
      [API_SHIPMENT.PLAN_VAN_FROM]: val.planVanningFrom,
      [API_SHIPMENT.PLAN_VAN_TO]: val.planVanningTo,
      [API_SHIPMENT.ACTUAL_VAN_FROM]: val.actlVanningFrom,
      [API_SHIPMENT.INVOICE_NO]: val.invoiceNo,
      [API_SHIPMENT.TRANSPORT_LT_ID]: val.transportLtId,
      //ETD
      [API_SHIPMENT.ETD_DATE]: val.actlEtdDt,
      //ETA
      [API_SHIPMENT.ETA_DATE]: val.actlEtaDt,
      // Actual file
      [API_SHIPMENT.ORG_ACTL_FILE_NAME]: val?.orgActlFileName,
      // NVC
      [API_SHIPMENT.ORG_NCV_FILE_NAME]: val.orgNcvFileName,
      [API_SHIPMENT.NCV_FILE_NAME]: val.ncvFileName,
      //BI
      [API_SHIPMENT.ORG_BL_FILE_NAME]: val.orgBlFileName,
      [API_SHIPMENT.BL_FILE_NAME]: val.blFileName,
      [API_SHIPMENT.SHIPMENT_STATUS_ID]: val.shipmentStsId,
      [API_SHIPMENT.SHIPMENT_STATUS]: val.shipmentSts,
      [API_SHIPMENT.CANCEL_FLAG]: val.cancelFlag,
      [API_SHIPMENT.UPDATE_DATE]: dayjs(val.updateDt, COMMON_ENUM.DATETIME_FORMAT_YYYYMMDDHHMMSS).format(
        "DD/MM/YYYY HH:mm:ss"
      ),
      [API_SHIPMENT.CARRIER]: val.carrier,
      [API_SHIPMENT.ETD_SORT]: val.etdSort,
      [API_SHIPMENT.PLAN_ACTL]: "Actual",
    };

    val.plan.forEach(item => {
      planData[item.key] = item.qty;
      if (!rPkg.includes(item.key)) return rPkg.push(item.key);
    });

    val.actual.forEach(item => {
      actualData[item.key] = item.qty;
    });

    data.push(planData, actualData);
  });
  return { data, rPkg };
};

export { formatRowsGroup, formatRowsMapping };
