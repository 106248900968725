import { useGetQuery } from "shared/services/base.service";
const BaseServicePath = process.env.REACT_APP_API_COMMON;

const CommonApiService = () => ({
  // GET BUSINESS DATA
  useGetDateTimeQuery: () => {
    const data = useGetQuery({
      key: ["common-date-time"],
      url: `${BaseServicePath}/service-time`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: false,
      refetchInterval: 3 * 1000,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: true,
    });
    return data;
  },
});

export const { useGetDateTimeQuery } = CommonApiService();
