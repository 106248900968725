import React from "react";
import { Box } from "@mui/material";
import { DataGridTable, SubmitBar } from "components/UI";
//Utils
import { findObject, responseErrors, responseSuccess } from "utils";
import { transformCancelSubScreenData } from "../../../hooks/tranformData";
import { validationSearchForm } from "utils/validation";
//Service
import {
  useExportInvoiceCancelCancelVanningResultSubScreenMutation,
  useExportInvoiceSearchCancelVanningResultSubScreenMutation,
  useShippingInfoCreateMutation,
  useShippingInfoUpdateMutation,
} from "shared/services/invoice";
//Type
import { MessageType, ModeAction } from "state/enum";
import { PAGINATION, ROW_PER_PAGE } from "shared/constants";
import { userProfile } from "constant";
import { getLocalDate } from "utils/init-config-date";
import { DEFAULT_STATE_WDN95012 } from "pages/Invoice/ExportInvoiceCreationMaintenanceScreen/constants/constants";
import { useSelector } from "react-redux";
export default function TableSection(props) {
  const {
    mode,
    setMode,
    rows,
    setRows,
    columns,
    onSearch,
    form,
    setForm,
    setOnSearch,
    refreshRowsTable,
    dataList: { importerList, consigneeList, brokerList },
    setMsg: { setMsgAlert, setMsgError },
    rowSelection: { rowModesModel, setRowModesModel, rowSelectionModel, setRowSelectionModel },
    pagination: { pagination, setPagination, pageNumber, setPageNumber },
    setOnOpen,
    alertMain,
  } = props;
  const userInfo = useSelector(state => state.auth.user);
  const searchExportInfo = useExportInvoiceSearchCancelVanningResultSubScreenMutation();
  const cancelVanning = useExportInvoiceCancelCancelVanningResultSubScreenMutation();
  // TODO: API create
  const createExportInfo = useShippingInfoCreateMutation();
  const updateExportInfo = useShippingInfoUpdateMutation();
  const handleChangePagination = async (event, value) => {
    try {
      setPageNumber(value);
      const body = {
        dataOwner: userProfile.dataOwner, // TODO: mock constants here
        etdDt: form?.etdDt,
        companyId: form?.companyId,
        containerNo: form?.containerNo,
        userId: userInfo.userName,
        loginUserCompanyAbbr: userInfo.companyAbbr, // TODO: mock constants here
        [PAGINATION.PAGE_NUMBER]: value,
        [PAGINATION.ROW_PER_PAGE]: ROW_PER_PAGE,
      };
      const searchData = await searchExportInfo(body);
      const data = transformCancelSubScreenData(searchData?.result?.data);
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      window.scrollTo(0, 0);
      return;
    }
  };
  // TODO: Action Create
  const handleCreate = async newRow => {
    try {
      let body = {
        expCd: newRow?.exporterCode,
        impCompanyId: newRow?.impCompanyAbbrId,
        impCompanyAbbr: newRow?.impCompanyAbbrId
          ? findObject({
              data: importerList,
              value: newRow?.impCompanyAbbrId,
              property: "companyId",
              field: "companyAbbr",
            })
          : "",
        loadingPort: newRow?.loadingPort,
        dischargingPort: newRow?.dischargingPort,
        etd: newRow?.etd,
        vessel: newRow?.vesselName,
        consignee: newRow?.consignee
          ? findObject({
              data: consigneeList,
              value: newRow?.consignee,
              property: "cd",
              field: "value",
            })
          : "",
        broker: newRow.broker,
        brokerAbbr: newRow.broker
          ? findObject({
              data: brokerList,
              value: newRow?.broker,
              property: "cd",
              field: "value",
            })
          : "",
        userId: userProfile?.userId,
      };
      const { isSuccess, errors } = validationSearchForm({
        data: body,
        rule: [
          {
            field: "expCd",
            type: MessageType.EMPTY,
            key: ["Exporter Code"],
          },
          {
            field: "impCompanyId",
            type: MessageType.EMPTY,
            key: ["Importer Company"],
          },
          {
            field: "etd",
            type: MessageType.EMPTY,
            key: ["ETD", "DD/MM/YYYY"],
          },
          {
            field: "loadingPort",
            type: MessageType.EMPTY,
            key: ["Loading Port"],
          },
          {
            field: "dischargingPort",
            type: MessageType.EMPTY,
            key: ["Discharging Port"],
          },
          {
            field: "vessel",
            type: MessageType.EMPTY,
            key: ["Vessel Name"],
          },
          {
            field: "broker",
            type: MessageType.EMPTY,
            key: ["Broker"],
          },
          {
            field: "etd",
            type: MessageType.DATE_FORMAT_50,
            key: ["ETD", "DD/MM/YYYY"],
          },
          {
            field: "etd",
            type: MessageType.DATE_EQUAL_LATER,
            key: ["ETD", "Current Date", "YYYY-MM-DD"],
          },
        ],
      });
      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return false;
      }
      body.etd = !newRow?.etd ? "" : getLocalDate(newRow?.etd, "DD/MM/YYYY").format("YYYY-MM-DD");
      const response = await createExportInfo(body);
      const msg = responseSuccess(response);
      setMsgAlert([msg]);
      window.scrollTo(0, 0);
      return true;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      return false;
    }
  };
  // TODO: Action Update
  const handleUpdate = async row => {
    try {
      console.log(row);
      const body = {
        userId: userInfo.userName,
        shippingData: {
          expCd: row?.exporterCode,
          impCd: row?.importerCode,
          impCompanyId: row?.impCompanyAbbrId,
          impCompanyAbbr: row?.impCompanyAbbr,
          etd: row?.etd,
          csnCompanyId: row?.consigneeId,
          csnCompanyAbbr: row?.consigneeId
            ? findObject({
                data: consigneeList,
                value: row?.consigneeId,
                property: "cd",
                field: "value",
              })
            : "",
          loadingPort: row?.loadingPort,
          dischargingPort: row?.dischargingPort,
          vessel: row?.vesselName,
          brokerCompanyId: row?.broker
            ? findObject({
                data: brokerList,
                value: row?.broker,
                property: "value",
                field: "cd",
              })
            : "",
          brokerCompanyAbbr: row?.broker,
          shippingInfoHId: row?.shippingInfoHId,
          orderTypeCd: row?.orderTypeCd,
          carFamilyCd: row?.carFamilyCode,
        },
        container: [
          // {
          //   rPackage: [], // if empty choose delete all existing rPackage
          // },
        ], // if empty choose delete all existing container
      };
      const { isSuccess, errors } = validationSearchForm({
        data: body?.shippingData,
        rule: [
          {
            field: "loadingPort",
            type: MessageType.EMPTY,
            key: ["Loading Port"],
          },
          {
            field: "dischargingPort",
            type: MessageType.EMPTY,
            key: ["Discharging Port"],
          },
          {
            field: "vessel",
            type: MessageType.EMPTY,
            key: ["Vessel Name"],
          },
          {
            field: "brokerCompanyId",
            type: MessageType.EMPTY,
            key: ["Broker"],
          },
        ],
      });
      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return false;
      }
      const response = await updateExportInfo(body);
      console.log(response);
      const msg = responseSuccess(response);
      setMsgAlert([msg]);
      window.scrollTo(0, 0);
      return true;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      return false;
    }
  };

  const processRowUpdate = async newRow => {
    let response;
    try {
      setMsgError([]);
      setMsgAlert([]);
      if (newRow.isNew) {
        response = await handleCreate(newRow);
      } else {
        response = await handleUpdate(newRow);
      }
      // throw to onProcessRowUpdate is error
      if (!response) return false;
      if (rows.length === 1 && ModeAction.ADD === mode) {
        setMode(ModeAction.VIEW);
        setOnSearch(false);
        setRowSelectionModel([]);
        setRowModesModel({});
        setRows([]);
      } else {
        setMode(ModeAction.VIEW);
        setRowSelectionModel([]);
        const doneRefresh = await refreshRowsTable();
        if (!doneRefresh) return;
      }
      const updatedRow = { ...newRow, isNew: false };
      return updatedRow;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return false;
    }
  };

  const handleCancel = async row => {
    setMsgError([]);
    setMsgAlert([]);

    // if (!row) {
    //   alert("MDN95025AERR: A record must be selected to execute Cancel Vanning Result operation.");
    //   return;
    // }

    try {
      const body = {
        dataOwner: userProfile.dataOwner,
        userId: userInfo.userName,
        loginUserCompanyAbbr: userInfo.companyAbbr,
        ...row,
      };

      const response = await cancelVanning(body);
      const msg = responseSuccess(response);
      // setMsgAlert([msg]);
      alertMain.setMsgAlert([msg]);
      window.scrollTo(0, 0);
      setForm(DEFAULT_STATE_WDN95012);
      setOnOpen(false);
      setOnSearch(true);
      return true;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      return false;
    }
  };

  const handleCancelClick = async () => {
    setMode(ModeAction.VIEW);
    setRowSelectionModel([]);
    setForm(DEFAULT_STATE_WDN95012);
    // setOnOpen(false);
  };

  return (
    <Box
      sx={{
        mt: 3,
      }}>
      <DataGridTable
        id="table-container"
        mode={mode}
        setMode={setMode}
        rows={rows}
        onCellClick={false}
        checkboxSelection={true}
        processRowUpdate={processRowUpdate}
        column={{
          columns,
          columnVisibilityModel: {
            // [API_EXPORT_INVOICE_CREATION_INFO.SHIPPING_INFO_H_ID]: false,
            // [API_EXPORT_INVOICE_CREATION_INFO.VANNING_INFO_H_ID]: false,
            // [API_EXPORT_INVOICE_CREATION_INFO.IMPORTER_COMPANY]: false,
            // [API_EXPORT_INVOICE_CREATION_INFO.CONSIGNEE]: false,
          },
        }}
        rowSelection={{
          rowModesModel,
          setRowModesModel,
          rowSelectionModel,
          setRowSelectionModel,
        }}
        pagination={{
          pagination: pagination,
          pageNumber: pageNumber,
          handleChangePagination: handleChangePagination,
        }}
      />
      <SubmitBar
        setOnSearch={setOnSearch}
        mode={onSearch ? ModeAction.EDIT : mode}
        rows={rows}
        onSubmit={handleCancel}
        onCancel={handleCancelClick}
        setMode={setMode}
        setRows={setRows}
        setRowModesModel={setRowModesModel}
        setMsgError={setMsgError}
        rowModesModel={rowModesModel}
        rowSelectionModel={rowSelectionModel}
        setRowSelectionModel={setRowSelectionModel}
        labelBtnSave="Save for Cancel VanningData"
        noRoleRequired={true}
      />
    </Box>
  );
}
