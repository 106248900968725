import React, { useEffect } from "react";
import { Logo, Main, Dashboard } from "components/Layout";
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { ConfirmDialogProvider } from "context/confirmDialogContext";

import RpackageTypeScreen from "pages/Master/RpackageTypeScreen/RpackageTypeScreen";
import RpackageMasterScreen from "pages/Master/RpackageMasterScreen/RpackageMasterScreen";

import RoutePointScreen from "pages/Master/RoutePointScreen";
import PathMasterScreen from "pages/Master/PathMasterScreen";
import LogisticRouteScreen from "pages/Master/LogisticRouteScreen";
import VolumeDimensionScreen from "pages/Master/VolumeDimension/VolumeDimensionScreen";
import GentanIMasterScreen from "pages/Master/GentanIMasterScreen";
import SafetyStockandOverflowScreen from "pages/Master/SafetyStockandOverflowScreen";
import ContainerGroupScreen from "pages/Master/ContainerGroupScreen/ContainerGroupScreen";
import CompanyAndPlantMasterScreen from "pages/Master/CompanyAndPlantMasterScreen/CompanyAndPlantMasterScreen";

import DownloadAndUploadMasterScreen from "pages/Master/DownloadAndUploadMasterScreen/DownloadAndUploadMasterScreen";
import BarcodePrintScreen from "pages/Master/BarcodePrintScreen/BarcodePrintScreen";

// Forecast Block
import ForecastScreen from "pages/Forecast/MonthlyDataUploadScreen";
import ApmcForecastScreen from "pages/Forecast/ApmcForecastScreen";
import ShipmentManagementScreen from "pages/Forecast/ShipmentManagementScreen";

// Master Maintenance Block
import SystemMasterScreen from "pages/MasterMaintenance";

// Common
import LogMonitoringSummaryScreen from "pages/Common/Monitoring/LogMonitoringScreen/LogMonitoringSummaryScreen";
import LogMonitoringDetailScreen from "pages/Common/Monitoring/LogMonitoringScreen/LogMonitoringDetailScreen";
import OnDemandExcelDownloadMonitorScreen from "pages/Common/Monitoring/OnDemandExcelDownloadMonitorScreen";
import ExcelDownloadScreen from "pages/Common/Monitoring/ExcelDownloadScreen";
import ReprocessErrorScreen from "pages/Common/Monitoring/ReprocessErrorScreen";
import OnDemandBatchScreen from "pages/Common/Monitoring/OnDemandBatchScreen";

//INVENTORY
import WarehouseAllocationMaintenanceScreen from "pages/Inventory/RPackageWarehouseAllocationSummaryScreen";
import OtherMaterialStockManagementScreen from "pages/Inventory/OthermaterialStockManagementScreen";
//INVOICE
import ExportInvoiceCreationMaintenanceScreen from "pages/Invoice/ExportInvoiceCreationMaintenanceScreen";
import ImportInvoiceDownloadScreen from "pages/Invoice/ImportInvoiceDownloadScreen";
import ShippingInformationMaintenanceScreen from "pages/Invoice/ShippingInformationMaintenanceScreen";

// HANDHELD
import SendingDataToPOLYGONPlantServerScreen from "pages/Handheld/MainServer/SendingDataToPOLYGONPlantServerScreen";
import UploadingRPackageReceiveDeliverUpdateStatusScreen from "pages/Handheld/MainServer/UploadingRPackageReceiveDeliverUpdateStatusScreen";
import PrintDeliveryInstructionSheetScreen from "pages/Handheld/MainServer/PrintDeliveryInstructionSheetScreen";
import Report from "pages/Report/Report";

import { MainContent } from "configs/auth";
import { MsalProvider } from "@azure/msal-react";
import Login from "components/Layout/LoginTest";
import ProtectedRoute from "components/Layout/ProtectedRoute";

function RedirectTrailingSlash() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname !== "/" && location.pathname.endsWith("/")) {
      navigate(location.pathname.slice(0, -1), { replace: true });
    }
  }, [location, navigate]);

  return null;
}

function App({ instance }) {
  return (
    <ConfirmDialogProvider>
      <Router>
        <RedirectTrailingSlash />
        <Routes>
          {/* <Route path="/" element={<Login />} /> */}
          <Route
            path="/"
            element={
              <MsalProvider instance={instance}>
                <MainContent>
                  {/* <ProtectedRoute> */}
                  <Main />
                  {/* </ProtectedRoute> */}
                </MainContent>
              </MsalProvider>
            }>
            <Route path="/" element={<Logo />} />
            {/* <Route path="/auth" element={<Logo />} /> */}
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/master">
              <Route path="r-package-type" element={<RpackageTypeScreen />} />
              <Route path="r-package" element={<RpackageMasterScreen />} />
              <Route path="route-point" element={<RoutePointScreen />} />
              <Route path="path" element={<PathMasterScreen />} />
              <Route path="logistic" element={<LogisticRouteScreen />} />
              <Route path="volume-dismension" element={<VolumeDimensionScreen />} />
              <Route path="gentan-i" element={<GentanIMasterScreen />} />
              <Route path="safety-stock-and-overflow" element={<SafetyStockandOverflowScreen />} />
              <Route path="container-group" element={<ContainerGroupScreen />} />
              <Route path="company-plant-master" element={<CompanyAndPlantMasterScreen />} />
              <Route path="download-upload-master" element={<DownloadAndUploadMasterScreen />} />
              <Route path="barcode-print" element={<BarcodePrintScreen />} />
            </Route>
            <Route path="/forecast">
              <Route path="forecast-type" element={<ForecastScreen />} />
              <Route path="apmc-forecast" element={<ApmcForecastScreen />} />
              <Route path="shipment-forecast" element={<ShipmentManagementScreen />} />
            </Route>
            <Route path="/master-maintenance">
              <Route path="system-master" element={<SystemMasterScreen />} />
            </Route>
            <Route path="/common/monitoring">
              <Route path="log-monitoring" element={<LogMonitoringSummaryScreen />} />
              <Route path="log-monitoring-detail" element={<LogMonitoringDetailScreen />} />
              <Route path="on-demand-excel-download-monitor" element={<OnDemandExcelDownloadMonitorScreen />} />
              <Route path="excel-download" element={<ExcelDownloadScreen />} />
              <Route path="reprocess-error" element={<ReprocessErrorScreen />} />
              <Route path="on-demand-batch" element={<OnDemandBatchScreen />} />
            </Route>
            <Route path="/inventory">
              <Route path="warehouse-allocation-summary" element={<WarehouseAllocationMaintenanceScreen />} />
              <Route path="other-material-stock-management" element={<OtherMaterialStockManagementScreen />} />
            </Route>
            <Route path="/invoice">
              <Route path="export-invoice-creation" element={<ExportInvoiceCreationMaintenanceScreen />} />
              <Route path="import-invoice-download" element={<ImportInvoiceDownloadScreen />} />
              <Route path="shipping-information-maintenance" element={<ShippingInformationMaintenanceScreen />} />
            </Route>
            <Route path="/report/*" element={<Report />} />
            <Route path="/main-server">
              <Route path="sending-data" element={<SendingDataToPOLYGONPlantServerScreen />} />
              <Route path="uploading-r-package" element={<UploadingRPackageReceiveDeliverUpdateStatusScreen />} />
              <Route path="printing-delivery" element={<PrintDeliveryInstructionSheetScreen />} />
            </Route>
          </Route>
          <Route
            path="/handheld"
            element={
              <>
                <iframe src={process.env.REACT_APP_HANDHELD_URL} height={490} width={395} title="handheld" />
              </>
            }
          />
        </Routes>
      </Router>
    </ConfirmDialogProvider>
  );
}

export default App;
