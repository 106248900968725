import { Box } from "@mui/material";
import { InputButton } from "components/UI";
import { userProfile } from "constant";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { BUTTON_VALUE } from "shared/constants";
import { useLoadingContext } from "shared/contexts/LoadingContext";
import { useUploadingScanningDataUpdateDataMutation } from "shared/services/main-server";
import { responseErrors, responseSuccess } from "utils";

export default function ButtonSendingData({ form, setForm, setMsg: { setMsgError, setMsgAlert }, functionId }) {
  const { startLoading, stopLoading } = useLoadingContext();
  const userInfo = useSelector(state => state.auth.user);
  const uploadApi = useUploadingScanningDataUpdateDataMutation();
  const handleSend = async () => {
    try {
      setMsgAlert([]);
      setMsgError([]);

      const body = {
        impExpCode: !form?.locationId ? "all" : form?.locationId,
        dataType: !form?.dataTypeId ? "all" : form?.dataTypeId,
        userCompanyCd: userInfo.companyAbbr,
        userId: userInfo.userName,
      };
      startLoading();
      const response = await uploadApi(body);
      const msg = responseSuccess(response);
      stopLoading();
      setMsgAlert([msg]);
      return;
    } catch (error) {
      stopLoading();
      const err = responseErrors(error);
      setMsgError(err);
      return;
    }
  };
  const handleClear = () => {
    setMsgAlert([]);
    setMsgError([]);
    setForm({ locationId: "", dataTypeId: "" });
  };
  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          flexFlow: "row wrap",
          justifyContent: "flex-end",
          alignItems: "center",
        }}>
        <InputButton value={BUTTON_VALUE.SEND} onClick={handleSend} name={`${functionId}Send`} />
        <InputButton
          value={BUTTON_VALUE.CLEAR}
          onClick={handleClear}
          onKeyDown={e => {
            if (e.key === "Tab") {
              e.preventDefault();
              document.getElementById("dropdown-location").focus();
            }
          }}
          name={`${functionId}Clear`}
        />
      </Box>
    </Fragment>
  );
}
