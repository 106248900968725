import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { DatePickerField, DropDown, InputTextField } from "components/UI";
import { useExportInvoiceCategoryVanningPlantQuery, useExportInvoicePackingMonthQuery } from "shared/services/invoice";
import { findObject } from "utils";
import { COMMON_ENUM } from "shared/constants";

export default function CriterialSection(props) {
  const {
    mode,
    form,
    setForm,
    dataList: { invoiceOrderTypeData, expData, brokerData, importerData },
    isAPMCUser,
    actionType,
    exporterList,
  } = props;

  // const [parkingMonthData, setParkingMonthData] = useState([]);

  const { data: parkingMonthData } = useExportInvoicePackingMonthQuery({
    etdDt: form?.etdDt,
    impCompanyId: form?.impCompanyId,
    expCd: findObject({
      data: expData,
      value: form?.exporterPlantId,
      property: "plantId",
      field: "exporterCd",
    }),
  });
  const { data: vanningPlantData } = useExportInvoiceCategoryVanningPlantQuery({
    etdDt: form?.etdDt,
    impCompanyId: form?.impCompanyId,
    expCd: findObject({
      data: expData,
      value: form?.exporterPlantId,
      property: "plantId",
      field: "exporterCd",
    }),
  });

  useEffect(() => {
    document.getElementById("input-etd-dt").focus();
  }, []);

  return (
    <Grid container spacing={2}>
      {/* Container item #1 */}
      <Grid container spacing={1} sx={{ alignItems: "center" }}>
        <Grid item xs={2}>
          <strong>Invoice No. : </strong>
        </Grid>
        <Grid item xs={3}>
          <InputTextField
            id="input-invoice-no"
            fullWidth={true}
            maxLength={20}
            value={form?.invNo}
            // regularExp={/^[a-zA-Z0-9_*-]*$/}
            onChange={e =>
              setForm(old => ({
                ...old,
                invNo: e.target.value,
              }))
            }
            disabled={true}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <strong>Invoice Order Type : </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-invoice-order-type"
            fullWidth
            defaultValue=""
            placeholder="<All>"
            value={form?.invType}
            onChange={e =>
              setForm(prev => ({
                ...prev,
                invType: e.target.value,
              }))
            }
            menu={invoiceOrderTypeData?.map(val => ({
              key: val?.cd,
              value: val?.value,
            }))}
            disabled={[COMMON_ENUM.SC_MODE_VIEW, COMMON_ENUM.SC_MODE_EDIT].includes(mode)}
          />
        </Grid>
      </Grid>

      {/* Container item #2 */}
      <Grid container spacing={1} sx={{ alignItems: "center" }}>
        <Grid item xs={2}>
          <strong>*ETD : </strong>
        </Grid>
        <Grid item xs={3}>
          <DatePickerField
            id="input-etd-dt"
            className="input-etd-dt"
            onChange={e =>
              setForm(old => ({
                ...old,
                etdDt: e,
              }))
            }
            value={form?.etdDt}
            disabled={[COMMON_ENUM.SC_MODE_VIEW, COMMON_ENUM.SC_MODE_EDIT].includes(mode)}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <strong>Vessel Name : </strong>
        </Grid>
        <Grid item xs={3}>
          <InputTextField
            id="input-vessel-name"
            fullWidth={true}
            maxLength={40}
            value={form?.vesselName}
            // regularExp={/^[a-zA-Z0-9_*-]*$/}
            onChange={e =>
              setForm(old => ({
                ...old,
                vesselName: e.target.value,
              }))
            }
            disabled={[COMMON_ENUM.SC_MODE_VIEW, COMMON_ENUM.SC_MODE_EDIT].includes(mode)}
          />
        </Grid>
      </Grid>

      {/* Container item #3 */}
      <Grid container spacing={1} sx={{ alignItems: "center", mt: 1 }}>
        <Grid item xs={2}>
          <strong>*Importer Company :</strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-importer-company"
            fullWidth
            required
            defaultValue=""
            placeholder="<Select>"
            value={form?.impCompanyId}
            onChange={e => {
              setForm(prev => ({
                ...prev,
                impCompanyId: e.target.value,
              }));
            }}
            menu={importerData?.map(val => ({
              key: val?.companyId,
              value: val?.companyAbbr,
            }))}
            disabled={[COMMON_ENUM.SC_MODE_VIEW, COMMON_ENUM.SC_MODE_EDIT].includes(mode)}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <strong>*Exporter Code :</strong>
        </Grid>
        <Grid item xs={3}>
          {console.log(exporterList, form?.exporterPlantId)}
          <DropDown
            id="select-exporter-company"
            fullWidth
            required
            defaultValue=""
            placeholder="<Select>"
            value={form?.exporterPlantId}
            onChange={e => {
              setForm(prev => ({
                ...prev,
                exporterPlantId: e.target.value,
              }));
            }}
            menu={exporterList?.map(val => ({
              key: val?.plantId,
              value: val?.exporterCd,
            }))}
            disabled={[COMMON_ENUM.SC_MODE_VIEW, COMMON_ENUM.SC_MODE_EDIT].includes(mode)}
          />
        </Grid>
      </Grid>

      {/* Container item #4 */}
      <Grid container spacing={1} sx={{ alignItems: "center", mt: 1 }}>
        <Grid item xs={2}>
          <strong>Packing Month :</strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-packing-month"
            fullWidth
            defaultValue=""
            placeholder="<All>"
            value={form?.parkingMonth}
            onChange={e => {
              setForm(prev => ({
                ...prev,
                parkingMonth: e.target.value,
              }));
            }}
            menu={parkingMonthData?.map(val => ({
              key: val?.parkingMonth,
              value: val?.parkingMonth,
            }))}
            disabled={[COMMON_ENUM.SC_MODE_VIEW, COMMON_ENUM.SC_MODE_EDIT].includes(mode)}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <strong>Vanning Plant :</strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-vanning-plant"
            fullWidth
            defaultValue=""
            placeholder="<All>"
            value={form?.vanningPlant}
            onChange={e => {
              setForm(prev => ({
                ...prev,
                vanningPlant: e.target.value,
              }));
            }}
            menu={vanningPlantData?.map(val => ({
              key: val?.vanningPlant,
              value: val?.vanningPlant,
            }))}
            disabled={[COMMON_ENUM.SC_MODE_VIEW, COMMON_ENUM.SC_MODE_EDIT].includes(mode)}
          />
        </Grid>
      </Grid>

      {/* Container item #5 */}
      {!isAPMCUser && (
        <Grid container spacing={1} sx={{ alignItems: "center", mt: 1 }}>
          <Grid item xs={2}>
            <strong>*Broker :</strong>
          </Grid>
          <Grid item xs={3}>
            <DropDown
              id="select-broker"
              fullWidth
              required
              defaultValue=""
              placeholder="<Select>"
              value={form?.expBrokerCompanyId}
              onChange={e => {
                setForm(prev => ({
                  ...prev,
                  expBrokerCompanyId: e.target.value,
                }));
              }}
              menu={brokerData?.map(val => ({
                key: val?.companyId,
                value: val?.companyAbbr,
              }))}
              disabled={[COMMON_ENUM.SC_MODE_VIEW, COMMON_ENUM.SC_MODE_EDIT].includes(mode)}
            />
          </Grid>
          <Grid item xs={7} />
        </Grid>
      )}
    </Grid>
  );
}
