import React, { Fragment } from "react";
// Util
import { messageDisplay, responseErrors } from "utils";
import { validationSearchForm, validationUploadFile } from "utils/validation";

//Components
import { Divider, Grid } from "@mui/material";
import { DropDown, InputButton, InputUploadFile } from "components/UI";

// Constant
import { BUTTON_VALUE } from "shared/constants/button.constant";
import { API_REPROCESS } from "shared/constants/api-name/common/reprocess";
import { MSG_TYPE, MessageType, ModeAction } from "state/enum";
import { userProfile } from "constant";
import { isUndefined } from "lodash";
import { useReprocessUploadMutation } from "shared/services/common/monitoring";
import { DEFAULT_REPROCESS_DOWNLOAD, DEFAULT_REPROCESS_UPLOAD } from "../constants/reprocess.constant";
import { useSelector } from "react-redux";
export default function UploadBar(props) {
  const {
    form,
    setForm,
    setFormDownload,
    businessDataList,
    setOnSearch,
    setMode,
    setRows,
    setRowModesModel,
    setRowSelectionModel,
    functionId,
    setMsg: { setMsgAlert, setMsgError },
  } = props;
  const uploadReprocess = useReprocessUploadMutation();
  const userInfo = useSelector(state => state.auth.user);
  // ? Upload File
  const uploadProcess = async (field, file, maxLength) => {
    try {
      setMsgAlert([]);
      setMsgError([]);
      if (isUndefined(file)) {
        setForm(prev => ({
          ...prev,
          file: {},
          [API_REPROCESS.BATCH_ROUND]: "",
        }));
        return true;
      }
      // validate
      const { isSuccess, errors } = validationUploadFile({
        file: file,
        rule: [
          {
            type: "TYPE_EXCEL_MSTD1048AERR",
          },
          {
            type: MessageType.BATCH_ROUND,
            key: [`Uploaded File Name`, maxLength],
          },
        ],
      });
      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
      setForm(prev => ({
        ...prev,
        [API_REPROCESS.FILE]: file,
        [API_REPROCESS.BATCH_ROUND]: file.name,
      }));
      return true;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };
  const handleUploadBtn = async () => {
    try {
      setMsgAlert([]);
      setMsgError([]);
      const body = {
        [API_REPROCESS.DATA_OWNER]: userProfile.dataOwner, // !MOCK
        [API_REPROCESS.BUSINESS_NAME]: form?.businessName,
        [API_REPROCESS.BATCH_ROUND]: form?.batchRound,
        [API_REPROCESS.CREATE_BY]: userInfo.userName,
        file: form.file,
      };
      const { isSuccess, errors } = validationSearchForm({
        data: body,
        rule: [
          {
            field: API_REPROCESS.BUSINESS_NAME,
            type: MessageType.EMPTY,
            key: ["Business Data"],
          },
          {
            field: API_REPROCESS.BATCH_ROUND,
            type: MessageType.EMPTY,
            key: ["Excel File"],
          },
        ],
      });
      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
      const uploadData = new FormData();
      uploadData.append([API_REPROCESS.DATA_OWNER], body.dataOwner);
      uploadData.append([API_REPROCESS.BUSINESS_NAME], body.businessName);
      uploadData.append([API_REPROCESS.BATCH_ROUND], body.batchRound);
      uploadData.append([API_REPROCESS.CREATE_BY], body.createBy);
      uploadData.append([API_REPROCESS.FILE], body.file);
      const response = await uploadReprocess(uploadData);
      console.log(response);
      // const msg = responseSuccess(response);
      const msg = messageDisplay({
        type: MSG_TYPE.INFO,
        code: "MSTD0101AINF",
        msg: [""],
      });
      setMsgAlert([msg]);
      // setMsgAlert([response?.message]);
      setForm(DEFAULT_REPROCESS_UPLOAD);
      window.scrollTo(0, 0);
      document.getElementById("select-upload-business-data").focus();
      return true;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };

  return (
    <Fragment>
      <Grid item xs={12} sx={{ display: "flex", flexFlow: "row wrap" }}>
        <Grid item xs={2}>
          <strong>Business Data:</strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-upload-business-data"
            required
            value={form?.businessName}
            onChange={event => {
              setOnSearch(false);
              setMode(ModeAction.VIEW);
              setRowModesModel({});
              setRowSelectionModel([]);
              setRows({});
              setFormDownload(DEFAULT_REPROCESS_DOWNLOAD);
              setMsgError([]);
              setForm(prev => ({
                ...prev,
                [API_REPROCESS.BUSINESS_NAME]: event.target.value,
                [API_REPROCESS.FILE]: {},
                [API_REPROCESS.BATCH_ROUND]: "",
              }));
            }}
            menu={businessDataList?.map(val => ({
              key: val.businessName,
              value: val.businessName,
            }))}
            disabled={false}
            defaultValue=""
            placeholder="<Select>"
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ mt: 2, mb: 1 }}>
        <strong>Please browse the Excel file, and click Upload button.</strong>
      </Grid>
      <Grid item xs={2}>
        <strong>*Excel File:</strong>
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          display: "flex",
          flexFlow: "row wrap",
          justifyContent: "flex-start",
          alignItems: "center",
        }}>
        <InputUploadFile
          id="input-upload-excel"
          fileType=".xlsx"
          required={true}
          onConfirmDelete={false}
          value={form?.batchRound}
          uploadProcess={uploadProcess}
          maxLength={50}
        />
      </Grid>
      <Grid
        item
        xs={4}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          pl: "5px",
        }}>
        <InputButton
          id="btn-upload"
          value={BUTTON_VALUE.UPLOAD}
          onClick={handleUploadBtn}
          name={`${functionId}Upload`}
        />
      </Grid>
      <Grid item xs={12} sx={{ mt: 1, mb: 2 }}>
        <Divider />
      </Grid>
    </Fragment>
  );
}
