import { Grid } from "@mui/material";
import { DatePickerField, DropDown } from "components/UI";
import { FORM_DEFAULT_STOCK_IN_OUT } from "pages/Report/constants/constants";
import React, { useEffect, useState } from "react";
import ButtonAction from "../ButtonAction";
import {
  useExcelDownloadOnlineMutation,
  useReportCommonGetLocationStockInOutQuery,
  useReportCommonGetRPackageOwnerQuery,
  useReportCommonGetRPackageTypeQuery,
} from "shared/services/report";
import { userProfile } from "constant";
import { responseErrors } from "utils";
import { useLoadingContext } from "shared/contexts/LoadingContext";
import { waitAndDownloadExcel } from "shared/services/common-dowload/download-helper-service";
import { useSelector } from "react-redux";
export default function StockInOutReport(props) {
  const userInfo = useSelector(state => state.auth.user);
  const [form, setForm] = useState(FORM_DEFAULT_STOCK_IN_OUT);

  const { data: rPkgOwnerList } = useReportCommonGetRPackageOwnerQuery();
  const { data: rPkgTypeList } = useReportCommonGetRPackageTypeQuery(form.rPkgTypeOwner);
  const { data: locationFromToList } = useReportCommonGetLocationStockInOutQuery({
    dataOwner: userProfile.dataOwner,
    locationType: "routePoint",
  });

  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(false);
  const generateReport = useExcelDownloadOnlineMutation();
  const { startLoading, stopLoading } = useLoadingContext();
  const handleDownload = async () => {
    try {
      props.setMsgError([]);
      props.setMsgAlert([]);
      const body = {
        userId: userInfo.userName,
        displayName: userInfo.displayName,
        dataOwner: userProfile.dataOwner,
        rPackageType: form?.rPkgType,
        monthFrom: form?.monthForm,
        monthTo: form?.monthTo,
        rPackageOwner: form?.rPkgTypeOwner,
        locationFrom: form?.locationFrom,
        locationTo: form?.locationTo,
      };
      const pathReport = "generate-stock-in-out-report";
      startLoading();
      const response = await generateReport(body, pathReport);
      waitAndDownloadExcel(60, response.data, props.setMsgError, stopLoading);
      setDisabledSubmitBtn(false);
      return;
    } catch (error) {
      const msgError = responseErrors(error);
      props.setMsgError(msgError);
      setDisabledSubmitBtn(false);
      stopLoading();
    }
  };

  useEffect(() => {
    document.getElementById("select-rpackage-owner").focus();
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={5} style={{ display: "flex", paddingTop: "7px" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>*Report : </strong>
          <DropDown
            id="select-report"
            fullWidth
            required
            defaultValue=""
            placeholder="<Select>"
            menu={[{ key: "1", value: "Stock IN OUT Report" }]}
            value={form?.reportId}
            disabled
          />
        </Grid>
        <Grid item xs={7} />
        <Grid item xs={5} style={{ display: "flex", paddingTop: "7px" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>*R-Package Owner : </strong>
          <DropDown
            id="select-rpackage-owner"
            fullWidth
            required
            value={form?.rPkgTypeOwner}
            defaultValue=""
            placeholder="<Select>"
            menu={rPkgOwnerList?.map(val => ({
              key: val.rPkgOwnerCompAbbr,
              value: val.rPkgOwnerCompAbbr,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                rPkgTypeOwner: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", paddingTop: "7px" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>R-Package Type : </strong>
          <DropDown
            id="select-rpackage-type"
            fullWidth
            value={form?.rPkgType}
            defaultValue=""
            placeholder="<All>"
            // autoFocus={true}
            menu={rPkgTypeList?.map(val => ({
              key: `${val.rPkgType}:${val.rPkgTypeId}`,
              value: val.rPkgType,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                rPkgType: e.target.value,
              }))
            }
            disabled={!form.rPkgTypeOwner?.length}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={4} style={{ display: "flex", paddingTop: "3px" }}>
          <strong style={{ margin: "auto 0px", width: "338px" }}>*Month (From) : </strong>
          <DatePickerField
            views={["month", "year"]}
            format="MM/YYYY"
            mask="MM/YYYY"
            fullWidth={true}
            value={form?.monthForm}
            onChange={e =>
              setForm(old => ({
                ...old,
                monthForm: e,
              }))
            }
            required
          />
        </Grid>
        <Grid item xs={2} style={{ margin: "auto 0px" }}>
          (MM/YYYY)
        </Grid>
        <Grid item xs={4} style={{ display: "flex", paddingTop: "3px" }}>
          <strong style={{ margin: "auto 0px", width: "338px" }}>*Month (To) : </strong>
          <DatePickerField
            views={["month", "year"]}
            format="MM/YYYY"
            mask="MM/YYYY"
            fullWidth={true}
            value={form?.monthTo}
            onChange={e =>
              setForm(old => ({
                ...old,
                monthTo: e,
              }))
            }
            required
          />
        </Grid>
        <Grid item xs={2} style={{ margin: "auto 0px" }}>
          (MM/YYYY)
        </Grid>
        <Grid item xs={5} style={{ display: "flex", paddingTop: "5px", marginBottom: "15px" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>Location (From) : </strong>
          <DropDown
            id="select-location-from"
            fullWidth
            value={form?.locationFrom}
            defaultValue=""
            placeholder="<All>"
            menu={locationFromToList?.map(val => ({
              key: val?.cd,
              value: val?.name,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                locationFrom: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", paddingTop: "5px", marginBottom: "15px" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>Location (To) : </strong>
          <DropDown
            id="select-location-to"
            fullWidth
            value={form?.locationTo}
            defaultValue=""
            placeholder="<All>"
            menu={locationFromToList?.map(val => ({
              key: val?.cd,
              value: val?.name,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                locationTo: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <ButtonAction
          handleDownload={handleDownload}
          setFrom={setForm}
          defaultFrom={FORM_DEFAULT_STOCK_IN_OUT}
          disabled={disabledSubmitBtn}
          setDisabled={setDisabledSubmitBtn}
          clearMsg={() => {
            props.setMsgAlert([]);
            props.setMsgError([]);
          }}
          firstField={"select-rpackage-owner"}
        />
      </Grid>
    </>
  );
}
