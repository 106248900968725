import { Grid } from "@mui/material";
import { DatePickerField, DropDown, InputTextField } from "components/UI";
import { FORM_DEFAULT_TRANSACTION_RAW_DATA } from "pages/Report/constants/constants";
import React, { useEffect, useState } from "react";
import ButtonAction from "../ButtonAction";
import {
  useReportCommonGetRPackageOwnerQuery,
  useReportCommonGetRPackageTypeQuery,
  useReportCommonGetLocationNameListQuery,
  useReportGetTransactionModeQuery,
  useReportGetTransactionCategoryQuery,
  useExcelDownloadOnDemandMutation,
} from "shared/services/report";
import { userProfile } from "constant";
import { responseErrors } from "utils";

import { useLoadingContext } from "shared/contexts/LoadingContext";
import { useSelector } from "react-redux";
export default function TransactionRawDataReport(props) {
  const userInfo = useSelector(state => state.auth.user);
  const [form, setForm] = useState(FORM_DEFAULT_TRANSACTION_RAW_DATA);

  const { data: rPkgOwnerList } = useReportCommonGetRPackageOwnerQuery();
  const { data: rPkgTypeList } = useReportCommonGetRPackageTypeQuery(form.rPkgTypeOwner);
  const { data: locationFromToList } = useReportCommonGetLocationNameListQuery(userProfile.dataOwner);
  const { data: transactionModeList } = useReportGetTransactionModeQuery();
  const { data: transactionCategoryList } = useReportGetTransactionCategoryQuery();

  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(false);

  const generateReport = useExcelDownloadOnDemandMutation();
  const { startLoading, stopLoading } = useLoadingContext();
  const handleDownload = async () => {
    try {
      setDisabledSubmitBtn(true);
      props.setMsgError([]);
      props.setMsgAlert([]);
      const body = {
        userId: userInfo.userName,
        displayName: userInfo.displayName,
        dataOwner: userProfile.dataOwner,
        transactionDateFrom: form?.transactionDateFrom,
        transactionDateTo: form?.transactionDateTo,
        rPackageType: form?.rPkgType,
        barcodeId: form?.barcodeId,
        locationFrom: form?.locationFrom,
        locationTo: form?.locationTo,
        rPackageOwner: form?.rPkgTypeOwner,
        transactionMode: form?.transactionMode,
        transactionCategory: form?.transactionCategory,
      };
      const pathReport = "generate-transaction-raw-data-report";
      startLoading();
      const { result } = await generateReport(body, pathReport);
      props.setMsgAlert([result?.message]);
      stopLoading();
      // waitAndDownloadExcel(120, response, props.setMsgError, stopLoading);
      setDisabledSubmitBtn(false);
      return;
    } catch (error) {
      const msgError = responseErrors(error);
      props.setMsgError(msgError);
      setDisabledSubmitBtn(false);
      stopLoading();
    }
  };

  useEffect(() => {
    document.getElementById("input-transaction-date-from").focus();
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={5} style={{ display: "flex" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>*Report : </strong>
          <DropDown
            id="select-report"
            fullWidth
            required
            defaultValue=""
            placeholder="<Select>"
            menu={[
              {
                key: "LDN960F0",
                value: "Transaction Raw Data Report",
              },
            ]}
            value={"LDN960F0"}
            disabled
          />
        </Grid>
        <Grid item xs={7} />
        <Grid item xs={4} style={{ display: "flex", paddingTop: "5px" }}>
          <strong style={{ margin: "auto 0px", width: "338px" }}>*Transaction Date (From) : </strong>
          <DatePickerField
            id="input-transaction-date-from"
            className="input-transaction-date-from"
            onChange={e =>
              setForm(old => ({
                ...old,
                transactionDateFrom: e,
              }))
            }
            value={form?.transactionDateFrom}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={4} style={{ display: "flex", paddingTop: "5px" }}>
          <strong style={{ margin: "auto 0px", width: "338px" }}>Transaction Date (To): </strong>
          <DatePickerField
            id="input-transaction-date-to"
            className="input-transaction-date-to"
            onChange={e =>
              setForm(old => ({
                ...old,
                transactionDateTo: e,
              }))
            }
            value={form?.transactionDateTo}
            fullWidth
          />
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={5} style={{ display: "flex", paddingTop: "6px" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>R-Package Owner : </strong>
          <DropDown
            id="select-rpackage-owner"
            fullWidth
            value={form?.rPkgTypeOwner}
            defaultValue=""
            placeholder="<All>"
            menu={rPkgOwnerList?.map(val => ({
              key: val?.rPkgOwnerCompAbbr,
              value: val?.rPkgOwnerCompAbbr,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                rPkgTypeOwner: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", paddingTop: "6px" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>R-Package Type : </strong>
          <DropDown
            id="select-rpackage-type"
            fullWidth
            value={form?.rPkgType}
            defaultValue=""
            placeholder="<All>"
            menu={rPkgTypeList?.map(val => ({
              key: val?.rPkgTypeId,
              value: `${val?.rPkgType}`,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                rPkgType: e.target.value,
              }))
            }
            disabled={!form?.rPkgTypeOwner.length}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", paddingTop: "6px" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>Transaction Mode : </strong>
          <DropDown
            id="select-transaction-mode"
            fullWidth
            value={form?.transactionMode}
            defaultValue=""
            placeholder="<All>"
            menu={transactionModeList?.map(val => ({
              key: val?.cd,
              value: val?.value,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                transactionMode: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", paddingTop: "6px" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>Transaction Category : </strong>
          <DropDown
            id="select-transaction-category"
            fullWidth
            value={form?.transactionCategory}
            defaultValue=""
            placeholder="<All>"
            menu={transactionCategoryList?.map(val => ({
              key: val?.cd,
              value: val?.value,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                transactionCategory: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", paddingTop: "6px" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>Location (From) : </strong>
          <DropDown
            id="select-location-from"
            fullWidth
            value={form?.locationFrom}
            defaultValue=""
            placeholder="<All>"
            menu={locationFromToList?.map(val => ({
              key: val?.cd,
              value: val?.name,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                locationFrom: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", paddingTop: "6px" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>Location (To) : </strong>
          <DropDown
            id="select-location-to"
            fullWidth
            value={form?.locationTo}
            defaultValue=""
            placeholder="<All>"
            menu={locationFromToList?.map(val => ({
              key: val?.cd,
              value: val?.name,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                locationTo: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", paddingTop: "0px" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>Barcode ID : </strong>
          <InputTextField
            id="input-barcode-id"
            fullWidth={true}
            maxLength={14}
            value={form?.barcodeId}
            onChange={e =>
              setForm(old => ({
                ...old,
                barcodeId: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />

        <ButtonAction
          handleDownload={handleDownload}
          setFrom={setForm}
          defaultFrom={FORM_DEFAULT_TRANSACTION_RAW_DATA}
          disabled={disabledSubmitBtn}
          setDisabled={setDisabledSubmitBtn}
          clearMsg={() => {
            props.setMsgAlert([]);
            props.setMsgError([]);
          }}
          firstField={"input-transaction-date-from"}
        />
      </Grid>
    </>
  );
}
