import { Box, Grid } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import {
  InputText,
  // InputDropDown,
  AlertMessage,
  DatePickerField,
} from "components/UI/index";
import { InputDropDown } from "components/UI";
import { ModeAction } from "state/enum";
import { HeaderContentBar } from "components/Layout";
import {
  useCategory,
  useGetLocation,
  useGetMaker,
  useGetType,
} from "service/rpackagemaster";
import RmoduleTable from "./partials/RmoduleTable";
import OtherMaterialsTable from "./partials/OtherMaterialsTable";
import { userProfile } from "constant";
import { allowAlphabhetNumberUnderScore } from "utils/allowPattern";

const RpackageMasterScreen = () => {
  const [mode, setMode] = useState("view");

  //Initial search form
  const [searchForm, setSearchForm] = useState({
    rPkgCategoryId: "",
    rPkgTypeId: "",
    poNo: "",
    barcodeId: "",
    rMdMaker: "",
    makerPlantId: "",
    locationId: "",
    firstReceiveDtFrom: "",
    firstReceiveDtTo: "",
  });

  const [onSearch, setOnSearch] = useState(false);
  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);

  useEffect(() => {
    document.getElementById("select_rPkgCategoryId").focus();
  }, []);

  // 2. api
  const cdOrder = { RM: 1, OT: 2 };
  const { data: categoryData } = useCategory();
  categoryData?.sort((a, b) => cdOrder[a.cd] - cdOrder[b.cd]);

  const { data: typeData, refetch: refetchTypeData } = useGetType({
    dataOwner: userProfile.dataOwner, // TODO: get dataOwner from profile
    rPkgOwnerCompAbbr: userProfile.dataOwner, // TODO: get dataOwner from profile
    cd: searchForm?.rPkgCategoryId,
  });

  const { data: makerData, refetch: refetchMakerData } = useGetMaker({
    dataOwner: userProfile.dataOwner, // TODO: get dataOwner from profile
    cd: searchForm?.rPkgCategoryId,
  });

  const { data: locationData } = useGetLocation({
    dataOwner: userProfile.dataOwner, // TODO: get dataOwner from profile
  });

  useEffect(() => {
    const handleTabKey = (event) => {
      if (event.key === "Tab") {
        if (searchForm.rPkgCategoryId === "") {
          event.preventDefault();
        }
      }
    };

    window.addEventListener("keydown", handleTabKey);

    return () => {
      window.removeEventListener("keydown", handleTabKey);
    };
  }, [searchForm.rPkgCategoryId]);

  const handleChangeRPackageCategory = (event) => {
    setSearchForm({
      rPkgCategoryId: event.target.value,
      rPkgTypeId: "",
      poNo: "",
      barcodeId: "",
      rMdMaker: "",
      makerPlantId: "",
      locationId: "",
      firstReceiveDtFrom: "",
      firstReceiveDtTo: "",
    });

    setMsgError([]);
    setMsgAlert([]);
  };

  return (
    <Fragment>
      <Box sx={{ padding: "1rem" }}>
        <HeaderContentBar title="WDN91020 : R-Package Master Maintenance Screen" />
        <AlertMessage type={"warning"} message={msgError} />
        <AlertMessage type={"success"} message={msgAlert} />
        <Grid container spacing={0} sx={{ alignItems: "center" }}>
          <Grid item xs={5} style={{ display: "flex" }}>
            <strong style={{ margin: "auto 0px", width: "325px" }}>
              * R-Package Category :{" "}
            </strong>
            <InputDropDown
              id="select_rPkgCategoryId"
              required
              value={searchForm.rPkgCategoryId}
              onChange={(e) => {
                handleChangeRPackageCategory(e);
              }}
              memu={categoryData?.map((val) => ({
                key: val.cd,
                value: val.value,
              }))}
              disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
              placeholder="<Select>"
              defaultValue=""
            />
          </Grid>
          <Grid item xs={7} />
          {searchForm.rPkgCategoryId === "RM" && (
            <>
              <Grid item xs={5} style={{ display: "flex" }}>
                <strong style={{ margin: "auto 0px", width: "325px" }}>
                  * R-Package Type :{" "}
                </strong>
                <InputDropDown
                  id="select_rPkgTypeId"
                  required
                  value={searchForm.rPkgTypeId}
                  onChange={(e) =>
                    setSearchForm((old) => ({
                      ...old,
                      rPkgTypeId: e.target.value,
                    }))
                  }
                  memu={typeData?.map((val) => ({
                    key: val.rPkgTypeId,
                    value: val.rPkgType,
                  }))}
                  disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
                  placeholder="<Select>"
                  defaultValue=""
                />
              </Grid>
              <Grid item xs={1} />

              <Grid item xs={5} style={{ display: "flex" }}>
                <strong style={{ margin: "auto 0px", width: "325px" }}>
                  R-Module Maker :{" "}
                </strong>
                <InputDropDown
                  id="select_makerPlantId"
                  value={searchForm.makerPlantId}
                  onChange={(e) =>
                    setSearchForm((old) => ({
                      ...old,
                      makerPlantId: e.target.value,
                    }))
                  }
                  memu={makerData?.map((val) => ({
                    key: val.plantId,
                    value: val.impExpCd,
                  }))}
                  disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
                  placeholder="<All>"
                  defaultValue=""
                />
              </Grid>
              <Grid item xs={1} />

              <Grid item xs={5} style={{ display: "flex" }}>
                <strong style={{ margin: "auto 0px", width: "325px" }}>
                  P/O Number :{" "}
                </strong>
                <InputText
                  id="input_poNo"
                  fullWidth={true}
                  maxLength={20}
                  value={searchForm.poNo}
                  regularExp={/^[a-zA-Z0-9_*-]*$/}
                  onChange={(e) =>
                    setSearchForm((old) => ({
                      ...old,
                      poNo: e.target.value?.toUpperCase(),
                    }))
                  }
                  disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
                />
              </Grid>
              <Grid item xs={1} />

              <Grid item xs={5} style={{ display: "flex" }}>
                <strong style={{ margin: "auto 0px", width: "325px" }}>
                  R-Module Barcode ID :{" "}
                </strong>
                <InputText
                  id="input_barcodeId"
                  fullWidth={true}
                  maxLength={14}
                  value={searchForm.barcodeId}
                  regularExp={/^[a-zA-Z0-9*]*$/}
                  onChange={(e) =>
                    setSearchForm((old) => ({
                      ...old,
                      barcodeId: e.target.value?.toUpperCase(),
                    }))
                  }
                  disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
                />
              </Grid>
              <Grid item xs={1} />

              <Grid item xs={5} style={{ display: "flex" }}>
                <strong style={{ margin: "auto 0px", width: "325px" }}>
                  Location :{" "}
                </strong>
                <InputDropDown
                  id="select_locationId"
                  value={searchForm.locationId}
                  onChange={(e) =>
                    setSearchForm((old) => ({
                      ...old,
                      locationId: e.target.value,
                    }))
                  }
                  memu={locationData?.map((val) => ({
                    key: val.cd,
                    value: val.value,
                  }))}
                  disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
                  placeholder="<All>"
                  defaultValue=""
                />
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={5}></Grid>

              <Grid item xs={5} style={{ display: "flex" }}>
                <strong style={{ margin: "auto 0px", width: "325px" }}>
                  First Received Date (From) :{" "}
                </strong>
                <DatePickerField
                  id="input_firstReceiveDtFrom"
                  className="input_firstReceiveDtFrom"
                  onChange={(e) =>
                    setSearchForm((old) => ({
                      ...old,
                      firstReceiveDtFrom: e,
                    }))
                  }
                  value={searchForm.firstReceiveDtFrom}
                  disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={1}></Grid>

              <Grid item xs={5} style={{ display: "flex" }}>
                <strong style={{ margin: "auto 0px", width: "325px" }}>
                  First Received Date (To) :
                </strong>
                <DatePickerField
                  id="input_firstReceiveDtTo"
                  className="input_firstReceiveDtTo"
                  onChange={(e) =>
                    setSearchForm((old) => ({
                      ...old,
                      firstReceiveDtTo: e,
                    }))
                  }
                  value={searchForm.firstReceiveDtTo}
                  disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
                  fullWidth
                />
              </Grid>
            </>
          )}
          {searchForm.rPkgCategoryId === "OT" && (
            <>
              <Grid item xs={5} style={{ display: "flex" }}>
                <strong style={{ margin: "auto 0px", width: "325px" }}>
                  R-Package Type:{" "}
                </strong>
                <InputDropDown
                  id="select_rPkgTypeId"
                  value={searchForm.rPkgTypeId}
                  onChange={(e) =>
                    setSearchForm((old) => ({
                      ...old,
                      rPkgTypeId: e.target.value,
                    }))
                  }
                  memu={typeData?.map((val) => ({
                    key: val.rPkgTypeId,
                    value: val.rPkgType,
                  }))}
                  disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
                  placeholder="<All>"
                  defaultValue=""
                />
              </Grid>
              <Grid item xs={1} />

              <Grid item xs={5} style={{ display: "flex" }}>
                <strong style={{ margin: "auto 0px", width: "325px" }}>
                  Other Material Maker :{" "}
                </strong>
                <InputDropDown
                  id="select_makerPlantId"
                  value={searchForm.makerPlantId}
                  onChange={(e) =>
                    setSearchForm((old) => ({
                      ...old,
                      makerPlantId: e.target.value,
                    }))
                  }
                  memu={makerData?.map((val) => ({
                    key: val.plantId,
                    value: val.impExpCd,
                  }))}
                  disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
                  placeholder="<All>"
                  defaultValue=""
                />
              </Grid>
              <Grid item xs={1} />

              <Grid item xs={5} style={{ display: "flex" }}>
                <strong style={{ margin: "auto 0px", width: "325px" }}>
                  P/O Number :{" "}
                </strong>
                <InputText
                  id="input_poNo"
                  fullWidth={true}
                  value={searchForm.poNo}
                  pattern="[0-9]*"
                  regularExp={/^[a-zA-Z0-9_*-]*$/}
                  maxLength={20}
                  onChange={(e) =>
                    setSearchForm((old) => ({
                      ...old,
                      poNo: allowAlphabhetNumberUnderScore(
                        old.poNo,
                        e.target.value
                      )?.toUpperCase(),
                    }))
                  }
                  disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
                />
              </Grid>
              <Grid item xs={1} />
              {searchForm.rPkgCategoryId === "OT" && <Grid item xs={3} />}
              <Grid item xs={5} style={{ display: "flex" }}>
                <strong style={{ margin: "auto 0px", width: "325px" }}>
                  First Received Date (From) :{" "}
                </strong>
                <DatePickerField
                  id="input_firstReceiveDtFrom"
                  className="input_firstReceiveDtFrom"
                  onChange={(e) =>
                    setSearchForm((old) => ({
                      ...old,
                      firstReceiveDtFrom: e,
                    }))
                  }
                  value={searchForm.firstReceiveDtFrom}
                  disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={1} />

              <Grid item xs={5} style={{ display: "flex" }}>
                <strong style={{ margin: "auto 0px", width: "325px" }}>
                  First Received Date (To) :
                </strong>
                <DatePickerField
                  id="input_firstReceiveDtTo"
                  className="input_firstReceiveDtTo"
                  onChange={(e) =>
                    setSearchForm((old) => ({
                      ...old,
                      firstReceiveDtTo: e,
                    }))
                  }
                  value={searchForm.firstReceiveDtTo}
                  disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
                  fullWidth
                />
              </Grid>
            </>
          )}
        </Grid>
      </Box>
      {searchForm.rPkgCategoryId === "RM" && (
        <RmoduleTable
          searchForm={searchForm}
          setSearchForm={setSearchForm}
          setMsgError={setMsgError}
          setMsgAlert={setMsgAlert}
          setOnSearch={setOnSearch}
          onSearch={onSearch}
          mode={mode}
          setMode={setMode}
          refetchTypeData={refetchTypeData}
          refetchMakerData={refetchMakerData}
        />
      )}
      {searchForm.rPkgCategoryId === "OT" && (
        <OtherMaterialsTable
          searchForm={searchForm}
          setSearchForm={setSearchForm}
          setMsgError={setMsgError}
          setMsgAlert={setMsgAlert}
          setOnSearch={setOnSearch}
          onSearch={onSearch}
          mode={mode}
          setMode={setMode}
          refetchTypeData={refetchTypeData}
          refetchMakerData={refetchMakerData}
        />
      )}
    </Fragment>
  );
};

export default RpackageMasterScreen;
