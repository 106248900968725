import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  userInfo: null,
  role: null,
  token: null,
  currentWebComponent: null,
  sessionExpiry: null,
};
// Load session state from sessionStorage
const savedSession = sessionStorage.getItem("appSession");
const persistedState = savedSession ? JSON.parse(savedSession) : initialState;

const slice = createSlice({
  name: "auth",
  initialState: persistedState,
  reducers: {
    setAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
    setCredentials: (state, { payload: { userInfo, role, token, sessionExpiry } }) => {
      state.userInfo = userInfo;
      state.role = role;
      state.token = token;
      state.sessionExpiry = sessionExpiry;
    },
    setCurrentWebComponent: (state, action) => {
      state.currentWebComponent = action.payload;
    },
    clearSession: () => {
      return initialState;
    },
  },
});
export const { setCredentials, setAuthenticated, setUser, setRole, setCurrentWebComponent, clearSession } =
  slice.actions;

export default slice.reducer;

export const authReducer = state => state.auth.user;

// Middleware to save state to sessionStorage
export const sessionMiddleware = storeAPI => next => action => {
  const result = next(action);
  const state = storeAPI.getState();
  sessionStorage.setItem("appSession", JSON.stringify(state.auth));
  return result;
};
