import React, { Fragment, useCallback } from "react";
//Component
import { DataGridTable, SubmitBar } from "components/UI";
//  Utils
import { validationSearchForm } from "utils/validation.js";
import { responseErrors, messageDisplay, responseSuccess } from "utils";
// Type
import { MessageType, ModeAction, MSG_TYPE } from "state/enum";
import { API_SYSTEM_MASTER, PAGINATION, ROW_PER_PAGE, FIRST_PAGE } from "shared/constants";
import { userProfile } from "constant";
import {
  useSystemMasterCreateMutation,
  useSystemMasterUpdateMutation,
} from "shared/services/master-maintenance/system-master.service";
import { useLoadingContext } from "shared/contexts/LoadingContext";
import { useSelector } from "react-redux";
export default function TableBar(props) {
  const {
    form,
    mode,
    setMode,
    onSearch,
    rows,
    setRows,
    columns,
    setPagination,
    setPageNumber,
    setRowModesModel,
    rowModesModel,
    rowSelectionModel,
    setRowSelectionModel,
    pagination,
    pageNumber,
    getSearch,
    setOnSearch,
    setMsg: { setMsgAlert, setMsgError },
    functionId,
  } = props;
  const userInfo = useSelector(state => state.auth.user);
  const { startLoading, stopLoading } = useLoadingContext();
  const createSystemMaster = useSystemMasterCreateMutation();
  const updateSystemMaster = useSystemMasterUpdateMutation();
  const handleChangePagination = async (event, value) => {
    try {
      setPageNumber(value);
      const body = {
        [API_SYSTEM_MASTER.DATA_OWNER]: userProfile.dataOwner,
        [API_SYSTEM_MASTER.CATEGORY]: form?.category,
        [API_SYSTEM_MASTER.SUB_CATEGORY]: form?.subCategory,
        [API_SYSTEM_MASTER.CODE]: form?.code,
        [PAGINATION.PAGE_NUMBER]: value,
        [PAGINATION.ROW_PER_PAGE]: ROW_PER_PAGE,
      };
      startLoading();
      const { searchData, data } = await getSearch(body);
      stopLoading();
      if (!data.length) {
        setMsgError([
          messageDisplay({
            type: MSG_TYPE.ERROR,
            code: "MSTD0059AERR",
            msg: "",
          }),
        ]);
        return;
      }
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
    } catch (error) {
      stopLoading();
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
    }
  };
  const handleCreate = async newRow => {
    try {
      const createData = {
        [API_SYSTEM_MASTER.DATA_OWNER]: userProfile.dataOwner, // !MOCK
        [API_SYSTEM_MASTER.CATEGORY]: newRow?.category,
        [API_SYSTEM_MASTER.SUB_CATEGORY]: newRow?.subCategory,
        [API_SYSTEM_MASTER.CODE]: newRow?.code,
        [API_SYSTEM_MASTER.VALUE]: newRow?.value,
        [API_SYSTEM_MASTER.REMARK]: newRow?.remark,
        [API_SYSTEM_MASTER.STATUS_ID]: newRow?.status === "active" ? "Y" : "N",
        [API_SYSTEM_MASTER.CREATE_BY]: userInfo.userName,
      };
      const { isSuccess, errors } = validationSearchForm({
        data: createData,
        rule: [
          {
            field: API_SYSTEM_MASTER.SUB_CATEGORY,
            type: MessageType.EMPTY,
            key: ["Sub Category"],
          },
          {
            field: API_SYSTEM_MASTER.CODE,
            type: MessageType.EMPTY,
            key: ["Code"],
          },
          {
            field: API_SYSTEM_MASTER.VALUE,
            type: MessageType.EMPTY,
            key: ["Value"],
          },
          {
            field: API_SYSTEM_MASTER.STATUS_ID,
            type: MessageType.EMPTY,
            key: ["Status"],
          },
          {
            field: API_SYSTEM_MASTER.CATEGORY,
            type: MessageType.EMPTY,
            key: ["Category"],
          },
        ],
      });
      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return false;
      }
      const response = await createSystemMaster(createData);
      const msg = responseSuccess(response);
      setMsgAlert([msg]);
      window.scrollTo(0, 0);
      return true;
    } catch (error) {
      stopLoading();
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return false;
    }
  };
  const handleUpdate = async row => {
    try {
      const category = row?.category;
      const updateData = {
        [API_SYSTEM_MASTER.DATA_OWNER]: userProfile?.dataOwner,
        [API_SYSTEM_MASTER.CATEGORY]: row?.category,
        [API_SYSTEM_MASTER.SUB_CATEGORY]: row?.subCategory,
        [API_SYSTEM_MASTER.CODE]: row?.code,
        [API_SYSTEM_MASTER.VALUE]: row?.value,
        [API_SYSTEM_MASTER.REMARK]: row?.remark,
        [API_SYSTEM_MASTER.STATUS_ID]: row?.status === "active" ? "Y" : "N",
        [API_SYSTEM_MASTER.UPDATE_BY]: userInfo.userName,
      };

      const { isSuccess, errors } = validationSearchForm({
        data: updateData,
        rule: [
          {
            field: API_SYSTEM_MASTER.VALUE,
            type: MessageType.EMPTY,
            key: ["Value"],
          },
        ],
      });
      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return false;
      }
      const response = await updateSystemMaster(category, updateData);
      const msg = responseSuccess(response);
      setMsgAlert([msg]);
      window.scrollTo(0, 0);
      return true;
    } catch (error) {
      stopLoading();

      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return false;
    }
  };
  const handleProcessRowUpdateError = useCallback(error => {
    // console.error(` children:${error.message}, severity: ${error}`);
  }, []);
  const processRowUpdate = async newRow => {
    try {
      let response;
      setMsgError([]);
      setMsgAlert([]);
      startLoading();
      if (newRow.isNew) {
        response = await handleCreate(newRow);
      } else {
        response = await handleUpdate(newRow);
      }
      // throw to onProcessRowUpdate is error
      if (!response) {
        stopLoading();
        return false;
      }
      if (rows.length === 1 && ModeAction.ADD === mode) {
        setMode(ModeAction.VIEW);
        setRowSelectionModel([]);
        setRowModesModel({});
        setRows([]);
        setPagination({});
        setPageNumber(FIRST_PAGE);
        setOnSearch(false);
        stopLoading();
      } else {
        setMode(ModeAction.VIEW);
        setRowSelectionModel([]);
        const body = {
          [API_SYSTEM_MASTER.DATA_OWNER]: userProfile.dataOwner,
          [API_SYSTEM_MASTER.CATEGORY]: form?.category,
          [API_SYSTEM_MASTER.SUB_CATEGORY]: form?.subCategory,
          [API_SYSTEM_MASTER.CODE]: form?.code,
          [PAGINATION.PAGE_NUMBER]: pageNumber,
          [PAGINATION.ROW_PER_PAGE]: ROW_PER_PAGE,
        };
        const { searchData, data } = await getSearch(body);
        stopLoading();

        if (!data.length) {
          setMode(ModeAction.VIEW);
          setOnSearch(false);
          setRowSelectionModel([]);
          setRowModesModel({});
          setRows([]);
          setPagination({});
          setPageNumber(FIRST_PAGE);
          return;
        }
        setMode(ModeAction.VIEW);
        setRowSelectionModel([]);
        setRowModesModel({});
        setOnSearch(false);
        setPagination(searchData?.result?.pagination ?? {});
        setPageNumber(searchData?.result?.pagination?.pageNumber);
        setRows(data);
        setOnSearch(true);
      }
      const updatedRow = { ...newRow, isNew: false };
      return updatedRow;
    } catch (error) {
      stopLoading();
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };

  return (
    <Fragment>
      {onSearch && (
        <DataGridTable
          id="table-master-system"
          mode={mode}
          setMode={setMode}
          onSearch={onSearch}
          rows={rows}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          isMultipleSelection={false}
          checkboxSelection={true}
          rowHeight={45}
          autoHeight={true}
          onCellClick={false}
          rowSelection={{
            rowModesModel: rowModesModel,
            setRowModesModel: setRowModesModel,
            rowSelectionModel: rowSelectionModel,
            setRowSelectionModel: setRowSelectionModel,
          }}
          pagination={{
            pagination: pagination,
            pageNumber: pageNumber,
            handleChangePagination: handleChangePagination,
          }}
          column={{
            columns: columns,
            columnGroupingModel: null,
            columnVisibilityModel: {
              // [API_SYSTEM_MASTER.FILE_UPLOAD_ID]: false,
              // [API_SYSTEM_MASTER.MODE_CD]: false,
              // [API_SYSTEM_MASTER.DATA_TYPE_CD]: false,
              // [API_SYSTEM_MASTER.API_ID]: false,
            },
          }}
        />
      )}
      <SubmitBar
        setOnSearch={setOnSearch}
        mode={mode}
        rows={rows}
        setMode={setMode}
        setRows={setRows}
        setRowModesModel={setRowModesModel}
        setMsgError={setMsgError}
        rowModesModel={rowModesModel}
        rowSelectionModel={rowSelectionModel}
        setRowSelectionModel={setRowSelectionModel}
        functionId={functionId}
      />
    </Fragment>
  );
}
