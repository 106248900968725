import { ModeAction } from "state/enum";
import { COLUMN_SHIPPING_INFO } from "../constants/table";
import { EditableTextField, InputButton } from "components/UI";
import { BUTTON_VALUE } from "shared/constants";
import { Box } from "@mui/material";
import { isEmpty } from "lodash";
import { API_INVOICE_SHIPPING_INFO } from "shared/constants/api-name/invoice/shipmentInfoMain";

export default function useColContainer({ rows, mode, handleOpenRPackageSubScreen, functionId }) {
  const handleCellClassName = ({ row }) => {
    if (row?.status === "N") {
      return "theme-cell-row-stats-incomplete";
    }
    return;
  };
  return [
    // Shipping PLan
    // Col 1
    {
      field: API_INVOICE_SHIPPING_INFO.ROW_NUMBER,
      headerName: "",
      // headerName: COLUMN_SHIPPING_INFO.NO,
      headerAlign: "center",
      align: "right",
      width: 50,
      sortable: false,
      editable: false,
      cellClassName: handleCellClassName,
      renderCell: params => {
        if (params?.row?.rowNumber === rows.slice(-1)?.[0]?.rowNumber && ModeAction.ADD === mode) {
          return <div></div>;
        }
        return params.value;
      },
    },
    // Col 2
    {
      field: API_INVOICE_SHIPPING_INFO.PLAN_CONTAINER_NO,
      headerName: COLUMN_SHIPPING_INFO.CONTAINER_NO,
      headerAlign: "center",
      align: "left",
      minWidth: 150,
      flex: 0.1,
      sortable: false,
      editable: true,
      cellClassName: handleCellClassName,
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return (
            <EditableTextField
              {...params}
              required
              defaultValue=""
              maxLength={15}
              onUpperCase={true}
              autoFocus={true}
              regularExp={/^[\w]{0,40}$/}
            />
          );
        } else if (mode === ModeAction.EDIT) {
          return <EditableTextField {...params} value={params.value} required defaultValue="" maxLength={15} />;
        } else if (mode === ModeAction.COPY) {
          return params?.value;
        }
        return params?.value;
      },
    },
    // Col 3
    {
      field: API_INVOICE_SHIPPING_INFO.PLAN_RM_QTY,
      headerName: COLUMN_SHIPPING_INFO.R_MODULE_QTY,
      headerAlign: "center",
      align: "right",
      minWidth: 100,
      flex: 0.1,
      sortable: false,
      editable: true,
      cellClassName: handleCellClassName,
      renderCell: params => {
        if (params?.row?.planRModuleQty === "0" || params?.row?.planRModuleQty === 0) {
          return "";
        }
        return params?.value;
      },
      renderEditCell: params => {
        if (mode === ModeAction.COPY) {
          if (params?.row?.planRModuleQty === "0" || params?.row?.planRModuleQty === 0) {
            return "";
          }
          return params?.row?.actualRModuleQty;
        } else if (params?.formattedValue === "0" || params?.formattedValue === 0) {
          return "";
        }

        return params?.formattedValue;
      },
    },
    // Col 3
    {
      field: "planRBoxDunQty",
      headerName: COLUMN_SHIPPING_INFO.R_BOX_Dun_QTY,
      headerAlign: "center",
      align: "right",
      minWidth: 100,
      flex: 0.1,
      sortable: false,
      editable: true,
      cellClassName: handleCellClassName,
      renderCell: params => {
        if (params?.row?.planRBoxDunQty === "0" || params?.row?.planRBoxDunQty === 0) {
          return "";
        }
        return params?.value;
      },
      renderEditCell: params => {
        if (mode === ModeAction.COPY) {
          if (params?.row?.planRBoxDunQty === "0" || params?.row?.planRBoxDunQty === 0) {
            return "";
          }
          return params?.row?.planRBoxDunQty;
        } else if (params?.formattedValue === "0" || params?.formattedValue === 0) {
          return "";
        }
        return params?.formattedValue;
      },
    },
    // Col 4
    {
      field: "planRPkgDetail",
      headerName: COLUMN_SHIPPING_INFO.R_PACKAGE_DETAIL,
      headerAlign: "center",
      align: "center",
      minWidth: 100,
      flex: 0.1,
      sortable: false,
      editable: true,
      cellClassName: handleCellClassName,
      renderCell: params => {
        return (
          <Box sx={{ display: "flex", justifyContent: "center", ml: 1 }}>
            <InputButton
              minWidth="70px"
              value={BUTTON_VALUE.DETAIL}
              disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode) || isEmpty(params?.row?.planContainerNo)}
              onClick={() => handleOpenRPackageSubScreen(params.row, params.value)}
              name={`${functionId}RPackageDetail`}
            />
          </Box>
        );
      },
      renderEditCell: params => {
        if ([ModeAction.ADD, ModeAction.EDIT, ModeAction.COPY].includes(mode)) {
          return (
            <Box sx={{ display: "flex", justifyContent: "center", ml: 1 }}>
              <InputButton
                minWidth="70px"
                value={BUTTON_VALUE.DETAIL}
                onClick={() => handleOpenRPackageSubScreen(params.row, params.value)}
                name={`${functionId}RPackageDetail`}
              />
            </Box>
          );
        }
      },
    },
    // Actual Vanning
    // Col 5
    {
      field: "actualContainerNo",
      headerName: COLUMN_SHIPPING_INFO.CONTAINER_NO,
      headerAlign: "center",
      align: "left",
      minWidth: 150,
      flex: 0.1,
      sortable: false,
      editable: false,
      cellClassName: handleCellClassName,
    },
    // Col 6
    {
      field: "actualRModuleQty",
      headerName: COLUMN_SHIPPING_INFO.R_MODULE_QTY,
      headerAlign: "center",
      align: "right",
      minWidth: 100,
      flex: 0.1,
      sortable: false,
      editable: false,
      cellClassName: handleCellClassName,
      renderCell: params => {
        if (params?.row?.actualRModuleQty === "0" || params?.row?.actualRModuleQty === 0) {
          return "";
        }
        return params?.row?.actualRModuleQty;
      },
    },
    // Col 7
    {
      field: "actualRBoxDunQty",
      headerName: COLUMN_SHIPPING_INFO.R_BOX_Dun_QTY,
      headerAlign: "center",
      align: "right",
      minWidth: 100,
      flex: 0.1,
      sortable: false,
      editable: false,
      cellClassName: handleCellClassName,
      renderCell: params => {
        if (params?.row?.actualRBoxDunQty === "0" || params?.row?.actualRBoxDunQty === 0) {
          return "";
        }
        return params?.row?.actualRBoxDunQty;
      },
    },
    // Col 8
    {
      field: "actualRPkgDetail",
      headerName: COLUMN_SHIPPING_INFO.R_PACKAGE_DETAIL,
      headerAlign: "center",
      align: "center",
      minWidth: 100,
      flex: 0.1,
      sortable: false,
      editable: true,
      cellClassName: handleCellClassName,
      renderCell: params => {
        return (
          <Box sx={{ display: "flex", justifyContent: "center", ml: 1 }}>
            <InputButton
              minWidth="70px"
              value={BUTTON_VALUE.DETAIL}
              disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode) || isEmpty(params?.row?.actualContainerNo)}
              onClick={() => handleOpenRPackageSubScreen(params.row, params.value)}
            />
          </Box>
        );
      },
      renderEditCell: params => {
        if ([ModeAction.ADD].includes(mode)) {
          return (
            <Box sx={{ display: "flex", justifyContent: "center", ml: 1 }}>
              <InputButton
                minWidth="70px"
                value={BUTTON_VALUE.DETAIL}
                onClick={() => handleOpenRPackageSubScreen(params.row, params.value)}
                disabled
              />
            </Box>
          );
        } else if ([ModeAction.EDIT].includes(mode)) {
          return (
            <Box sx={{ display: "flex", justifyContent: "center", ml: 1 }}>
              <InputButton
                minWidth="70px"
                value={BUTTON_VALUE.DETAIL}
                onClick={() => handleOpenRPackageSubScreen(params.row, params.value)}
                disabled={params.row.status === "N"}
              />
            </Box>
          );
        } else if (mode === ModeAction.COPY) {
          return (
            <Box sx={{ display: "flex", justifyContent: "center", ml: 1 }}>
              <InputButton
                minWidth="70px"
                value={BUTTON_VALUE.DETAIL}
                onClick={() => handleOpenRPackageSubScreen(params.row, params.value)}
              />
            </Box>
          );
        }
      },
    },
    // Col 9
    {
      field: "actualPackingMonth",
      headerName: COLUMN_SHIPPING_INFO.PACKING_MONTH,
      headerAlign: "center",
      align: "left",
      minWidth: 100,
      flex: 0.1,
      sortable: false,
      editable: false,
      cellClassName: handleCellClassName,
    },
    // Col 10
    {
      field: "actualVanningPLant",
      headerName: COLUMN_SHIPPING_INFO.VANNING_PLANT,
      headerAlign: "center",
      align: "left",
      minWidth: 100,
      flex: 0.1,
      sortable: false,
      editable: false,
      cellClassName: handleCellClassName,
    },
    // Col 11
    {
      field: "actualOrderType",
      headerName: COLUMN_SHIPPING_INFO.ORDER_TYPE,
      headerAlign: "center",
      align: "left",
      minWidth: 100,
      flex: 0.1,
      sortable: false,
      editable: false,
      cellClassName: handleCellClassName,
    },
    // Col 11
    {
      field: "status",
      headerName: "",
      // headerName: COLUMN_SHIPPING_INFO.STATUS,
      headerAlign: "center",
      align: "left",
      minWidth: 100,
      flex: 0.1,
      sortable: false,
      editable: false,
      cellClassName: handleCellClassName,
      renderCell: params => {
        if (params.value === "N") {
          return "Incomplete";
        } else if (params.value === "Y") {
          return "Complete";
        } else return <></>;
      },
    },
    {
      field: "shippingInfoDContId",
      sortable: false,
      editable: false,
    },
    {
      field: "vanningInfoHId",
      sortable: false,
      editable: false,
    },
    {
      field: "renbanNo",
      sortable: false,
      editable: false,
    },
  ];
}
