/* eslint-disable react-hooks/rules-of-hooks */
import { Box, Grid } from "@mui/material";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  InputDropDown,
  InputEditTableDropDown,
  AlertMessage,
  InputText,
  InputButton,
  InputEditTableText,
  InputEditTableCheckbox,
} from "components/UI/index";
import { MessageType, ModeAction } from "state/enum";
import { HeaderContentBar } from "components/Layout";
import ActionBar from "pages/Partials/ActionBar";
import { apiClient, findObject, message, messageTypeDisplay, responseDownloadFileErrors, responseErrors } from "utils";
import SubmitBar from "pages/Partials/SubmitBar";
import DataTable from "pages/DataTable";
import {
  useCompanyPlantInTable,
  useGetCountry,
  useGetCountryInTable,
  useGetSubPlant,
  useGetSubPlantInTable,
  useInOutStatus,
  useRoutePointCategory,
  useSearchRoutePoint,
  useRoutePathDownloadExcelMutation,
} from "service/routepoint";
import { ModalPossibleStatus } from "components/RoutePoint";
import { useConfirmDialogContext } from "context/confirmDialogContext";
import { useGridApiContext } from "@mui/x-data-grid";
import { GridRowModes } from "@mui/x-data-grid";
import { userProfile } from "constant";
import { validationRequestErrors, validationSearchForm } from "utils/validation";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";

const RoutePointScreen = () => {
  const userInfo = useSelector(state => state.auth.user);
  const confirmDialogCtx = useConfirmDialogContext();

  useEffect(() => {
    document.getElementById("select_countryCd").focus();
  }, []);

  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const [routePointCategoryInTable, setRoutePointCategoryInTable] = useState("");
  const [countryCdInTable, setCountryCdInTable] = useState("");

  const [mode, setMode] = useState("view");

  const [searchForm, setSearchForm] = useState({
    countryCd: "",
    routePointCd: "",
    routePointName: "",
    extensionCd: "",
  });

  // 1. pagination
  const [pagination, setPagination] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const handleChangePagination = async (event, value) => {
    setPageNumber(value);

    // TODO: Action Search
    const searchData = await searchDataAsync({
      dataOwner: userProfile.dataOwner,
      countryCd: searchForm.countryCd,
      routePointCd: searchForm.routePointCd,
      routePointName: searchForm.routePointName,
      extensionCd: searchForm.extensionCd,
      pageNumber: value,
      rowsPerPage: 10,
    });

    const data =
      searchData?.result?.data?.map((item, index) => ({
        rowNumber: index + 1,
        ...item,
      })) ?? [];

    setPagination(searchData?.result?.pagination ?? {});
    setPageNumber(searchData?.result?.pagination?.pageNumber);
    setRows(data);
  };

  const [onSearch, setOnSearch] = useState(false);

  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);

  // 2. api
  const downloadExcel = useRoutePathDownloadExcelMutation();
  const { data: countryData } = useGetCountry({
    dataOwner: userProfile.dataOwner,
  });

  const { data: routePointCategoryData } = useRoutePointCategory({
    dataOwner: userProfile.dataOwner,
  });

  const cdOrder = { N: 1, P: 2, C: 3, D: 4 };
  const { data: subPlantData } = useGetSubPlant({
    dataOwner: userProfile.dataOwner,
  });
  subPlantData?.sort((a, b) => cdOrder[a.cd] - cdOrder[b.cd]);

  const { data: subPlantInTableData } = useGetSubPlantInTable({
    category: userProfile.dataOwner,
    dataOwner: userProfile.dataOwner,
  });

  const { data: countryDataInTable } = useGetCountryInTable({
    dataOwner: userProfile.dataOwner,
  });

  const { data: companyPlantData } = useCompanyPlantInTable({
    dataOwner: userProfile.dataOwner,
    cd: countryCdInTable,
  });

  const { data: inOutStatusData } = useInOutStatus();

  const { mutateAsync: searchDataAsync } = useSearchRoutePoint({});

  const handleCopy = () => {
    setMode(ModeAction.ADD);
    setMsgError([]);
    setMsgAlert([]);
    setOnSearch(true);

    const rowCopy = rows.find(v => v.rowNumber === rowSelectionModel[0]);

    setRows(oldRows => [
      ...oldRows,
      {
        ...rowCopy,
        rowNumber: rows.length + 1,
        companyPlant: findObject({
          data: companyPlantData,
          value: rowCopy.companyPlant,
          property: "companyPlant",
          field: "plantId",
        }),
        isNew: true,
      },
    ]);

    setRowModesModel(oldModel => ({
      ...oldModel,
      [rows.length + 1]: { mode: GridRowModes.Edit },
    }));

    setRowSelectionModel([rows.length + 1]);
  };

  const [companyPlant, setCompanyPlant] = useState("");

  // 4. columns
  const columns = useMemo(
    () => [
      {
        field: "rowNumber",
        sortable: false,
        headerName: "No",
        align: "right",
        width: 50,
        editable: false,
        renderCell: params => {
          if (params.row.rowNumber === rows.slice(-1)?.[0].rowNumber && ModeAction.ADD === mode) {
            return <div></div>;
          }
          // return params.value;
          return params.value;
        },
      },
      {
        field: "routePointCategory",
        sortable: false,
        headerName: "Route Point Category",
        headerAlign: "center",
        minWidth: 120,
        editable: ModeAction.ADD === mode,
        rule: [
          {
            type: MessageType.EMPTY,
            key: ["Route Point Category"],
          },
        ],
        renderEditCell: params => {
          if (params.value !== "") {
            setRoutePointCategoryInTable(params.value);
          }

          if (params.value == "") {
            setRoutePointCategoryInTable("");
          }

          // on change clear data dropdown
          if (routePointCategoryInTable !== params.value) {
            const apiRef = useGridApiContext();
            apiRef.current.setEditCellValue({
              id: params.id,
              field: "countryCd",
              value: "",
            });
            apiRef.current.setEditCellValue({
              id: params.id,
              field: "companyPlant",
              value: "",
            });
            apiRef.current.setEditCellValue({
              id: params.id,
              field: "extensionCd",
              value: "",
            });
            apiRef.current.setEditCellValue({
              id: params.id,
              field: "routePointName",
              value: "",
            });
          }

          return (
            <InputEditTableDropDown
              autoFocus
              {...params}
              memu={routePointCategoryData?.map(val => ({
                key: val.cd,
                value: val.value,
              }))}
              placeholder="<Select>"
              required
              clearValueWithoutChange={["countryCd", "companyPlant", "extensionCd"]}
            />
          );
        },
        renderCell: params => {
          const name =
            params.value &&
            findObject({
              data: routePointCategoryData,
              value: params.value,
              property: "cd",
              field: "value",
            });

          return <p>{name}</p>;
        },
        flex: 2,
      },
      {
        field: "countryCd",
        sortable: false,
        headerName: "Country",
        headerAlign: "center",
        minWidth: 120,
        editable: ModeAction.ADD === mode,
        rule: [
          {
            type: routePointCategoryInTable === "C" ? MessageType.EMPTY : "",
            key: ["Country"],
          },
        ],
        renderEditCell: params => {
          if (params.value !== "") {
            setCountryCdInTable(params.value);
          }

          if (routePointCategoryInTable === "C") {
            return (
              <InputEditTableDropDown
                {...params}
                memu={countryData?.map(val => ({
                  key: val.cd,
                  value: val.value,
                }))}
                placeholder="<Select>"
                required
              />
            );
          } else if (routePointCategoryInTable === "O") {
            return (
              <InputEditTableDropDown
                {...params}
                memu={countryData?.map(val => ({
                  key: val.cd,
                  value: val.value,
                }))}
                placeholder="<Select>"
              />
            );
          }
        },
        renderCell: params => {
          const name =
            params.value &&
            findObject({
              data: countryData,
              value: params.value,
              property: "cd",
              field: "value",
            });
          return <p>{name}</p>;
        },
        flex: 2,
      },
      {
        field: "companyPlant",
        sortable: false,
        headerName: "Company/Plant",
        headerAlign: "center",
        minWidth: 120,
        editable: ModeAction.ADD === mode,
        rule: [
          {
            type: routePointCategoryInTable === "C" ? MessageType.EMPTY : "",
            key: ["Company/Plant"],
          },
        ],
        renderEditCell: params => {
          if (routePointCategoryInTable === "C") {
            const apiRef = useGridApiContext();
            if (companyPlant !== params.value) {
              setCompanyPlant(params.value);
              apiRef.current.setEditCellValue({
                id: params.id,
                field: "routePointName",
                value:
                  findObject({
                    data: companyPlantData,
                    value: params.value,
                    property: "plantId",
                    field: "impExpCd",
                  }) ?? "",
              });
            }

            return (
              <InputEditTableDropDown
                {...params}
                memu={companyPlantData?.map(val => ({
                  key: val.plantId,
                  value: val.companyPlant,
                }))}
                required
                placeholder="<Select>"
                fullWidth
              />
            );
          } else if (routePointCategoryInTable === "O") {
            return "";
          }
        },
        renderCell: params => {
          return params.value;
        },
        flex: 2,
      },
      {
        field: "extensionCd",
        sortable: false,
        headerName: "Sub Plant",
        headerAlign: "center",
        minWidth: 125,
        editable: ModeAction.ADD === mode,
        rule: [
          {
            type: MessageType.EMPTY,
            key: ["Sub Plant"],
          },
        ],
        renderEditCell: params => {
          return (
            <InputEditTableDropDown
              {...params}
              memu={subPlantInTableData?.map(val => ({
                key: val.cd,
                value: val.value,
              }))}
              required
              placeholder="<Select>"
            />
          );
        },
        renderCell: params => {
          const name =
            params.value &&
            findObject({
              data: subPlantData,
              value: params.value,
              property: "cd",
              field: "value",
            });

          return <p>{name}</p>;
        },
        flex: 2,
      },
      {
        field: "routePointName",
        sortable: false,
        align: "left",
        headerName: "Route Point Name",
        minWidth: 150,
        editable: ModeAction.ADD === mode,
        headerAlign: "center",
        rule: [
          {
            type: MessageType.EMPTY,
            key: ["Route Point Name"],
          },
        ],
        renderEditCell: params => {
          return <InputEditTableText {...params} required maxLength={30} regularExp={/^[a-zA-Z0-9_*-/\\ /s]*$/} />;
        },
        renderCell: params => params.value,
        flex: 2,
      },
      {
        field: "possibleStatusId",
        rule: [
          {
            type: MessageType.MUST_BE_SELECTED,
            key: ["Possible Status"],
          },
        ],
      },
      {
        field: "possibleStatus",
        sortable: false,
        headerName: "Possible Status",
        minWidth: 125,
        editable: true,
        headerAlign: "center",
        align: "center",
        renderEditCell: params => {
          const findRow = rows.find(v => v.rowNumber === params.id).possibleStatusId;
          if (findRow?.length) {
            const nameRs = findRow?.map(val => val.routePointSts).join(" | ") || params.value;

            return (
              <div
                onClick={() => {
                  if (params.row.rowNumber === rowSelectionModel[0]) {
                    handleModalPossible(params.id);
                  }
                }}
                style={{
                  cursor: "pointer",
                  width: "100%",
                  textWrap: "wrap",
                  textAlign: "center",
                  color: params.row.rowNumber === rowSelectionModel[0] ? "blue" : "black",
                }}>
                {nameRs}
              </div>
            );
          }
          return (
            <InputButton
              value={"Select"}
              color="info"
              size="small"
              onClick={() => handleModalPossible(params.id)}
              name={"WDN91030Add"}
            />
          );
        },
        renderCell: params => {
          const findRow = rows.find(v => v.rowNumber === params.id).possibleStatusId;

          if (ModeAction.VIEW === mode) {
            if (findRow?.length) {
              const nameRs = findRow?.map(val => val.routePointSts).join(" | ") || params.value;

              return (
                <div
                  onClick={() => {
                    handleModalPossible(params.id);
                  }}
                  style={{
                    cursor: "pointer",
                    color: "blue",
                    width: "100%",
                    textWrap: "wrap",
                    textAlign: "center",
                  }}>
                  {nameRs}
                </div>
              );
            }
            return (
              <InputButton value={"Select"} color="info" size="small" onClick={() => handleModalPossible(params.id)} />
            );
          } else {
            if (findRow?.length) {
              const nameRs = findRow?.map(val => val.routePointSts).join(" | ") || params.value;

              return (
                <div
                  onClick={() => {
                    if (params.row.rowNumber === rowSelectionModel[0]) {
                      handleModalPossible(params.id);
                    }
                  }}
                  style={{
                    cursor: "pointer",
                    width: "100%",
                    textWrap: "wrap",
                    textAlign: "center",
                    color: params.row.rowNumber === rowSelectionModel[0] ? "blue" : "black",
                  }}>
                  {nameRs}
                </div>
              );
            }
            return (
              <InputButton value={"Select"} color="info" size="small" onClick={() => handleModalPossible(params.id)} />
            );
          }
        },
        flex: 2,
      },
      {
        field: "trackSts",
        sortable: false,
        headerName: "IN/OUT Status",
        headerAlign: "center",
        minWidth: 120,
        editable: true,
        rule: [
          {
            type: MessageType.EMPTY,
            key: ["IN/OUT Status"],
          },
        ],
        renderEditCell: params => {
          return (
            <InputEditTableDropDown
              {...params}
              autoFocus={mode === ModeAction.EDIT}
              memu={inOutStatusData?.map(val => ({
                key: val.cd,
                value: val.value,
              }))}
              required
              placeholder="<Select>"
            />
          );
        },
        renderCell: params => {
          const name =
            params.value &&
            findObject({
              data: inOutStatusData,
              value: params.value,
              property: "cd",
              field: "value",
            });

          return <p>{name}</p>;
        },
        flex: 2,
      },
      {
        field: "enableFlag",
        sortable: false,
        headerName: "Enable Flag",
        headerAlign: "center",
        editable: true,
        align: "center",
        minWidth: 100,
        renderEditCell: params => {
          if (ModeAction.ADD === mode) {
            return <InputEditTableCheckbox {...params} checked={true} color="default" />;
          } else {
            return <InputEditTableCheckbox {...params} color="default" checked={params.value === "Y"} />;
          }
        },
        renderCell: params => {
          return <InputEditTableCheckbox {...params} checked={params.value === "Y"} color="secondary" disabled />;
        },
        flex: 1,
      },
    ],
    [rows, companyPlantData, routePointCategoryInTable, mode, companyPlant]
  );

  // get search
  const getSearch = async (pageNumber = 1) => {
    setOnSearch(true);

    // TODO: Action Search
    const searchData = await searchDataAsync({
      dataOwner: userProfile.dataOwner,
      countryCd: searchForm.countryCd,
      routePointCd: searchForm.routePointCd,
      routePointName: searchForm.routePointName,
      extensionCd: searchForm.extensionCd,
      pageNumber: pageNumber,
      rowsPerPage: 10,
    });

    const data =
      searchData?.result?.data?.map((item, index) => ({
        rowNumber: index + 1,
        ...item,
      })) ?? [];

    return { searchData, data };
  };

  // modal possible
  const [selectedPossible, setSelectedPossible] = useState([]);
  const [rowNumberPossible, setRowNumberPossible] = useState(null);
  const [isOpenModalPossible, setIsOpenModalPossible] = useState(false);
  const handleModalPossible = id => {
    setRowNumberPossible(id);
    setIsOpenModalPossible(true);
  };

  const handleSearch = async e => {
    try {
      e.preventDefault();
      setRows([]);
      setMsgError([]);
      setMsgAlert([]);
      setRowSelectionModel([]);
      setRowModesModel({});
      setMode(ModeAction.VIEW);

      const { searchData, data } = await getSearch();

      const { isSuccess, errors } = validationSearchForm({
        rule: [
          {
            data: data,
            type: MessageType.NOT_FOUND,
          },
        ],
      });

      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }

      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };

  // 9. handleClear
  const handleClear = () => {
    setMode(ModeAction.VIEW);
    setMsgError([]);
    setMsgAlert([]);
    setRowSelectionModel([]);
    setOnSearch(false);
    setSearchForm(prev => ({
      ...prev,
      countryCd: "",
      routePointCd: "",
      routePointName: "",
      extensionCd: "",
    }));
    setRows([]);
  };

  // 10. handleDownloadExcel
  const handleDownloadExcel = async () => {
    // validate
    setMsgError([]);
    setMsgAlert([]);
    try {
      const { searchData, data } = await getSearch();
      if (!data.length) {
        const msg = messageTypeDisplay(MessageType.NOT_FOUND);
        setMsgError(old => [...old, msg]);
        return;
      }
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
    try {
      const option = {
        dataOwner: userProfile.dataOwner,
        countryCd: searchForm.countryCd,
        routePointCd: searchForm.routePointCd,
        routePointName: searchForm.routePointName,
        extensionCd: searchForm.extensionCd,
        pageNumber: pageNumber,
        rowsPerPage: 10,
      };
      // const filename = `Route_Point_${getLocalDate().format("YYYYMMDDHHmm")}.xlsx`;

      await downloadExcel(option);
      return;
    } catch (error) {
      const errors = responseDownloadFileErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };

  const handleDeleteClick = async () => {
    setMsgError([]);
    setMsgAlert([]);
    const confirm = await confirmDialogCtx.success({ type: "confirmDelete" });
    if (!confirm) {
      return;
    }

    try {
      const selectedNo = rowSelectionModel[0];
      const id = rows.find(v => v.rowNumber === selectedNo)?.routePointId;

      await apiClient.delete(`/route-point/delete/${id}`, {
        data: { updateBy: userInfo.userName }, // TODO: get dataOwner from profile
      });

      const { searchData, data } = await getSearch(pageNumber);

      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);

      setMode(ModeAction.VIEW);
      setRowSelectionModel([]);

      const msg = messageTypeDisplay(MessageType.DELETED);
      setMsgAlert([msg]);
      window.scrollTo(0, 0);
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };

  const processRowUpdate = async newRow => {
    setMsgError([]);
    setMsgAlert([]);

    console.log("-newRow", newRow);

    const { isSuccess, errors } = validationRequestErrors({
      columns: columns,
      data: newRow,
    });

    if (!isSuccess) {
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }

    if (newRow.isNew) {
      try {
        await apiClient.post(`/route-point/create`, {
          dataOwner: userProfile.dataOwner,
          routePointCd: newRow.routePointCategory,
          routePointName: newRow.routePointName,
          countryCd: newRow.countryCd,
          companyId: findObject({
            data: companyPlantData,
            value: newRow.companyPlant,
            property: "plantId",
            field: "companyId",
          }),
          companyAbbr: findObject({
            data: companyPlantData,
            value: newRow.companyPlant,
            property: "plantId",
            field: "companyAbbr",
          }),
          plantId: newRow.companyPlant,
          impExpCd: findObject({
            data: companyPlantData,
            value: newRow.companyPlant,
            property: "plantId",
            field: "impExpCd",
          }),
          extensionCd: newRow.extensionCd,
          trackSts: newRow.trackSts,
          enableFlag: isEmpty(newRow.enableFlag) || newRow.enableFlag === "Y" ? "Y" : "N",
          refFileUploadId: "",
          createBy: userInfo.userName,
          possibleStatusId:
            newRow.possibleStatusId?.map(v => ({
              seqNo: v.seqNo,
              routePointSts: v.routePointSts,
              refFileUploadId: "",
            })) || [],
        });
        const msg = messageTypeDisplay(MessageType.ADDED);
        setMsgAlert([msg]);
        window.scrollTo(0, 0);
      } catch (error) {
        const errors = responseErrors(error);
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
    } else {
      try {
        await apiClient.patch(`/route-point/edit/${newRow.routePointId}`, {
          dataOwner: userProfile.dataOwner,
          trackSts: newRow.trackSts,
          enableFlag: isEmpty(newRow.enableFlag) || newRow.enableFlag === "Y" ? "Y" : "N",
          updateBy: userInfo.userName,
          possibleStatusId:
            newRow.possibleStatusId?.map(v => ({
              routePointStsId: v.routePointStsId,
              seqNo: v.seqNo,
              routePointSts: v.routePointSts,
              refFileUploadId: v.refFileUploadId,
            })) || [],
        });
        setMsgAlert([message({ type: "updated" })]);
        window.scrollTo(0, 0);
      } catch (error) {
        const errors = responseErrors(error);
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
    }
    if (rows.length === 1 && ModeAction.ADD === mode) {
      setMode(ModeAction.VIEW);
      setRowSelectionModel([]);
      setRows([]);
    } else {
      const { searchData, data } = await getSearch(pageNumber);

      setMode(ModeAction.VIEW);
      setRowSelectionModel([]);

      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
    }

    const updatedRow = { ...newRow, isNew: false };
    return updatedRow;
  };

  return (
    <Fragment>
      <Box sx={{ padding: "1rem" }}>
        <HeaderContentBar title="WDN91030 : Route Point Master Maintenance Screen" />
        <AlertMessage type={"warning"} message={msgError} />
        <AlertMessage type={"success"} message={msgAlert} />
        <Grid container sx={{ alignItems: "center" }}>
          <Grid item xs={5} style={{ display: "flex" }}>
            <strong style={{ margin: "auto 0px", width: "300px" }}>Country : </strong>
            <InputDropDown
              id="select_countryCd"
              value={searchForm.countryCd}
              onChange={e =>
                setSearchForm(old => ({
                  ...old,
                  countryCd: e.target.value,
                }))
              }
              memu={countryData?.map(val => ({
                key: val.cd,
                value: val.value,
              }))}
              placeholder="<All>"
            />
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={5} style={{ display: "flex" }}>
            <strong style={{ margin: "auto 0px", width: "300px" }}>Route Point Category : </strong>
            <InputDropDown
              id="select_routePointCd"
              value={searchForm.routePointCd}
              onChange={e =>
                setSearchForm(old => ({
                  ...old,
                  routePointCd: e.target.value,
                }))
              }
              memu={routePointCategoryData?.map(val => ({
                key: val.cd,
                value: val.value,
              }))}
              placeholder="<All>"
            />
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={5} style={{ display: "flex" }}>
            <strong style={{ margin: "auto 0px", width: "300px" }}>Route Point Name : </strong>
            <InputText
              id="input_routePointName"
              fullWidth={true}
              value={searchForm.routePointName}
              onChange={e =>
                setSearchForm(old => ({
                  ...old,
                  routePointName: e.target.value,
                }))
              }
              regularExp={/^[a-zA-Z0-9_*-/\\ /s]*$/}
              maxLength={30}
            />
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={5} style={{ display: "flex" }}>
            <strong style={{ margin: "auto 0px", width: "300px" }}>Sub Plant : </strong>
            <InputDropDown
              id="select_extensionCd"
              value={searchForm.extensionCd}
              onChange={e =>
                setSearchForm(old => ({
                  ...old,
                  extensionCd: e.target.value,
                }))
              }
              memu={subPlantData?.map(val => ({
                key: val.cd,
                value: val.value,
              }))}
              placeholder="<All>"
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </Box>
      <>
        <ActionBar
          mode={mode}
          rows={rows}
          columns={columns}
          setRows={setRows}
          setMode={setMode}
          setMsgError={setMsgError}
          setMsgAlert={setMsgAlert}
          setOnSearch={setOnSearch}
          setRowModesModel={setRowModesModel}
          setRowSelectionModel={setRowSelectionModel}
          rowSelectionModel={rowSelectionModel}
          rowModesModel={rowModesModel}
          handleSearch={handleSearch}
          handleClear={handleClear}
          handleDownloadExcel={handleDownloadExcel}
          handleDeleteClick={handleDeleteClick}
          handleCopy={handleCopy}
          firstField={"select_countryCd"}
          functionId={"WDN91030"}
        />
        <Box sx={{ padding: "1rem" }}>
          <Grid>
            {onSearch && (
              <DataTable
                mode={mode}
                onSearch={onSearch}
                rowSelectionModel={rowSelectionModel}
                setRowSelectionModel={setRowSelectionModel}
                setMode={setMode}
                rows={rows}
                rowModesModel={rowModesModel}
                columns={columns}
                setRowModesModel={setRowModesModel}
                processRowUpdate={processRowUpdate}
                pagination={pagination}
                pageNumber={pageNumber}
                handleChangePagination={handleChangePagination}
                columnGroupingModel={null}
                columnVisibilityModel={{
                  possibleStatusId: false,
                }}
              />
            )}
            <SubmitBar
              mode={mode}
              rows={rows}
              setMode={setMode}
              setRows={setRows}
              setRowModesModel={setRowModesModel}
              setMsgError={setMsgError}
              rowModesModel={rowModesModel}
              rowSelectionModel={rowSelectionModel}
              setRowSelectionModel={setRowSelectionModel}
              functionId={"WDN91030"}
              routePointCategoryInTable={routePointCategoryInTable}
              setRoutePointCategoryInTable={setRoutePointCategoryInTable}
            />
          </Grid>
        </Box>
      </>
      {/* ModalPossibleStatus */}
      <ModalPossibleStatus
        open={isOpenModalPossible}
        setOpen={setIsOpenModalPossible}
        setSelectedPossible={setSelectedPossible}
        rows={rows}
        setRows={setRows}
        rowNumber={rowNumberPossible}
        mode={mode}
        functionId={"WDN91030"}
      />
    </Fragment>
  );
};

export default RoutePointScreen;
