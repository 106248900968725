import React, { Fragment } from "react";
import { Grid } from "@mui/material";
import { DropDown, InputTextField } from "components/UI";
import { API_ON_DEMAND_BATCH, FIRST_PAGE } from "shared/constants";
import { ModeAction } from "state/enum";

export default function FilterBar(props) {
  const {
    form,
    setForm,
    setRows,
    setMode,
    setOnSearch,
    setRowModesModel,
    setRowSelectionModel,
    setPagination,
    setPageNumber,
    dataList: { projectList, batchList },
  } = props;
  const handleProjectCode = event => {
    setForm(prev => ({
      ...prev,
      [API_ON_DEMAND_BATCH.PROJECT_CODE]: event.target.value,
      [API_ON_DEMAND_BATCH.BATCH_NAME]: "",
      [API_ON_DEMAND_BATCH.BATCH_ID]: "",
    }));
    setOnSearch(false);
    setMode(ModeAction.VIEW);
    setRows([]);
    setRowModesModel({});
    setRowSelectionModel([]);
    setPagination({});
    setPageNumber(FIRST_PAGE);
    return;
  };

  const handleBatchName = event => {
    setForm(prev => ({
      ...prev,
      [API_ON_DEMAND_BATCH.BATCH_NAME]: event.target.value,
      [API_ON_DEMAND_BATCH.BATCH_ID]:
        event.target.value !== "" ? batchList.find(v => v.batchName === event.target.value).batchId : "",
    }));
    setOnSearch(false);
    setMode(ModeAction.VIEW);
    setRows([]);
    setRowModesModel({});
    setRowSelectionModel([]);
    setPagination({});
    setPageNumber(FIRST_PAGE);
    return;
  };
  return (
    <Grid container spacing={2} sx={{ alignItems: "center" }}>
      <Grid item xs={5} style={{ display: "flex" }}>
        <strong style={{ margin: "auto 0px", minWidth: "140px" }}>*Project Code:</strong>
        <DropDown
          id="input-project-code"
          value={form?.projectCode}
          onChange={handleProjectCode}
          menu={projectList?.map(val => ({
            key: val.projectCode,
            value: val.projectName,
          }))}
          disabled={false}
          required
          autoFocus={true}
          defaultValue=""
          placeholder="<Select>"
        />
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={5} style={{ display: "flex" }}>
        <strong style={{ margin: "auto 0px", minWidth: "140px" }}>Batch Name:</strong>
        <DropDown
          id="input-batch-name"
          value={form?.batchName}
          onChange={handleBatchName}
          menu={batchList?.map(val => ({
            key: val?.batchName,
            value: val?.batchName,
          }))}
          disabled={false}
          defaultValue=""
          placeholder="<All>"
        />
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={5} style={{ display: "flex" }}>
        <strong style={{ margin: "auto 0px", minWidth: "140px" }}>Batch ID:</strong>
        <InputTextField
          id="input-batch-id"
          fullWidth
          value={form?.batchId}
          onChange={e => {
            setForm(prev => ({
              ...prev,
              [API_ON_DEMAND_BATCH.BATCH_ID]: e.target.value,
            }));
          }}
          disabled={true}
        />
      </Grid>
      <Grid item xs={1}></Grid>
    </Grid>
  );
}
