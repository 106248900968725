import React, { Fragment, useEffect, useState } from "react";
// Component
import { Grid, Divider } from "@mui/material";
import { InputButton } from "components/UI";
import ActionTableBar from "./ActionTableBar";
// Hook
import { useButtonMode } from "pages/Forecast/ShipmentManagementScreen/hooks/useButtonMode";
// Service
import { useShipmentDownloadExcelMutation, useShipmentSearchMutation } from "shared/services/inventory-forecast";

// Constant & Type
import { BUTTON_VALUE, API_SHIPMENT, ROW_PER_PAGE, FIRST_PAGE } from "shared/constants";
import { MSG_TYPE, MessageType, ModeAction } from "state/enum";
// utils
import { responseErrors, messageDisplay, findObject, responseDownloadFileErrors } from "utils";
import { validationSearchForm } from "utils/validation";
import { formatRowsMapping } from "pages/Forecast/ShipmentManagementScreen/utils/formatData";
import { getLocalDate } from "utils/init-config-date";
import { isEmpty } from "lodash";
import { useLoadingContext } from "shared/contexts/LoadingContext";
import { waitAndDownloadExcel } from "shared/services/common-dowload/download-helper-service";
import { ResponseText } from "utils/5xxResponseText";

export default function MappingButtonBar(props) {
  const {
    mode,
    setMode,
    form,
    rows,
    setRows,
    setRPkg,
    onSearch,
    setOnSearch,
    refresh,
    setForm,
    columns,
    dataList: { exporterList, importerList, rPkgList },
    rowSelection: { selectedRowKeys, setSelectedRowKeys, selectedRows, setSelectedRows },
    pagination: { pageNumber, setPageNumber, pagination, setPagination },
    setMsg: { setMsgAlert, setMsgError },
    functionId,
  } = props;
  const { startLoading, stopLoading } = useLoadingContext();
  //Action bar
  const [addBtn, setAddBtn] = useState(false);
  const [editBtn, setEditBtn] = useState(false);
  const [cancelShipmentBtn, setCancelShipmentBtn] = useState(false);
  // button bar
  const [searchBtn, setSearchBtn] = useState(false);
  const [clearBtn, setClearBtn] = useState(false);
  const [downloadBtn, setDownloadBtn] = useState(false);
  const [allExporterBtn, setAllExporterBtn] = useState(false);
  //API
  const searchShipment = useShipmentSearchMutation();
  const exportShipment = useShipmentDownloadExcelMutation();

  const handleDownload = async btnName => {
    setMsgError([]);
    setMsgAlert([]);
    try {
      const body = {
        companyAbbr: form?.companyAbbr,
        mode: btnName !== "AM" ? "1" : "2",
        exporter: btnName !== "AM" ? form?.exporterId : "",
        importer: form?.importerId
          ? findObject({
              data: importerList,
              value: form?.importerId,
              property: "companyId",
              field: "companyAbbr",
            })
          : "",
        rPackageOwner: form.rPkgOwnerId
          ? findObject({
              data: rPkgList,
              value: form?.rPkgOwnerId,
              property: "rPkgOwnerCompId",
              field: "rPkgOwnerCompAbbr",
            })
          : "",
        forecastMonth: form.forecastMonth,
        status: form.shipmentStsId,
        etdFrom: form.etdFrom,
        etdTo: form.etdTo,
        etaFrom: form.etaFrom,
        etaTo: form.etaTo,
      };

      const rule = [
        btnName !== "AM" && {
          field: API_SHIPMENT.EXPORTER,
          type: MessageType.EMPTY,
          key: ["Exporter"],
        },
        {
          field: API_SHIPMENT.IMPORTER,
          type: MessageType.EMPTY,
          key: ["Importer"],
        },
        {
          field: API_SHIPMENT.FORECAST_MONTH,
          type: MessageType.MONTH_INVALID_50,
          key: ["Forecast Month", "MM/YYYY"],
        },
        {
          field: "rPackageOwner",
          type: MessageType.EMPTY,
          key: ["R-Package Owner"],
        },
      ];

      const { isSuccess, errors } = validationSearchForm({
        data: body,
        rule,
      });
      setMsgError(errors);
      let stopProcess = true;

      // check etd MSTD0019AERR: ETD From must be equal to or later than ETD To
      if (!isEmpty(body?.etdFrom) && !isEmpty(body?.etdTo)) {
        const dateFrom = getLocalDate(body?.etdFrom, "DD/MM/YYYY");
        const dateTo = getLocalDate(body?.etdTo, "DD/MM/YYYY");
        // MSTD0019AERR: ETD From must be equal to or later than ETD To
        if (dateTo.isBefore(dateFrom, "day")) {
          const msgEtd = messageDisplay({
            type: MSG_TYPE.ERROR,
            code: "MSTD0019AERR",
            msg: ["ETD (To)", "ETD (From)"],
          });
          setMsgError(oldMsg => [...oldMsg, msgEtd]);
          stopProcess = false;
        }
      }

      // check eta MSTD0019AERR: ETD From must be equal to or later than ETD To
      if (!isEmpty(body?.etaFrom) && !isEmpty(body?.etaTo)) {
        const dateFrom = getLocalDate(body?.etaFrom, "DD/MM/YYYY");
        const dateTo = getLocalDate(body?.etaTo, "DD/MM/YYYY");
        // MSTD0019AERR: ETA From must be equal to or later than ETA To
        if (dateTo.isBefore(dateFrom, "day")) {
          const msgEta = messageDisplay({
            type: MSG_TYPE.ERROR,
            code: "MSTD0019AERR",
            msg: ["ETA (To)", "ETA (From)"],
          });
          setMsgError(oldMsg => [...oldMsg, msgEta]);
          stopProcess = false;
        }
      }

      if (!isSuccess || !stopProcess) {
        window.scrollTo(0, 0);
        return;
      }
      startLoading();
      const response = await exportShipment(body);
      waitAndDownloadExcel(60, response.data, setMsgError, stopLoading);
      return;
    } catch (error) {
      if (error?.response?.status >= 500) {
        setMsgAlert([ResponseText[error?.response?.status]]);
      } else {
        const msgError = responseDownloadFileErrors(error);
        setMsgError(msgError);
      }
      stopLoading();
      window.scroll(0, 0);
      return;
    }
  };
  const handleSearch = async e => {
    try {
      e.preventDefault();
      setMsgAlert([]);
      setMsgError([]);

      // initial mode
      setMode(ModeAction.VIEW);
      setRows([]);
      setSelectedRowKeys([]);
      setSelectedRows({});
      setPageNumber(FIRST_PAGE);
      setPagination({});
      setOnSearch(false);
      setForm(old => ({
        ...old,
        [API_SHIPMENT.NCV_FILE_UPLOAD]: "",
        [API_SHIPMENT.NCV_FILE_UPLOAD]: "",
        [API_SHIPMENT.FILE_NAME]: {
          [API_SHIPMENT.ORG_ACTL_FILE_NAME]: "",
          [API_SHIPMENT.ORG_NCV_FILE_NAME]: "",
          [API_SHIPMENT.ORG_BL_FILE_NAME]: "",
        },
        [API_SHIPMENT.FILE]: {
          [API_SHIPMENT.ORG_ACTL_FILE_NAME]: {},
          [API_SHIPMENT.ORG_NCV_FILE_NAME]: {},
          [API_SHIPMENT.ORG_BL_FILE_NAME]: {},
        },
      }));
      const bodySearch = {
        [API_SHIPMENT.DATA_OWNER]: form?.dataOwner,
        [API_SHIPMENT.COMPANY]: form?.companyAbbr,
        [API_SHIPMENT.COMPANY_ABBR]: form?.companyAbbr,
        [API_SHIPMENT.OPERATION]: form?.operationId,
        [API_SHIPMENT.EXPORTER]: form?.exporterId,
        // [API_SHIPMENT.EXPORTER]: form?.exporterId
        //   ? findObject({ data: exporterList, value: form?.exporterId, property: "companyAbbr", field: "impExpCd" })
        //   : "",
        [API_SHIPMENT.IMPORTER]: form?.importerId
          ? findObject({
              data: importerList,
              value: form?.importerId,
              property: "companyId",
              field: "companyAbbr",
            })
          : "",
        [API_SHIPMENT.R_RKG_OWNER]: form.rPkgOwnerId
          ? findObject({
              data: rPkgList,
              value: form?.rPkgOwnerId,
              property: "rPkgOwnerCompId",
              field: "rPkgOwnerCompAbbr",
            })
          : "",
        [API_SHIPMENT.FORECAST_MONTH]: form.forecastMonth,
        [API_SHIPMENT.SHIPMENT_STATUS]: form.shipmentStsId,
        [API_SHIPMENT.ETD_FORM]: form.etdFrom,
        [API_SHIPMENT.ETD_TO]: form.etdTo,
        [API_SHIPMENT.ETA_FORM]: form.etaFrom,
        [API_SHIPMENT.ETA_TO]: form.etaTo,
        pageNumber: FIRST_PAGE,
        rowsPerPage: ROW_PER_PAGE,
      };

      const { isSuccess, errors } = validationSearchForm({
        data: bodySearch,
        rule: [
          {
            field: API_SHIPMENT.EXPORTER,
            type: MessageType.EMPTY,
            key: ["Exporter"],
          },
          {
            field: API_SHIPMENT.IMPORTER,
            type: MessageType.EMPTY,
            key: ["Importer"],
          },
          {
            field: API_SHIPMENT.FORECAST_MONTH,
            type: MessageType.MONTH_INVALID_50,
            key: ["Forecast Month", "MM/YYYY"],
          },
          {
            field: API_SHIPMENT.R_RKG_OWNER,
            type: MessageType.EMPTY,
            key: ["R-Package Owner"],
          },
        ],
      });
      setMsgError(errors);
      let stopProcess = true;

      // check etd MSTD0019AERR: ETD From must be equal to or later than ETD To
      if (!isEmpty(bodySearch?.etdFrom) && !isEmpty(bodySearch?.etdTo)) {
        const dateFrom = getLocalDate(bodySearch?.etdFrom, "DD/MM/YYYY");
        const dateTo = getLocalDate(bodySearch?.etdTo, "DD/MM/YYYY");
        if (dateTo.isBefore(dateFrom, "day")) {
          const msgEtd = messageDisplay({
            type: MSG_TYPE.ERROR,
            code: "MSTD0019AERR",
            msg: ["ETD (To)", "ETD (From)"],
          });
          setMsgError(oldMsg => [...oldMsg, msgEtd]);
          stopProcess = false;
        }
      }

      // check eta MSTD0019AERR: ETD From must be equal to or later than ETD To
      if (!isEmpty(bodySearch?.etaFrom) && !isEmpty(bodySearch?.etaTo)) {
        const dateFrom = getLocalDate(bodySearch?.etaFrom, "DD/MM/YYYY");
        const dateTo = getLocalDate(bodySearch?.etaTo, "DD/MM/YYYY");
        if (dateTo.isBefore(dateFrom, "day")) {
          const msgEta = messageDisplay({
            type: MSG_TYPE.ERROR,
            code: "MSTD0019AERR",
            msg: ["ETA (To)", "ETA (From)"],
          });
          setMsgError(oldMsg => [...oldMsg, msgEta]);
          stopProcess = false;
        }
      }

      if (!isSuccess || !stopProcess) {
        window.scrollTo(0, 0);
        return;
      }
      startLoading();
      const searchData = await searchShipment(bodySearch);
      stopLoading();
      // ? CHECK DATA NOT FOUND
      if (!searchData?.result?.data?.length) {
        setRows([]);
        setRPkg([]);
        setPagination({});
        setPageNumber(FIRST_PAGE);
        setOnSearch(false);
        const msg = messageDisplay({ type: MSG_TYPE.ERROR, code: "MSTD0059AERR", msg: [""] });
        setMsgError([msg]);
        return false;
      }

      const { data, rPkg } = formatRowsMapping(searchData?.result?.data);
      setRows(data);
      setRPkg(rPkg);
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setOnSearch(true);
      setForm(old => ({
        ...old,
        [API_SHIPMENT.NCV_FILE_UPLOAD]: "",
        [API_SHIPMENT.NCV_FILE_UPLOAD]: "",
        [API_SHIPMENT.FILE_NAME]: {
          [API_SHIPMENT.ORG_ACTL_FILE_NAME]: "",
          [API_SHIPMENT.ORG_NCV_FILE_NAME]: "",
          [API_SHIPMENT.ORG_BL_FILE_NAME]: "",
        },
        [API_SHIPMENT.FILE]: {
          [API_SHIPMENT.ORG_ACTL_FILE_NAME]: {},
          [API_SHIPMENT.ORG_NCV_FILE_NAME]: {},
          [API_SHIPMENT.ORG_BL_FILE_NAME]: {},
        },
      }));
      return true;
    } catch (error) {
      stopLoading();
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };
  const handleClear = () => {
    setMsgAlert([]);
    setMsgError([]);
    setMode(ModeAction.VIEW);
    setRows([]);
    setOnSearch(false);
    setPageNumber(1);
    setPagination({});
    setSelectedRowKeys([]);
    setSelectedRows({});
    document.getElementById("select_exporter").focus();
    setForm(prev => ({
      ...prev,
      [API_SHIPMENT.EXPORTER_ID]: "",
      [API_SHIPMENT.IMPORTER_ID]: "CMP_1208_000001",
      [API_SHIPMENT.R_RKG_OWNER_ID]: "",
      [API_SHIPMENT.FORECAST_MONTH]: "",
      [API_SHIPMENT.SHIPMENT_STATUS_ID]: "",
      [API_SHIPMENT.ETD_FORM]: "",
      [API_SHIPMENT.ETD_TO]: "",
      [API_SHIPMENT.ETA_FORM]: "",
      [API_SHIPMENT.ETA_TO]: "",
      [API_SHIPMENT.NCV_FILE_UPLOAD]: "",
      [API_SHIPMENT.NCV_FILE_UPLOAD]: "",
      [API_SHIPMENT.FILE_NAME]: {
        [API_SHIPMENT.ORG_ACTL_FILE_NAME]: "",
        [API_SHIPMENT.ORG_NCV_FILE_NAME]: "",
        [API_SHIPMENT.ORG_BL_FILE_NAME]: "",
      },
      [API_SHIPMENT.FILE]: {
        [API_SHIPMENT.ORG_ACTL_FILE_NAME]: {},
        [API_SHIPMENT.ORG_NCV_FILE_NAME]: {},
        [API_SHIPMENT.ORG_BL_FILE_NAME]: {},
      },
    }));
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useButtonMode({
      mode,
      rows,
      setDownloadBtn,
      setAllExporterBtn,
      setSearchBtn,
      setClearBtn,
      setAddBtn,
      setEditBtn,
      setCancelShipmentBtn,
    });
  }, [mode, rows]);

  useEffect(() => {
    if (!rows?.length) {
      setAddBtn(true);
      setEditBtn(true);
      setCancelShipmentBtn(true);
    }
  }, [rows]);
  return (
    <Fragment>
      <Grid container spacing={2} sx={{ mb: 2, mt: 0 }}>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <InputButton
            id="btn_mapping_search"
            value={BUTTON_VALUE.SEARCH}
            disabled={searchBtn}
            onClick={handleSearch}
            name={`${functionId}Search`}
          />
          <InputButton
            id="btn_mapping_clear"
            value={BUTTON_VALUE.CLEAR}
            disabled={clearBtn}
            onClick={handleClear}
            name={`${functionId}Clear`}
          />
          <InputButton
            id="btn_mapping_download"
            value={BUTTON_VALUE.DOWNLOAD}
            disabled={downloadBtn}
            onClick={() => handleDownload(form?.operationId)}
            name={`${functionId}Download`}
            onKeyDown={e => {
              if (!onSearch && e.key === "Tab" && !form?.isAPMCuser) {
                e.preventDefault();
                document.getElementById("input-operation").focus();
              }
            }}
          />
          {form?.isAPMCuser && (
            <InputButton
              id="btn_mapping_all_export"
              value={BUTTON_VALUE.ALL_EXPORT}
              disabled={allExporterBtn}
              onClick={() => handleDownload("AM")}
              onKeyDown={e => {
                if (!onSearch && e.key === "Tab") {
                  e.preventDefault();
                  document.getElementById("input-operation").focus();
                }
              }}
              name={`${functionId}AllExporter`}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <ActionTableBar
            form={form}
            mode={mode}
            setMode={setMode}
            columns={columns}
            setForm={setForm}
            rows={rows}
            setRows={setRows}
            setOnSearch={setOnSearch}
            refresh={refresh}
            setMsg={{ setMsgError, setMsgAlert }}
            dataList={{ exporterList, importerList, rPkgList }}
            buttonState={{ addBtn, editBtn, cancelShipmentBtn }}
            pagination={{ pageNumber, setPageNumber, pagination, setPagination }}
            rowSelection={{ selectedRowKeys, setSelectedRowKeys, selectedRows, setSelectedRows }}
            functionId={functionId}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}
