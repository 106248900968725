import React from "react";
import { Box } from "@mui/material";
import { EditableDropDown, InputEditTableMask, InputEditableUploadFile, LinkTableCell } from "components/UI";
import { ModeAction } from "state/enum";
import { GENERATE_STATUS } from "pages/Forecast/ApmcForecastScreen/constants/apmc.type.js";
import { API_NAME_APMC } from "shared/constants";
import { APMC_COLUMNS } from "pages/Forecast/ApmcForecastScreen/constants/apmc.type.js";

export const useColumn = ({
  rows,
  mode,
  dataList: { modeList, dataTypeList, impExpList },
  handleUploadFile,
  handleLinkStatus,
  setDisable,
  disable,
}) => {
  return [
    // Col 1
    {
      field: API_NAME_APMC.ROW_NUMBER,
      headerName: APMC_COLUMNS.NO,
      headerAlign: "center",
      align: "right",
      flex: 0.1,
      minWidth: 50,
      maxWidth: 50,
      sortable: false,
      editable: false,
      renderCell: params => {
        if (params?.row?.rowNumber === rows.slice(-1)?.[0].rowNumber && ModeAction.ADD === mode) {
          return <div></div>;
        }
        return params.value;
      },
    },
    // Col 2
    {
      field: API_NAME_APMC.UPLOAD_IMP_EXP_CD,
      headerName: APMC_COLUMNS.IMPORT_EXPORT,
      headerAlign: "center",
      align: "center",
      flex: 0.1,
      minWidth: 100,
      sortable: false,
      editable: true,
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return (
            <EditableDropDown
              {...params}
              style={{ margin: "0px 15px", width: "100%" }}
              required
              defaultValue=""
              menu={impExpList?.map(val => ({
                key: val.impExpCd,
                value: val.impExpCd,
              }))}
              placeholder="<Select>"
              autoFocus={true}
            />
          );
        } else {
          return params.value;
        }
      },
    },
    // Col 3
    {
      field: API_NAME_APMC.FORECAST_MONTH,
      headerName: APMC_COLUMNS.FORECAST_MONTH,
      headerAlign: "center",
      align: "center",
      flex: 0.1,
      minWidth: 120,
      sortable: false,
      editable: true,
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return (
            <InputEditTableMask
              {...params}
              mask="mM/YYYY"
              formatChars={{
                Y: "[0-9]",
                m: "[0-1]",
                M: "[0-9]",
              }}
              required
              maskChar={null}
              alwaysShowMask={false}
            />
          );
        } else {
          return params.value;
        }
      },
    },
    // Col4
    {
      field: API_NAME_APMC.MODE,
      headerName: APMC_COLUMNS.MODE,
      headerAlign: "center",
      align: "left",
      flex: 0.1,
      minWidth: 100,
      sortable: false,
      editable: true,
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return (
            <EditableDropDown
              {...params}
              style={{ margin: "0px 15px", width: "100%" }}
              required
              defaultValue=""
              menu={modeList?.map(val => ({
                key: val.value,
                value: val.value,
              }))}
              placeholder="<Select>"
              disabled={disable}
            />
          );
        } else {
          return params.value;
        }
      },
    },
    // Col 5
    {
      field: API_NAME_APMC.DATA_TYPE,
      headerName: APMC_COLUMNS.DATA_TYPE,
      headerAlign: "center",
      align: "left",
      type: "select",
      flex: 0.1,
      minWidth: mode === ModeAction.ADD ? 220 : 160,
      sortable: false,
      editable: true,
      disableClickEventBubbling: true,
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return (
            <Box width="90%">
              <EditableDropDown
                {...params}
                style={{ margin: "0px 15px", width: "100%" }}
                required
                defaultValue=""
                onChange={e => {
                  if (["PT", "TR", "RC"].includes(e.target.value)) {
                    params.api.setEditCellValue({ id: params.row.rowNumber, field: API_NAME_APMC.MODE, value: "Firm" });
                    setDisable(true);
                    return;
                  }
                  setDisable(false);
                  return;
                }}
                menu={dataTypeList?.map(val => ({
                  key: val.cd,
                  value: val.value,
                }))}
                placeholder="<Select>"
              />
            </Box>
          );
        } else {
          return params.value;
        }
      },
    },
    // Col 6
    {
      field: API_NAME_APMC.ORG_FILE_NAME,
      headerName: APMC_COLUMNS.FILE_NAME,
      headerAlign: "center",
      align: "left",
      flex: 0.1,
      minWidth: 250,
      sortable: false,
      editable: true,
      renderCell: params => {
        return (
          <div
            style={{
              whiteSpace: "normal",
              wordBreak: "break-word",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100%",
            }}>
            {params.value}
          </div>
        );
      },
      renderEditCell: params => {
        if ([ModeAction.ADD, ModeAction.EDIT].includes(mode)) {
          return (
            <InputEditableUploadFile
              {...params}
              fileType=".xlsx"
              uploadProcess={handleUploadFile}
              required
              style={{ ml: "2px" }}
              maxLength={50}
            />
          );
        } else {
          return params.value;
        }
      },
    },
    // Col7
    {
      field: API_NAME_APMC.UPLOAD_STATUS,
      headerName: APMC_COLUMNS.UPLOAD_STATUS,
      headerAlign: "center",
      align: "left",
      flex: 0.1,
      minWidth: 100,
      sortable: false,
      editable: false,
      renderCell: params => {
        

        return (
          <LinkTableCell href="#" processScreen={handleLinkStatus} {...params} params={params}>
            {params?.value}
          </LinkTableCell>
        );
      },
    },
    // Col8
    {
      field: API_NAME_APMC.UPLOAD_DATE,
      headerName: APMC_COLUMNS.UPLOAD_DATE,
      headerAlign: "center",
      align: "center",
      flex: 0.1,
      minWidth: 120,
      sortable: false,
      editable: false,
    },
    // Col 9
    {
      field: API_NAME_APMC.UPLOAD_BY,
      headerName: APMC_COLUMNS.UPLOAD_BY,
      headerAlign: "center",
      align: "left",
      flex: 0.1,
      minWidth: 120,
      sortable: false,
      editable: false,
      renderCell: params => <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>{params.value}</div>,
    },
    // Col 10
    {
      field: API_NAME_APMC.USE_STATUS,
      headerName: APMC_COLUMNS.GENERATE_STATUS,
      headerAlign: "center",
      align: "left",
      flex: 0.1,
      minWidth: 120,
      sortable: false,
      editable: false,
      renderCell: params => {
        if ([GENERATE_STATUS.GENERATED, GENERATE_STATUS.ERROR].includes(params?.value)) {
          return (
            <LinkTableCell href="#" color="#1e88e5" processScreen={handleLinkStatus} {...params} params={params}>
              {params?.value}
            </LinkTableCell>
          );
        } else {
          return params?.value;
        }
      },
    },
    // Col 11
    {
      field: API_NAME_APMC.USE_DATE,
      headerName: APMC_COLUMNS.GENERATE_DATE,
      headerAlign: "center",
      align: "center",
      flex: 0.1,
      minWidth: 120,
      sortable: false,
      editable: false,
      renderCell: params => {
        if ([GENERATE_STATUS.GENERATED, GENERATE_STATUS.ERROR].includes(params?.row?.useSts)) {
          return params.value;
        } else {
          return "";
        }
      },
    },
    // Col 12
    {
      field: API_NAME_APMC.USE_BY,
      headerName: APMC_COLUMNS.GENERATE_BY,
      headerAlign: "center",
      align: "left",
      flex: 0.1,
      minWidth: 120,
      sortable: false,
      editable: false,
      renderCell: params => {
        if ([GENERATE_STATUS.GENERATED, GENERATE_STATUS.ERROR].includes(params?.row?.useSts)) {
          return params.value;
        } else {
          return "";
        }
      },
    },
    {
      field: API_NAME_APMC.FILE_UPLOAD_ID,
      editable: false,
    },
    {
      field: API_NAME_APMC.FILE_NAME,
      editable: false,
    },
    {
      field: API_NAME_APMC.DATA_OWNER,
      editable: false,
    },
    {
      field: API_NAME_APMC.UPDATE_DATE,
      editable: false,
    },
    {
      field: API_NAME_APMC.MODULE_ID,
      editable: false,
    },
    {
      field: API_NAME_APMC.FUNCTION_ID,
      editable: false,
    },
    {
      field: API_NAME_APMC.D_HODTCRE,
      editable: false,
    },
  ];
};
