import { Box, Grid, TextField } from "@mui/material";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  InputDropDown,
  AlertMessage,
  InputEditTableDropDown,
  EditableDatePicker,
  EditableDecimalTextField,
  InputEditTableCurrency,
} from "components/UI/index";
import { MessageType, ModeAction } from "state/enum";
import { HeaderContentBar } from "components/Layout";
import ActionBar from "pages/Partials/ActionBar";
import { findObject, message, messageTypeDisplay, responseDownloadFileErrors, responseErrors } from "utils";
import SubmitBar from "pages/Partials/SubmitBar";
import DataTable from "pages/DataTable";
import { columEditTable } from "utils/columEditTable";
import {
  useCreateSafetyStockandOverflow,
  useDeleteSafetyStockandOverflow,
  useGetMasterType,
  useGetMasterCountry,
  useGetRoutePointCategoryDropDown,
  useGetRoutePointCountryDropDown,
  useGetRoutePointNameDropDown,
  useGetRoutePointNameTableDropDown,
  useGetRpkgOwnerDropDown,
  useGetRpkgOwnerTableDropDown,
  useGetRpkgTypeDropDown,
  useGetRpkgTypeTableDropDown,
  useSearchSafetyStockandOverflow,
  useUpdateSafetyStockandOverflow,
  useSafetyStockDownloadExcelMutation,
} from "service/safetyStockandOverflow";
import { useConfirmDialogContext } from "context/confirmDialogContext";
import { userProfile } from "constant";
import { validationSearchForm } from "utils/validation";

import { GridRowModes } from "@mui/x-data-grid";
import { useSelector } from "react-redux";

const SafetyStockandOverflowScreen = () => {
  const userInfo = useSelector(state => state.auth.user);
  const confirmDialogCtx = useConfirmDialogContext();

  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [routePointNameTable, setRoutePointNameTable] = useState("");
  const [mode, setMode] = useState("view");

  const [searchForm, setSearchForm] = useState({
    dataOwner: userProfile.dataOwner,
    rPkgOwnerCompAbbr: "",
    masterTypeCd: "",
    // rPkgOwnerId: "",
    rPkgType: "",
    routePointCountryCd: "",
    routePointCategoryCd: "",
    routePointName: "",
  });

  // 1. pagination
  const [pagination, setPagination] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const handleChangePagination = async (event, value) => {
    setPageNumber(value);
    const searchData = await searchDataAsync({
      ...searchForm,
      pageNumber: value,
      rowsPerPage: 10,
    });

    const data =
      searchData?.result?.data?.map((item, index) => ({
        rowNumber: index + 1,
        ...item,
      })) ?? [];

    setPagination(searchData?.result?.pagination ?? {});
    setPageNumber(searchData?.result?.pagination?.pageNumber);

    setRows(data);
  };

  const [onSearch, setOnSearch] = useState(false);

  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);

  // 2. api
  const downloadExcel = useSafetyStockDownloadExcelMutation();
  const { data: typeMasterData } = useGetMasterType({
    dataOwner: userProfile.dataOwner,
    rPkgOwnerCompAbbr: userProfile.packageOwner,
  });

  const { data: typeMasterCountryData } = useGetMasterCountry();

  const { data: routePointCountryData } = useGetRoutePointCountryDropDown({
    cd: searchForm.masterTypeCd,
    dataOwner: userProfile.dataOwner,
    rPkgOwnerCompAbbr: userProfile.packageOwner,
  });

  const { data: routePointCategoryData } = useGetRoutePointCategoryDropDown({
    cd: searchForm.masterTypeCd,
    dataOwner: userProfile.dataOwner,
    rPkgOwnerCompAbbr: userProfile.packageOwner,
  });

  const { data: routePointNameData } = useGetRoutePointNameDropDown({
    cd: searchForm.masterTypeCd,
    dataOwner: userProfile.dataOwner,
    rPkgOwnerCompAbbr: userProfile.packageOwner,
  });

  const { data: rpkgOwnerData } = useGetRpkgOwnerDropDown({
    cd: searchForm.masterTypeCd,
    dataOwner: userProfile.dataOwner,
    rPkgOwnerCompAbbr: userProfile.packageOwner,
  });

  const { data: rpkgTyperData, refetch: refetchGetRpkgTypeDropDown } = useGetRpkgTypeDropDown({
    cd: searchForm.masterTypeCd,
    dataOwner: userProfile.dataOwner,
    rPkgOwnerCompAbbr: searchForm.rPkgOwnerCompAbbr,
  });

  const { data: routePointNameTableData } = useGetRoutePointNameTableDropDown({
    cd: routePointNameTable,
    dataOwner: userProfile.dataOwner,
  });

  const { data: rpkgOwnerTableData } = useGetRpkgOwnerTableDropDown({
    dataOwner: userProfile.dataOwner,
    rPkgOwnerCompAbbr: userProfile.packageOwner,
  });

  const { data: rpkgTypeTableTableData } = useGetRpkgTypeTableDropDown({
    dataOwner: userProfile.dataOwner,
    rPkgOwnerCompAbbr: userProfile.packageOwner,
  });

  const { mutateAsync: searchDataAsync } = useSearchSafetyStockandOverflow({});
  const { mutateAsync: createDataAsync } = useCreateSafetyStockandOverflow({
    type: searchForm.masterTypeCd === "S" ? "safety" : "overflow",
  });
  const { mutateAsync: editDataAsync } = useUpdateSafetyStockandOverflow({
    id:
      searchForm.masterTypeCd === "S"
        ? rows.find(val => val.rowNumber === rowSelectionModel[0])?.safrtyStkId
        : rows.find(val => val.rowNumber === rowSelectionModel[0])?.overflowStkId,
    type: searchForm.masterTypeCd === "S" ? "safety" : "overflow",
  });
  const { mutateAsync: deleteDataAsync } = useDeleteSafetyStockandOverflow({
    id:
      searchForm.masterTypeCd === "S"
        ? rows.find(val => val.rowNumber === rowSelectionModel[0])?.safrtyStkId
        : rows.find(val => val.rowNumber === rowSelectionModel[0])?.overflowStkId,
    type: searchForm.masterTypeCd === "S" ? "safety" : "overflow",
  });
  const onSpecificCheck = (event, digit = 2) => {
    const value = event.target.value;
    const key = event.key;
    const keyPass = [".", "Backspace", "ArrowLeft", "ArrowRight"];

    // Allow numbers (0-9) and '.' only
    if (!keyPass.includes(key) && !/^[\d]$/.test(key)) {
      if (event.preventDefault) {
        event.preventDefault(); // Prevent default behavior for non-numeric keys
      } else {
        event.returnValue = false; // For older browsers
      }
      return;
    }

    const decimalPos = value.indexOf(".");
    // Handle '.'
    if (key === ".") {
      // Block '.' if one already exists
      if (decimalPos >= 0) {
        if (event.preventDefault) {
          event.preventDefault();
        } else {
          event.returnValue = false;
        }
      }
    } else {
      // Limit digits before decimal
      if (decimalPos < 0 && /^[\d]$/.test(key)) {
        if (value.toString().length >= digit) {
          if (event.preventDefault) {
            event.preventDefault();
          } else {
            event.returnValue = false;
          }
        }
      } else if (!/^[0-9]\B.$/.test(key)) {
        event.returnValue = false;
      }
    }
    return value;
  };

  const columns = useMemo(
    () => [
      {
        field: "rowNumber",
        align: "right",
        sortable: false,
        headerName: "No",
        width: 50,
        editable: false,
        renderCell: params => {
          if (params.row.rowNumber === rows.slice(-1)?.[0].rowNumber && ModeAction.ADD === mode) {
            return <div></div>;
          }
          return params.value;
        },
      },
      {
        field: "routePointCountryCd",
        align: "left",
        headerAlign: "center",
        headerName: "Route Point Country",
        sortable: false,
        minWidth: 250,
        flex: 0.1,
        editable: mode === ModeAction.ADD,
        rule: [
          {
            type: MessageType.EMPTY,
            key: ["Route Point Country"],
          },
        ],
        renderEditCell: params => {
          if (params.value !== "") {
            setRoutePointNameTable(params.value);
          }

          return (
            <InputEditTableDropDown
              {...params}
              required={true}
              disabled={false}
              // {...inputOption}
              memu={typeMasterCountryData?.map(val => ({
                key: val.cd,
                value: val.value,
              }))}
              placeholder="<Select>"
              autoFocus={true}
            />
          );
        },
        renderCell: params => {
          const name = params.row.routePointCountry;
          return <p>{name}</p>;
        },
      },
      columEditTable({
        field: "routePointCategory",
        align: "left",
        headerName: "Route Point Category",
        width: 150,
        type: "text",
        onFocus: true,
      }),
      {
        field: "routePointId",
        align: "left",
        headerAlign: "center",
        headerName: "Route Point Name",
        sortable: false,
        minWidth: 200,
        flex: 0.1,
        editable: mode === ModeAction.ADD,
        rule: [
          {
            type: MessageType.EMPTY,
            key: ["Route Point Name"],
          },
        ],
        renderEditCell: params => {
          return (
            <InputEditTableDropDown
              {...params}
              required={true}
              disabled={false}
              memu={routePointNameTableData?.map(val => ({
                key: val.routePointId,
                value: val.routePoint,
              }))}
              placeholder="<Select>"
            />
          );
        },
        renderCell: params => {
          const name = params.row.routePointName;
          return <p>{name}</p>;
        },
      },
      {
        field: "rPkgOwnerCompAbbr",
        headerName: "R Package Owner",
        align: "left",
        headerAlign: "center",
        sortable: false,
        minWidth: 150,
        flex: 0.1,
        editable: mode === ModeAction.ADD,
        rule: [
          {
            type: MessageType.EMPTY,
            key: ["R-Package Owner"],
          },
        ],
        renderEditCell: params => {
          return (
            <InputEditTableDropDown
              {...params}
              required={true}
              disabled={false}
              memu={rpkgOwnerTableData?.map(val => ({
                key: val.rPkgOwnerCompId,
                value: val.rPkgOwnerCompAbbr,
              }))}
              placeholder="<Select>"
            />
          );
        },
        renderCell: params => {
          const name = params.value;
          return <p>{name}</p>;
        },
      },
      {
        field: "rPkgTypeId",
        headerName: "R-Package Type",
        align: "left",
        headerAlign: "center",
        sortable: false,
        minWidth: 150,
        flex: 0.1,
        editable: mode === ModeAction.ADD,
        rule: [
          {
            type: MessageType.EMPTY,
            key: ["R-Package Type"],
          },
        ],
        renderEditCell: params => {
          return (
            <InputEditTableDropDown
              {...params}
              required={true}
              disabled={false}
              memu={rpkgTypeTableTableData?.map(val => ({
                key: val.rPkgTypeId,
                value: val.rPkgType,
              }))}
              placeholder="<Select>"
            />
          );
        },
        renderCell: params => {
          const name = params.row.rPkgType;
          return <p>{name}</p>;
        },
      },
      {
        field: searchForm.masterTypeCd === "S" ? "saftyStkDay" : "overflowStkQty",
        headerName: searchForm.masterTypeCd === "S" ? "Safety Stock (days)" : "Overflow Stock (qty)",
        headerAlign: "center",
        align: "right",
        width: 150,
        sortable: false,
        editable: true,

        renderEditCell: params => {
          if (searchForm.masterTypeCd === "S") {
            return (
              <EditableDecimalTextField
                id="saftyStkDay"
                {...params}
                required={true}
                maxLength={5}
                type="text"
                regularExp={/^[\\\d]+\\.?[0-9]{0,2}$/}
                onKeyDown={onSpecificCheck}
                textAlign="end"
              />
              // <InputEditTableCurrency
              //   id="saftyStkDay"
              //   {...params}
              //   required
              //   maxLimit={100.0}
              //   decimalSeparator={"."}
              //   type="number"
              //   style={{ width: "100%", textAlign: "right" }}
              // />
            );
          }
          return (
            <InputEditTableCurrency
              id="overflowStkQty"
              {...params}
              required
              maxLimit={10000000}
              type="number"
              style={{ width: "100%", textAlign: "right" }}
            />
          );
        },
        renderCell: params => {
          const name = params.value;
          return <p>{name}</p>;
        },
      },
      {
        field: "effDtFrom",
        headerName: "Effective Date (From) (DD/MM/YYYY)",
        align: "center",
        editable: true,
        minWidth: 200,
        flex: 0.1,
        sortable: false,
        renderEditCell: params => {
          return <EditableDatePicker {...params} required />;
        },
      },
      {
        field: "effDtTo",
        headerName: "Effective Date (To) (DD/MM/YYYY)",
        align: "center",
        editable: true,
        minWidth: 200,
        flex: 0.1,
        sortable: false,
        renderEditCell: params => {
          return <EditableDatePicker {...params} />;
        },
      },
    ],
    [rows, routePointNameTableData]
  );

  const getSearch = async () => {
    setOnSearch(true);
    const searchData = await searchDataAsync({
      ...searchForm,
      pageNumber: pageNumber,
      rowsPerPage: 10,
    });

    const data =
      searchData?.result?.data?.map((item, index) => ({
        rowNumber: index + 1,
        ...item,
      })) ?? [];

    return { searchData, data };
  };

  const handleSearch = async e => {
    try {
      e?.preventDefault();
      setRows([]);
      setMsgError([]);
      setMsgAlert([]);
      setRowSelectionModel([]);
      setMode(ModeAction.VIEW);

      // validate
      // const { searchData, data } = await getSearch();
      setOnSearch(true);
      const searchData = await searchDataAsync({
        ...searchForm,
        pageNumber: pageNumber,
        rowsPerPage: 10,
      });

      const data =
        searchData?.result?.data?.map((item, index) => ({
          rowNumber: index + 1,
          ...item,
        })) ?? [];

      const { isSuccess, errors } = validationSearchForm({
        data: searchForm,
        rule: [
          {
            data: data,
            type: MessageType.NOT_FOUND,
          },
        ],
      });

      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }

      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };

  // 9. handleClear
  const handleClear = () => {
    setMode(ModeAction.VIEW);
    setMsgError([]);
    setMsgAlert([]);
    setRowSelectionModel([]);
    setOnSearch(false);
    setSearchForm(prev => ({
      ...prev,
      // masterTypeCd: "",
      // rPkgOwnerId: "",
      dataOwner: userProfile.dataOwner,
      rPkgOwnerCompAbbr: "",
      rPkgType: "",
      routePointCountryCd: "",
      routePointCategoryCd: "",
      routePointName: "",
    }));
    setRows([]);
    document.getElementById("select_masterTypeCd").focus();
  };

  // 10. handleDownloadExcel
  const handleDownloadExcel = async () => {
    try {
      // validate
      setMsgError([]);
      setMsgAlert([]);
      try {
        const { data } = await getSearch();
        if (!data.length) {
          const msg = messageTypeDisplay(MessageType.NOT_FOUND);
          setMsgError(old => [...old, msg]);
          return;
        }
      } catch (error) {
        const errors = responseErrors(error);
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
      const option = {
        ...searchForm,
        pageNumber: pageNumber,
        rowsPerPage: 10,
      };
      await downloadExcel(option, searchForm.masterTypeCd);
      return;
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setMsgError(oldMsg => [...oldMsg, message({ type: "notFound" })]);
      } else {
        const errors = responseDownloadFileErrors(error);
        setMsgError(errors);
        return;
      }
    }
  };

  const handleDeleteClick = async () => {
    setMsgError([]);
    setMsgAlert([]);
    const confirm = await confirmDialogCtx.success({ type: "confirmDelete" });
    if (!confirm) {
      return;
    }
    try {
      await deleteDataAsync({
        updateBy: userInfo.userName, // TODO: get dataOwner from profile
      });

      setMode(ModeAction.VIEW);
      setRowSelectionModel([]);

      let { searchData, data } = await getSearch();
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);

      const msg = messageTypeDisplay(MessageType.DELETED);
      setMsgAlert([msg]);
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };

  const handleCopy = () => {
    setMode(ModeAction.ADD);
    setMsgError([]);
    setMsgAlert([]);
    setOnSearch(true);

    const rowCopy = rows.find(v => v.rowNumber === rowSelectionModel[0]);
    const maxNo = !rows || rows.length === 0 ? 1 : Math.max(...rows.map(item => item.rowNumber));

    console.log("+++rows2", rows);
    console.log("+++rowCopy2", rowCopy);
    console.log("rpkgOwnerTableData", rpkgOwnerTableData);

    if (searchForm.masterTypeCd === "S") {
      setRows(oldRows => [
        ...oldRows,
        {
          ...rowCopy,
          rowNumber: maxNo + 1,
          // TODO: API Response rPkgOwnerCompId when search
          rPkgOwnerCompAbbr: rpkgOwnerTableData[0].rPkgOwnerCompId,
          // rPkgTypeId: "",
          // rPkgType: "",
          effDtFrom: "",
          effDtTo: "",
          routePointCategory: "",
          routePointCategoryCd: "",
          isNew: true,
        },
      ]);
    } else {
      setRows(oldRows => [
        ...oldRows,
        {
          ...rowCopy,
          rowNumber: maxNo + 1,
          // TODO: API Response rPkgOwnerCompId when search
          rPkgOwnerCompAbbr: rpkgOwnerTableData[0].rPkgOwnerCompId,
          effDtFrom: "",
          effDtTo: "",
          routePointCategory: "",
          routePointCategoryCd: "",
          isNew: true,
        },
      ]);
    }
    setRowModesModel(oldModel => ({
      ...oldModel,
      [maxNo + 1]: { mode: GridRowModes.Edit },
    }));

    setRowSelectionModel([maxNo + 1]);
  };

  const processRowUpdate = async newRow => {
    setMsgError([]);
    setMsgAlert([]);
    if (newRow?.isNew) {
      // to do save
      try {
        // validate
        const qty =
          searchForm.masterTypeCd === "S"
            ? { saftyStkDay: parseFloat(newRow.saftyStkDay) }
            : { overflowStkQty: parseInt(newRow.overflowStkQty) };
        const body = {
          dataOwner: userProfile.dataOwner,
          cd: newRow.routePointCountryCd,
          routePointId: newRow.routePointId,
          routePointName: findObject({
            data: routePointNameTableData,
            value: newRow.routePointId,
            property: "routePointId",
            field: "routePoint",
          }),
          rPkgTypeId: newRow.rPkgTypeId,
          rPkgType: findObject({
            data: rpkgTypeTableTableData,
            value: newRow.rPkgTypeId,
            property: "rPkgTypeId",
            field: "rPkgType",
          }),
          rPkgOwnerCompAbbr: findObject({
            data: rpkgTypeTableTableData,
            value: newRow.rPkgTypeId,
            property: "rPkgTypeId",
            field: "rPkgOwnerCompAbbr",
          }),
          ...qty,
          effDtFrom: newRow.effDtFrom,
          effDtTo: newRow.effDtTo,
          createBy: userInfo.userName,
        };
        await createDataAsync(body);
        const msg = messageTypeDisplay(MessageType.ADDED);
        setMsgAlert([msg]);
        window.scrollTo(0, 0);
        refetchGetRpkgTypeDropDown();
      } catch (error) {
        const errors = responseErrors(error);
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
    } else {
      // to do edit
      try {
        const qty =
          searchForm.masterTypeCd === "S"
            ? { saftyStkDay: parseFloat(newRow.saftyStkDay) }
            : { overflowStkQty: parseFloat(newRow.overflowStkQty) };

        await editDataAsync({
          dataOwner: userProfile.dataOwner,
          routePointId: newRow.routePointId,
          routePointName: newRow.routePointName,
          rPkgTypeId: newRow.rPkgTypeId,
          rPkgType: newRow.rPkgType,
          ...qty,
          effDtFrom: newRow.effDtFrom,
          effDtTo: newRow.effDtTo,
          updateBy: userInfo.userName,
        });

        const msg = messageTypeDisplay(MessageType.UPDATED);
        setMsgAlert([msg]);
        window.scrollTo(0, 0);
      } catch (error) {
        const errors = responseErrors(error);
        setMsgError(errors);
        window.scrollTo(0, 0);
        refetchGetRpkgTypeDropDown();
        return;
      }
    }

    if (rows.length === 1 && ModeAction.ADD === mode) {
      setMode(ModeAction.VIEW);
      setRowSelectionModel([]);
      setRows([]);
    } else {
      const { searchData, data } = await getSearch(pageNumber);

      setMode(ModeAction.VIEW);
      setRowSelectionModel([]);

      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
    }

    const updatedRow = { ...newRow, isNew: false };
    return updatedRow;
  };

  useEffect(() => {
    document.getElementById("select_masterTypeCd").focus();
  }, []);

  return (
    <Fragment>
      <Box sx={{ padding: "1rem" }}>
        <HeaderContentBar title="WDN91080 : Safety Stock and Overflow Master Maintenance Screen" />
        <AlertMessage type={"warning"} message={msgError} />
        <AlertMessage type={"success"} message={msgAlert} />
        <Grid container spacing={0} sx={{ alignItems: "center" }}>
          <Grid item xs={5} style={{ display: "flex" }}>
            <strong style={{ margin: "auto 0px", width: "325px" }}>* Master Type : </strong>
            <InputDropDown
              id="select_masterTypeCd"
              required
              value={searchForm.masterTypeCd}
              onChange={e =>
                setSearchForm(old => {
                  setRows([]);
                  return {
                    ...old,
                    masterTypeCd: e.target.value,
                    dataOwner: userProfile.dataOwner,
                    rPkgOwnerCompAbbr: "",
                    rPkgType: "",
                    routePointCountryCd: "",
                    routePointCategoryCd: "",
                    routePointName: "",
                  };
                })
              }
              memu={typeMasterData?.map(val => ({
                key: val.cd,
                value: val.value,
              }))}
              disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
              placeholder="<Select>"
              defaultValue=""
            />
          </Grid>
          <Grid item xs={1}></Grid>

          {searchForm.masterTypeCd !== "" && (
            <>
              <Grid item xs={5} style={{ display: "flex" }}>
                <strong style={{ margin: "auto 0px", width: "325px" }}>Route Point Country : </strong>
                <InputDropDown
                  id="select_routePointCountryCd"
                  value={searchForm.routePointCountryCd}
                  onChange={e =>
                    setSearchForm(old => ({
                      ...old,
                      routePointCountryCd: e.target.value,
                    }))
                  }
                  memu={routePointCountryData?.map(val => ({
                    key: val.cd,
                    value: val.value,
                  }))}
                  disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
                  placeholder="<All>"
                  defaultValue=""
                />
              </Grid>
              <Grid item xs={1}></Grid>

              <Grid item xs={5} style={{ display: "flex" }}>
                <strong style={{ margin: "auto 0px", width: "325px" }}>Route Point Category : </strong>
                <InputDropDown
                  id="select_routePointCategoryCd"
                  value={searchForm.routePointCategoryCd}
                  onChange={e =>
                    setSearchForm(old => ({
                      ...old,
                      routePointCategoryCd: e.target.value,
                    }))
                  }
                  memu={routePointCategoryData?.map(val => ({
                    key: val.cd,
                    value: val.value,
                  }))}
                  disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
                  placeholder="<All>"
                  defaultValue=""
                />
              </Grid>
              <Grid item xs={1}></Grid>

              <Grid item xs={5} style={{ display: "flex" }}>
                <strong style={{ margin: "auto 0px", width: "325px" }}>Route Point Name : </strong>
                <InputDropDown
                  id="select_routePointName"
                  value={searchForm.routePointName}
                  onChange={e =>
                    setSearchForm(old => ({
                      ...old,
                      routePointName: e.target.value,
                    }))
                  }
                  memu={routePointNameData?.map(val => ({
                    key: val.routePointName,
                    value: val.routePointName,
                  }))}
                  disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
                  placeholder="<All>"
                  defaultValue=""
                />
              </Grid>
              <Grid item xs={1}></Grid>

              <Grid item xs={5} style={{ display: "flex" }}>
                <strong style={{ margin: "auto 0px", width: "325px" }}>R-Package Owner : </strong>
                <InputDropDown
                  id="select_rPkgOwnerCompAbbr"
                  value={searchForm.rPkgOwnerCompAbbr}
                  onChange={e =>
                    setSearchForm(old => ({
                      ...old,
                      rPkgOwnerCompAbbr: e.target.value,
                    }))
                  }
                  memu={rpkgOwnerData?.map(val => ({
                    key: val.rPkgOwnerCompAbbr,
                    value: val.rPkgOwnerCompAbbr,
                  }))}
                  disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
                  placeholder="<All>"
                  defaultValue=""
                />
              </Grid>
              <Grid item xs={1}></Grid>

              <Grid item xs={5} style={{ display: "flex" }}>
                <strong style={{ margin: "auto 0px", width: "325px" }}>R-Package Type : </strong>
                <InputDropDown
                  id="select_rPkgType"
                  value={searchForm.rPkgType}
                  onChange={e =>
                    setSearchForm(old => ({
                      ...old,
                      rPkgType: e.target.value,
                    }))
                  }
                  memu={rpkgTyperData?.map(val => ({
                    key: val.rPkgType,
                    value: val.rPkgType,
                  }))}
                  disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
                  placeholder="<All>"
                  defaultValue=""
                />
              </Grid>
              <Grid item xs={1}></Grid>
            </>
          )}
        </Grid>
      </Box>
      {searchForm.masterTypeCd !== "" && (
        <>
          <ActionBar
            mode={mode}
            rows={rows}
            columns={columns}
            setRows={setRows}
            setMode={setMode}
            setMsgError={setMsgError}
            setMsgAlert={setMsgAlert}
            setOnSearch={setOnSearch}
            setRowModesModel={setRowModesModel}
            setRowSelectionModel={setRowSelectionModel}
            rowSelectionModel={rowSelectionModel}
            rowModesModel={rowModesModel}
            handleSearch={handleSearch}
            handleClear={handleClear}
            handleDownloadExcel={handleDownloadExcel}
            handleDeleteClick={handleDeleteClick}
            handleCopy={handleCopy}
            functionId={"WDN91080"}
          />
          <Box sx={{ padding: "1rem" }}>
            <Grid>
              {onSearch && (
                <DataTable
                  mode={mode}
                  onSearch={onSearch}
                  rowSelectionModel={rowSelectionModel}
                  setRowSelectionModel={setRowSelectionModel}
                  setMode={setMode}
                  rows={rows}
                  rowModesModel={rowModesModel}
                  columns={columns}
                  setRowModesModel={setRowModesModel}
                  processRowUpdate={processRowUpdate}
                  pagination={pagination}
                  pageNumber={pageNumber}
                  handleChangePagination={handleChangePagination}
                  columnGroupingModel={null}
                  columnVisibilityModel={{
                    currencys: false,
                    // rPkgTypeId: false,
                  }}
                />
              )}
              <SubmitBar
                mode={mode}
                rows={rows}
                setMode={setMode}
                setRows={setRows}
                setRowModesModel={setRowModesModel}
                setMsgError={setMsgError}
                rowModesModel={rowModesModel}
                rowSelectionModel={rowSelectionModel}
                setRowSelectionModel={setRowSelectionModel}
                functionId={"WDN91080"}
              />
            </Grid>
          </Box>
        </>
      )}
    </Fragment>
  );
};

export default SafetyStockandOverflowScreen;
