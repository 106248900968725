import { Grid } from "@mui/material";
import { CheckBox, DatePickerField, DropDown, InputTextField } from "components/UI";
import React, { Fragment } from "react";
//Service
import { useExportInvoiceShipmentCarrierMutation } from "shared/services/invoice";
import { findObject, responseErrors } from "utils";
import { userProfile } from "constant";
import { COMMON_ENUM } from "shared/constants";
export default function DetailSection(props) {
  const {
    mode,
    form,
    setForm,
    isAPMCUser,
    dataList: { currencyData, shipmentEtdData, shipCarrierData, expData, brokerData },
    setShipCarrierData,
    setMsg: { setMsgAlert, setMsgError },
  } = props;
  const shipmentCarrier = useExportInvoiceShipmentCarrierMutation();

  const handleCheckNonComInvFlag = e => {
    setForm(prev => ({
      ...prev,
      nonComInvFlag: e.target.checked,
    }));
  };

  const handleChangeCurrencyCd = e => {
    setForm(prev => ({
      ...prev,
      currencyCd: e.target.value,
    }));
  };

  const handleChangeShipmentCd = async e => {
    const value = e.target.value;
    const carrierCompanyAbbr = await getCarrier(value);
    setShipCarrierData(carrierCompanyAbbr);
    if (!value) {
      setForm(prev => ({
        ...prev,
        shipmentEtd: value,
        carrierCompanyAbbr: "",
      }));
      return;
    } else {
      setForm(prev => ({
        ...prev,
        shipmentEtd: value,
        carrierCompanyAbbr: carrierCompanyAbbr[0].carrierCompanyAbbr,
      }));
    }
  };
  const getCarrier = async value => {
    try {
      const body = {
        dataOwner: userProfile.dataOwner,
        shipmentEtd: value,
        etdDt: form?.etdDt,
        impCompanyId: form?.impCompanyId ?? "",
        exporterCd: form?.exporterPlantId
          ? findObject({
              data: expData,
              value: form?.exporterPlantId,
              property: "plantId",
              field: "exporterCd",
            })
          : "",
        expCompanyAbbr: form?.exporterPlantId
          ? findObject({
              data: expData,
              value: form?.exporterPlantId,
              property: "plantId",
              field: "exporterCompanyAbbr",
            })
          : "",
      };
      const { result: shipCarrierResultData } = await shipmentCarrier(body);
      return shipCarrierResultData;
    } catch (error) {
      const msg = responseErrors(error);
      setMsgError(msg);
    }
  };
  const handleChangeShipCarrierCompanyAbbr = e => {
    setForm(prev => ({
      ...prev,
      carrierCompanyAbbr: e.target.value,
    }));
  };

  return (
    <Fragment>
      {/* Container #1 */}
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={2}>
          <strong>Export Custom Stock Date : </strong>
        </Grid>
        <Grid item xs={2}>
          <DatePickerField
            id="input-export-custom-stock-date"
            className="input-export-custom-stock-date"
            onChange={e =>
              setForm(old => ({
                ...old,
                expCustomsStkDt: e,
              }))
            }
            value={form?.expCustomsStkDt}
            disabled={[COMMON_ENUM.SC_MODE_VIEW].includes(mode)}
          />
        </Grid>
        <Grid item xs={3}>
          <CheckBox
            id="checkbox-non-com-inv-flag"
            placement="end"
            label="Non-Commercial Invoice"
            sx={{
              color: "#000",
              fontFamily: "Kanit",
              fontWeight: "bold",
              ml: 1,
            }}
            disabled={[COMMON_ENUM.SC_MODE_VIEW].includes(mode)}
            checked={form?.nonComInvFlag}
            onChange={e => handleCheckNonComInvFlag(e)}
          />
        </Grid>
        <Grid item xs={2}>
          <strong>Broker : </strong>
        </Grid>
        <Grid item xs={2}>
          <DropDown
            id="input-broker"
            fullWidth
            required
            defaultValue=""
            placeholder="All"
            value={form?.expBrokerCompanyId}
            onChange={e =>
              setForm(old => ({
                ...old,
                expBrokerCompanyId: e.target.value,
              }))
            }
            menu={brokerData?.map(val => ({
              key: val?.companyId,
              value: val?.companyAbbr,
            }))}
            disabled={[COMMON_ENUM.SC_MODE_VIEW].includes(mode)}
          />
        </Grid>
      </Grid>

      {/* Container #2 */}
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={2}>
          <strong>*Currency : </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-currency"
            fullWidth
            required
            defaultValue=""
            placeholder="<All>"
            value={form?.currencyCd}
            onChange={handleChangeCurrencyCd}
            menu={currencyData?.map(val => ({
              key: val?.cd,
              value: val?.cd,
            }))}
            disabled={[COMMON_ENUM.SC_MODE_VIEW].includes(mode)}
          />
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={2}>
          <strong>Export Entry No : </strong>
        </Grid>
        <Grid item xs={2}>
          <InputTextField
            id="input-export-entry-no"
            fullWidth={true}
            maxLength={20}
            value={form?.expEntryNo}
            onChange={e =>
              setForm(old => ({
                ...old,
                expEntryNo: e.target.value,
              }))
            }
            disabled={[COMMON_ENUM.SC_MODE_VIEW].includes(mode)}
          />
        </Grid>
      </Grid>
      {/* Container #3 */}
      {isAPMCUser !== null && isAPMCUser && (
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={2}>
            <strong>*Shipment Code|ETD : </strong>
          </Grid>
          <Grid item xs={3}>
            <DropDown
              id="select-shipment"
              fullWidth
              required
              defaultValue=""
              placeholder="----------Add New Shipment----------"
              value={form?.shipmentEtd}
              onChange={handleChangeShipmentCd}
              menu={shipmentEtdData?.map(val => ({
                key: val?.shipmentLabel,
                value: val?.shipmentLabel,
              }))}
              disabled={[COMMON_ENUM.SC_MODE_VIEW, COMMON_ENUM.SC_MODE_EDIT].includes(mode)}
            />
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={2}>
            <strong>Carrier : </strong>
          </Grid>
          <Grid item xs={2}>
            <DropDown
              id="select-ship-carier"
              fullWidth
              required
              defaultValue=""
              placeholder="<All>"
              value={form?.carrierCompanyAbbr}
              onChange={handleChangeShipCarrierCompanyAbbr}
              menu={shipCarrierData?.map(val => ({
                key: val?.carrierCompanyAbbr,
                value: val?.carrierCompanyAbbr,
              }))}
              disabled={
                [COMMON_ENUM.SC_MODE_VIEW, COMMON_ENUM.SC_MODE_EDIT].includes(mode) ||
                (form?.shipmentEtd && shipCarrierData && shipCarrierData.length > 0)
              }
            />
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
}
