import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  token: null,
  dateTime: null,
};
const common = createSlice({
  name: "common",
  initialState,
  reducers: {
    setCommonDateTime: (state, action) => {
      state.dateTime = action.payload;
    },
    setCredentials: (state, { payload: { dateTime, token } }) => {
      state.dateTime = dateTime;
      state.token = token;
    },
  },
});
export const { setCredentials, setCommonDateTime } = common.actions;

export default common.reducer;

export const commonReducer = state => state.common.dateTime;
