import { Grid } from "@mui/material";
import { DatePickerField, DropDown, InputTextField } from "components/UI";
import { FORM_DEFAULT_DELIVERY_PLAN } from "pages/Report/constants/constants";
import React, { useEffect, useState } from "react";
import ButtonAction from "../ButtonAction";
import {
  useReportCommonGetRPackageOwnerQuery,
  useReportCommonGetRPackageTypeQuery,
  useGetRPackageMakerCompanyListQuery,
  useExcelDownloadOnlineMutation,
} from "shared/services/report";
import { userProfile } from "constant";
import { responseErrors } from "utils";
import { useLoadingContext } from "shared/contexts/LoadingContext";
import { waitAndDownloadExcel } from "shared/services/common-dowload/download-helper-service";
import { useSelector } from "react-redux";

export default function DeliveryPlanActualReport(props) {
  const userInfo = useSelector(state => state.auth.user);
  const [form, setForm] = useState(FORM_DEFAULT_DELIVERY_PLAN);

  const { data: rPkgMakerList } = useGetRPackageMakerCompanyListQuery();
  const { data: rPkgOwnerList } = useReportCommonGetRPackageOwnerQuery();
  const { data: rPkgTypeList } = useReportCommonGetRPackageTypeQuery(form.rPkgTypeOwner);

  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(false);
  const generateReport = useExcelDownloadOnlineMutation();
  const { startLoading, stopLoading } = useLoadingContext();
  const handleDownload = async () => {
    try {
      props.setMsgError([]);
      props.setMsgAlert([]);
      const body = {
        userId: userInfo.userName,
        displayName: userInfo.displayName,
        dataOwner: userProfile.dataOwner,
        poNumber: form?.poNumber,
        rPackageOwner: form?.rPkgTypeOwner,
        rPackageMaker: form?.rPkgMaker,
        rPackageType: form?.rPkgType,
        monthFrom: form?.monthForm,
        monthTo: form.monthTo,
      };
      const pathReport = "generate-delivery-plan-actual-report";
      startLoading();
      const response = await generateReport(body, pathReport);
      waitAndDownloadExcel(60, response.data, props.setMsgError, stopLoading);
      setDisabledSubmitBtn(false);
      stopLoading();
      return;
    } catch (error) {
      const msgError = responseErrors(error);
      props.setMsgError(msgError);
      setDisabledSubmitBtn(false);
      stopLoading();
    }
  };

  useEffect(() => {
    document.getElementById("select-rpkg-maker").focus();
  }, []);

  return (
    <Grid container>
      <Grid item xs={5} style={{ display: "flex", paddingTop: "7px" }}>
        <strong style={{ margin: "auto 0px", width: "300px" }}>*Report : </strong>
        <DropDown
          id="select-report"
          fullWidth
          required
          defaultValue=""
          placeholder="<Select>"
          menu={[{ key: "1", value: "Delivery Plan Actual Report" }]}
          value={form?.reportId}
          disabled
        />
      </Grid>
      <Grid item xs={7}></Grid>
      <Grid item xs={5} style={{ display: "flex", paddingTop: "7px" }}>
        <strong style={{ margin: "auto 0px", width: "300px" }}>*R-Package Maker : </strong>
        <DropDown
          id="select-rpkg-maker"
          fullWidth
          required
          value={form?.rPkgMaker}
          defaultValue=""
          placeholder="<Select>"
          // autoFocus={true}
          menu={rPkgMakerList?.map((val, key) => ({
            key: val.name,
            value: val.name,
          }))}
          onChange={e =>
            setForm(old => ({
              ...old,
              rPkgMaker: e.target.value,
            }))
          }
        />
      </Grid>
      <Grid item xs={7}></Grid>
      <Grid item xs={5} style={{ display: "flex", paddingTop: "7px" }}>
        <strong style={{ margin: "auto 0px", width: "300px" }}>*R-Package Owner : </strong>
        <DropDown
          id="select-rpackage-owner"
          fullWidth
          required
          value={form?.rPkgTypeOwner}
          defaultValue=""
          placeholder="<Select>"
          // autoFocus={true}
          menu={rPkgOwnerList?.map(val => ({
            key: val.rPkgOwnerCompAbbr,
            value: val.rPkgOwnerCompAbbr,
          }))}
          onChange={e =>
            setForm(old => ({
              ...old,
              rPkgTypeOwner: e.target.value,
              rPkgType: "",
            }))
          }
        />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={5} style={{ display: "flex", paddingTop: "7px" }}>
        <strong style={{ margin: "auto 0px", width: "300px" }}>*R-Package Type : </strong>
        <DropDown
          id="select-rpackage-type"
          fullWidth
          value={form?.rPkgType}
          defaultValue=""
          placeholder="<Select>"
          // autoFocus={true}
          menu={rPkgTypeList?.map(val => ({
            key: val.rPkgType,
            value: val.rPkgType,
          }))}
          onChange={e =>
            setForm(old => ({
              ...old,
              rPkgType: e.target.value,
            }))
          }
          disabled={!form?.rPkgTypeOwner.length}
          required
        />
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={4} style={{ display: "flex", paddingTop: "4px" }}>
        <strong style={{ margin: "auto 0px", width: "338px" }}>Month (From) : </strong>
        <DatePickerField
          views={["month", "year"]}
          format="MM/YYYY"
          mask="MM/YYYY"
          fullWidth={true}
          value={form?.monthForm}
          onChange={e =>
            setForm(old => ({
              ...old,
              monthForm: e,
            }))
          }
        />
      </Grid>
      <Grid item xs={2} style={{ margin: "auto 0px" }}>
        (MM/YYYY)
      </Grid>
      <Grid item xs={4} style={{ display: "flex", paddingTop: "4px" }}>
        <strong style={{ margin: "auto 0px", width: "338px" }}>Month (To) : </strong>
        <DatePickerField
          views={["month", "year"]}
          format="MM/YYYY"
          mask="MM/YYYY"
          fullWidth={true}
          value={form?.monthTo}
          onChange={e =>
            setForm(old => ({
              ...old,
              monthTo: e,
            }))
          }
        />
      </Grid>
      <Grid item xs={2} style={{ margin: "auto 0px" }}>
        (MM/YYYY)
      </Grid>
      <Grid item xs={5} style={{ display: "flex", paddingTop: "0px" }}>
        <strong style={{ margin: "auto 0px", width: "300px" }}>P/O Number : </strong>
        <InputTextField
          id="input-po-number"
          fullWidth={true}
          maxLength={15}
          value={form?.poNumber}
          regularExp={/^[a-zA-Z0-9_*-]*$/}
          onChange={e =>
            setForm(old => ({
              ...old,
              poNumber: e.target.value,
            }))
          }
        />
      </Grid>
      <Grid item xs={7}></Grid>

      <ButtonAction
        handleDownload={handleDownload}
        setFrom={setForm}
        defaultFrom={FORM_DEFAULT_DELIVERY_PLAN}
        disabled={disabledSubmitBtn}
        setDisabled={setDisabledSubmitBtn}
        clearMsg={() => {
          props.setMsgAlert([]);
          props.setMsgError([]);
        }}
        firstField={"select-rpkg-maker"}
      />
    </Grid>
  );
}
