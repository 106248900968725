import { Grid } from "@mui/material";
import { DatePickerField, DropDown } from "components/UI";
import { FORM_DEFAULT_STOCK_VISUALIZATION } from "pages/Report/constants/constants";
import React, { useEffect, useState } from "react";
import ButtonAction from "../ButtonAction";
import {
  useReportCommonGetRPackageOwnerQuery,
  useReportCommonGetRPackageTypeCategoryQuery,
  useReportCommonGetLocationNameListQuery,
  useReportCommonRPackageCategoryListQuery,
  useExcelDownloadOnlineMutation,
} from "shared/services/report";
import { userProfile } from "constant";
import { findObject, responseErrors } from "utils";
import { useLoadingContext } from "shared/contexts/LoadingContext";
import { waitAndDownloadExcel } from "shared/services/common-dowload/download-helper-service";
import { useSelector } from "react-redux";
export default function StockVisualizationReport(props) {
  const userInfo = useSelector(state => state.auth.user);
  const convertGroupCd = rPkgTypeCategory => {
    switch (rPkgTypeCategory) {
      case "M":
        return "RM";
      case "B":
        return "OT";
      case "D":
        return "";
      default:
        break;
    }
    return;
  };
  const [form, setForm] = useState(FORM_DEFAULT_STOCK_VISUALIZATION);
  //API
  const { data: rPkgOwnerList } = useReportCommonGetRPackageOwnerQuery();
  const { data: rPkgTypeList } = useReportCommonGetRPackageTypeCategoryQuery(
    form.rPkgTypeOwner,
    convertGroupCd(form.rPkgTypeCategory)
  );
  const { data: locationFromToList } = useReportCommonGetLocationNameListQuery(userProfile.dataOwner);
  const { data: rPackageCategoryList } = useReportCommonRPackageCategoryListQuery();

  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(false);
  const generateReport = useExcelDownloadOnlineMutation();
  const { startLoading, stopLoading } = useLoadingContext();

  const handleDownload = async () => {
    try {
      props.setMsgError([]);
      props.setMsgAlert([]);
      const body = {
        displayName: userInfo.displayName,
        dataOwner: userProfile.dataOwner,
        rPackageOwner: form?.rPkgTypeOwner,
        userId: userInfo?.userName,
        locationName: form?.location,
        rPackageCategory: form?.rPkgTypeCategory,
        rPackageCategoryName: findObject({
          data: rPackageCategoryList,
          value: form?.rPkgTypeCategory,
          property: "cd",
          field: "value",
        }),
        locationDesc: findObject({
          data: locationFromToList,
          value: form?.location,
          property: "cd",
          field: "name",
        }),
        transactionDateFrom: form?.dateFrom,
        transactionDateTo: form?.dateTo,
        rPackageType: form?.rPkgType,
      };
      const pathReport = "generate-stock-visualization-report";
      startLoading();
      const response = await generateReport(body, pathReport);
      waitAndDownloadExcel(120, response.data, props.setMsgError, stopLoading);
      setDisabledSubmitBtn(false);
      return;
    } catch (error) {
      const msgError = responseErrors(error);
      props.setMsgError(msgError);
      setDisabledSubmitBtn(false);
      stopLoading();
    }
  };

  useEffect(() => {
    document.getElementById("input-date-from").focus();
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={5} style={{ display: "flex" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>* Report : </strong>
          <DropDown
            id="select-report"
            fullWidth
            required
            defaultValue=""
            placeholder="<Select>"
            menu={[{ key: "LDN960G0", value: "Stock Visualization Report" }]}
            value={"LDN960G0"}
            disabled
          />
        </Grid>
        <Grid item xs={7} />
        <Grid item xs={4} style={{ display: "flex", paddingTop: "3px" }}>
          <strong style={{ margin: "auto 0px", width: "338px" }}>* Date(From) : </strong>
          <DatePickerField
            id="input-date-from"
            className="input-date-from"
            onChange={e =>
              setForm(old => ({
                ...old,
                dateFrom: e,
              }))
            }
            value={form?.dateFrom}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={2} style={{ display: "flex", paddingTop: "3px" }}>
          <strong style={{ margin: "auto 0px" }}>(DD/MM/YYYY)</strong>
        </Grid>
        <Grid item xs={4} style={{ display: "flex", paddingTop: "3px" }}>
          <strong style={{ margin: "auto 0px", width: "338px" }}>Date(To) : </strong>
          <DatePickerField
            id="input-date-to"
            className="input-date-to"
            onChange={e =>
              setForm(old => ({
                ...old,
                dateTo: e,
              }))
            }
            value={form?.dateTo}
            fullWidth
          />
        </Grid>
        <Grid item xs={2} style={{ display: "flex", paddingTop: "3px" }}>
          <strong style={{ margin: "auto 0px" }}>(DD/MM/YYYY)</strong>
        </Grid>
        <Grid item xs={5} style={{ display: "flex", paddingTop: "6px" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>Location : </strong>
          <DropDown
            id="select-location"
            fullWidth
            value={form?.location}
            defaultValue=""
            placeholder="<All>"
            menu={locationFromToList?.map(val => ({
              key: val?.cd,
              value: val?.name,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                location: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", paddingTop: "6px" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>R - Package Owner: </strong>
          <DropDown
            id="select-rpackage-owner"
            fullWidth
            value={form?.rPkgTypeOwner}
            defaultValue=""
            placeholder="<All>"
            menu={rPkgOwnerList?.map(val => ({
              key: val?.rPkgOwnerCompAbbr,
              value: val?.rPkgOwnerCompAbbr,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                rPkgTypeOwner: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", paddingTop: "6px" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>R - Package Category: </strong>
          <DropDown
            id="select-rpackage-category"
            fullWidth
            value={form?.rPkgTypeCategory}
            defaultValue=""
            placeholder="<All>"
            menu={rPackageCategoryList?.map(val => ({
              key: val?.cd,
              value: val?.value,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                rPkgTypeCategory: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", paddingTop: "6px" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>R - Package Type: </strong>
          <DropDown
            id="select-rpackage-type"
            fullWidth
            value={form?.rPkgType}
            defaultValue=""
            placeholder="<All>"
            menu={rPkgTypeList?.map(val => ({
              key: val?.rPkgType,
              value: val?.rPkgType,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                rPkgType: e.target.value,
              }))
            }
            disabled={form?.rPkgTypeCategory === "D"}
          />
        </Grid>
        <Grid item xs={1} />

        <ButtonAction
          handleDownload={handleDownload}
          setFrom={setForm}
          defaultFrom={FORM_DEFAULT_STOCK_VISUALIZATION}
          disabled={disabledSubmitBtn}
          setDisabled={setDisabledSubmitBtn}
          clearMsg={() => {
            props.setMsgAlert([]);
            props.setMsgError([]);
          }}
          firstField={"input-date-from"}
        />
      </Grid>
    </>
  );
}
