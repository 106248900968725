import { API_WAREHOUSE_SUMMARY } from "shared/constants";
import { TanStackDatePickerField, TanStackDropDown } from "components/UI";
import { ModeAction } from "state/enum";

const INDEX_OF_ALLOCATE_DATE = 7;
export default function useColumnTanStack({ mode, rPkg, wareHouseList, handleHoliday }) {
  try {
    const rowsChanged = [];
    const column = [
      // Col 1
      {
        header: "No.",
        accessorKey: "rowNumber",
        headerAlign: "center",
        align: "right",
        size: 20,
      },
      // Col 2
      {
        header: "Shipment Code",
        accessorKey: API_WAREHOUSE_SUMMARY.SHIPMENT_CODE,
        headerAlign: "center",
        align: "left",
        size: 100,
      },
      // Col 3
      {
        header: "Container No.",
        accessorKey: API_WAREHOUSE_SUMMARY.CONTAINER_NO,
        headerAlign: "center",
        align: "left",
        size: 100,
      },
      // Col 4
      {
        header: "Status",
        accessorKey: API_WAREHOUSE_SUMMARY.ALLOCATE_STATUS,
        headerAlign: "center",
        align: "center",
        size: 100,
      },
      // Col 5
      {
        header: "Ready For Date (DD/MM/YYYY)",
        accessorKey: "readyForDt",
        headerAlign: "center",
        align: "center",
        size: 100,
      },
      // Col 6
      {
        header: "W/H",
        accessorKey: "plantId",
        headerAlign: "center",
        align: "left",
        size: 200,
        cell: props => {
          if (mode === ModeAction.EDIT) {
            return (
              <TanStackDropDown
                value={props?.row?.original?.plantId}
                menu={wareHouseList?.map(val => ({
                  key: val.plantId,
                  value: `${val.companyAbbr}:${val.impExpCd}`,
                }))}
                defaultValue=""
                placeholder="-- NONE --"
                onChange={e => {
                  props.row.original.plantId = e.target.value;
                  if (rowsChanged.length === 0) {
                    rowsChanged.push(props.row.original);
                  } else {
                    if (!rowsChanged.some(i => i === props.row.original)) {
                      rowsChanged.push(props.row.original);
                    }
                  }
                }}
                {...props}
              />
            );
          }
          return props?.row?.original.wareHouseName;
        },
      },
      // Col 7
      {
        header: "Allocation Date (DD/MM/YYYY)",
        accessorKey: "allocateDt",
        headerAlign: "center",
        align: "center",
        size: 250,
        cell: props => {
          const row = props.row.original;
          const isDisabled = row?.plantId === "";
          if (mode === ModeAction.EDIT) {
            return (
              <TanStackDatePickerField
                value={row?.allocateDt}
                disabled={isDisabled}
                disablePast={true}
                disableKey={true}
                onChange={e => {
                  props.row.original.allocateDt = e;
                  if (props.row.original.plantId !== "") {
                    if (rowsChanged.length === 0) {
                      rowsChanged.push(props.row.original);
                    } else {
                      if (!rowsChanged.some(i => i === props.row.original)) {
                        rowsChanged.push(props.row.original);
                      }
                    }
                  }
                }}
                holidayList={handleHoliday(row)}
                {...props}
              />
            );
          }
          return props?.row?.original.allocateDt;
        },
      },
      // Col 8
      {
        accessorKey: "wareHouseName",
        enableHiding: true,
      },
      // Col 8
      {
        accessorKey: "whCompanyAbbrId",
        enableHiding: true,
      },
      // Col 8
      {
        accessorKey: "whCompanyAbbr",
        enableHiding: true,
      },
    ];
    let newCol = [];
    if (!rPkg) {
      console.log("R-Package Col not found");
      return column;
    }
    newCol = rPkg.map((val, index) => ({
      header: val,
      accessorKey: val,
      headerAlign: "center",
      align: "right",
      size: 500,
      accessorFn: row => {
        const value = row[val];
        if (typeof value === "number") {
          if (value === 0) {
            return "";
          }
          return new Intl.NumberFormat("en-US").format(value);
        }
        return value;
      },
    }));
    column.splice(INDEX_OF_ALLOCATE_DATE, 0, ...newCol);
    return { columns: column, rowsChanged };
  } catch (error) {
    console.error(error);
  }
}
