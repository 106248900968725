import { Grid } from "@mui/material";
import { DropDown, InputTextField } from "components/UI";
import { FORM_DEFAULT_AGING_STOCK_USAGE } from "pages/Report/constants/constants";
import React, { useEffect, useState } from "react";
import ButtonAction from "../ButtonAction";
import {
  useExcelDownloadOnDemandMutation,
  useReportCommonGetLocationNameListQuery,
  useReportCommonGetRPackageOwnerQuery,
  useReportCommonGetRPackageTypeQuery,
  useReportGetAvailableRPackageStatusQuery,
} from "shared/services/report";
import { userProfile } from "constant";
import { responseErrors } from "utils";
import { useLoadingContext } from "shared/contexts/LoadingContext";
import { useSelector } from "react-redux";

export default function AgingStockAndUsageRawDataReport(props) {
  const userInfo = useSelector(state => state.auth.user);
  const [form, setForm] = useState(FORM_DEFAULT_AGING_STOCK_USAGE);
  const { data: rPkgOwnerList } = useReportCommonGetRPackageOwnerQuery();
  const { data: rPkgTypeList } = useReportCommonGetRPackageTypeQuery(form.rPkgTypeOwner);
  const { data: locationFromToList } = useReportCommonGetLocationNameListQuery(userProfile.dataOwner);
  const { data: currentStatusList } = useReportGetAvailableRPackageStatusQuery();

  const generateReport = useExcelDownloadOnDemandMutation();

  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(false);
  const { startLoading, stopLoading } = useLoadingContext();
  const handleDownload = async () => {
    props.setMsgError([]);
    props.setMsgAlert([]);
    setDisabledSubmitBtn(true);
    try {
      const body = {
        userId: userInfo.userName,
        displayName: userInfo.displayName,
        dataOwner: userProfile.dataOwner,
        rPackageOwner: form?.rPkgTypeOwner,
        rPackageType: form?.rPkgType,
        locationName: form?.currentLocation,
        currentStatus: form?.currentStatus,
        deadStockPeriodFrom: form?.deadStockPeriodFrom,
        deadStockPeriodTo: form?.deadStockPeriodTo,
        numberOfUsageFrom: form?.usageTimesFrom,
        numberOfUsageTo: form?.usageTimesTo,
      };
      const pathReport = "generate-dead-stock-usage-raw-data-report";
      startLoading();
      const { result } = await generateReport(body, pathReport);
      props.setMsgAlert([result?.message]);
      stopLoading();
      setDisabledSubmitBtn(false);
      return;
    } catch (error) {
      const msgError = responseErrors(error);
      props.setMsgError(msgError);
      stopLoading();
      setDisabledSubmitBtn(false);
    }
  };

  useEffect(() => {
    document.getElementById("select-rpackage-owner").focus();
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={5} style={{ display: "flex" }}>
          <strong style={{ margin: "auto 0px", width: "380px" }}>*Report : </strong>
          <DropDown
            id="select-report"
            fullWidth
            required
            defaultValue=""
            placeholder="<Select>"
            menu={[{ key: "1", value: "Aging Stock And Usage Raw Data Report" }]}
            value={form?.reportId}
            disabled
          />
        </Grid>
        <Grid item xs={7} />
        <Grid item xs={5} style={{ display: "flex", paddingTop: "7px" }}>
          <strong style={{ margin: "auto 0px", width: "380px" }}>*R-Package Owner : </strong>
          <DropDown
            id="select-rpackage-owner"
            fullWidth
            required
            value={form?.rPkgTypeOwner}
            defaultValue=""
            placeholder="<Select>"
            // autoFocus={true}
            menu={rPkgOwnerList?.map(val => ({
              key: val.rPkgOwnerCompAbbr,
              value: val.rPkgOwnerCompAbbr,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                rPkgTypeOwner: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", paddingTop: "7px" }}>
          <strong style={{ margin: "auto 0px", width: "380px" }}>R-Package Type : </strong>
          <DropDown
            id="select-rpackage-type"
            fullWidth
            value={form?.rPkgType}
            defaultValue=""
            placeholder="<All>"
            // autoFocus={true}
            menu={rPkgTypeList?.map(val => ({
              key: val.rPkgType,
              value: val.rPkgType,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                rPkgType: e.target.value,
              }))
            }
            disabled={!form.rPkgTypeOwner?.length}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", paddingTop: "7px" }}>
          <strong style={{ margin: "auto 0px", width: "380px" }}>Current Location : </strong>
          <DropDown
            id="select-current-location"
            fullWidth
            value={form?.currentLocation}
            defaultValue=""
            placeholder="<All>"
            // autoFocus={true}
            menu={locationFromToList?.map(val => ({
              key: val.cd,
              value: val.name,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                currentLocation: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", paddingTop: "7px" }}>
          <strong style={{ margin: "auto 0px", width: "380px" }}>Current Status : </strong>
          <DropDown
            id="select-current-status"
            fullWidth
            value={form?.currentStatus}
            defaultValue=""
            placeholder="<All>"
            // autoFocus={true}
            style={{ margin: "auto" }}
            menu={currentStatusList?.map(val => ({
              key: val.value,
              value: val.value,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                currentStatus: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", paddingTop: "0px" }}>
          <strong style={{ margin: "auto 0px", width: "380px" }}>Dead Stock Period (days) From : </strong>
          <InputTextField
            id="input-dead-stock-period-from"
            fullWidth={true}
            maxLength={3}
            value={form?.deadStockPeriodFrom}
            onChange={e => {
              let regex = /[0-9]+$/;
              if (regex.test(e.target.value) || e.target.value === "")
                setForm(old => ({
                  ...old,
                  deadStockPeriodFrom: e.target.value,
                }));
            }}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", paddingTop: "0px" }}>
          <strong style={{ margin: "auto 0px", width: "380px" }}>Dead Stock Period (days) To : </strong>
          <InputTextField
            id="input-dead-stock-period-to"
            fullWidth={true}
            maxLength={3}
            value={form?.deadStockPeriodTo}
            onChange={e => {
              let regex = /[0-9]+$/;
              if (regex.test(e.target.value) || e.target.value === "")
                setForm(old => ({
                  ...old,
                  deadStockPeriodTo: e.target.value,
                }));
            }}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", paddingTop: "0px", marginTop: "-7px" }}>
          <strong style={{ margin: "auto 0px", width: "380px" }}>Usage times From : </strong>
          <InputTextField
            id="input-usage-times-from"
            fullWidth={true}
            maxLength={3}
            value={form?.usageTimesFrom}
            onChange={e => {
              let regex = /[0-9]+$/;
              if (regex.test(e.target.value) || e.target.value === "")
                setForm(old => ({
                  ...old,
                  usageTimesFrom: e.target.value,
                }));
            }}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", paddingTop: "0px", marginTop: "-7px" }}>
          <strong style={{ margin: "auto 0px", width: "380px" }}>Usage times To : </strong>
          <InputTextField
            id="input-usage-times-to"
            fullWidth={true}
            maxLength={3}
            value={form?.usageTimesTo}
            onChange={e => {
              let regex = /[0-9]+$/;
              if (regex.test(e.target.value) || e.target.value === "")
                setForm(old => ({
                  ...old,
                  usageTimesTo: e.target.value,
                }));
            }}
          />
        </Grid>
        <Grid item xs={1} />

        <ButtonAction
          handleDownload={handleDownload}
          setFrom={setForm}
          defaultFrom={FORM_DEFAULT_AGING_STOCK_USAGE}
          disabled={disabledSubmitBtn}
          setDisabled={setDisabledSubmitBtn}
          clearMsg={() => {
            props.setMsgAlert([]);
            props.setMsgError([]);
          }}
          firstField={"select-rpackage-owner"}
        />
      </Grid>
    </>
  );
}
