import React from "react";
import { useGridApiContext } from "@mui/x-data-grid";
import { TextField, styled } from "@mui/material";
import { isEmpty } from "lodash";

const StyledTextField = styled(TextField)(({ theme, error, props }) => ({
  "& .MuiInput-root": {
    border: `2px solid black`,
    backgroundColor: theme.palette.primary.light,
    borderRadius: ".3rem",
  },
  "& .MuiInput-root.Mui-focused": {
    border: `1.5px solid ${theme.palette.primary.dark} !important`,
    borderRadius: ".3rem",
    color: `${theme.palette.primary.dark}`,
  },
  "& .MuiInputBase-input": {
    border: `1.5px solid black`,
    padding: "4px 8px !important",
  },
  "& .css-nz481w-MuiInputBase-input-MuiInput-input": {
    border: `1.7px solid`,
  },
}));
export default function EditableDecimalTextField(props) {
  const {
    value,
    field,
    id,
    style = {},
    placeholder = "",
    type = "text",
    regularExp,
    maxLength,
    autoFocus = false,
    required,
    disabled = false,
    fullWidth = false,
    multiline = false,
    textAlign = "start",
    onKeyDown,
  } = props;
  const apiRef = useGridApiContext();
  const handleValueChange = event => {
    let newValue = event.target.value; // The new value entered by the user
    if (isEmpty(newValue)) {
      apiRef.current.setEditCellValue({
        id: id,
        field: field,
        value: newValue,
      });
      return;
    }

    if (regularExp && !regularExp.test(newValue)) {
      apiRef.current.setEditCellValue({
        id: id,
        field: field,
        value: newValue,
      });
      return;
    }
    return;
  };

  return (
    <div style={{ margin: "0 5px", display: "grid", width: "100%" }}>
      <StyledTextField
        data-testid={`${id}-${field}`}
        margin="dense"
        size="small"
        variant="standard"
        inputProps={{ maxLength: maxLength, style: { textAlign: textAlign } }}
        InputProps={{
          sx: {
            height: "auto",
            ...style,
          },
          disableUnderline: true,
          required: required,
        }}
        multiline={multiline}
        autoFocus={autoFocus}
        type={type}
        fullWidth={fullWidth}
        placeholder={placeholder}
        value={value}
        onChange={handleValueChange}
        required={required}
        disabled={disabled}
        onKeyDown={onKeyDown}
      />
    </div>
  );
}
