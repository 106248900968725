import React from "react";
// Components
import { Grid } from "@mui/material";
import { DropDown } from "components/UI";
import { API_LOG_MONITORING } from "shared/constants";

export default function CriterialBar(props) {
  const {
    searchForm,
    setSearchForm,
    datalist: { moduleData, functionData, logStatusData },
  } = props;

  return (
    <React.Fragment>
      <Grid item xs={3} style={{ display: "flex" }}>
        <strong style={{ margin: "auto 0px", width: "90px" }}>Module : </strong>
        <DropDown
          id="select_moduleId"
          value={searchForm?.moduleId}
          onChange={(e) =>
            setSearchForm((prev) => ({
              ...prev,
              [API_LOG_MONITORING.MODULE_ID]: e.target.value,
            }))
          }
          menu={moduleData?.map((val) => ({
            key: val.moduleId,
            value: val.moduleName,
          }))}
          autoFocus={true}
          defaultValue=""
          placeholder="<All>"
        />
      </Grid>
      <Grid item xs={5} style={{ display: "flex" }}>
        <strong style={{ margin: "auto 10px", width: "80px" }}>
          Function :
        </strong>
        <DropDown
          id="select_function"
          value={searchForm?.functionId}
          onChange={(e) =>
            setSearchForm((prev) => ({
              ...prev,
              [API_LOG_MONITORING.FUNCTION_ID]: e.target.value,
            }))
          }
          menu={functionData?.map((val) => ({
            key: val.funtionId,
            value: val.funtionName,
          }))}
          placeholder="<All>"
          defaultValue=""
        />
      </Grid>
      <Grid item xs={3} style={{ display: "flex" }}>
        <strong style={{ margin: "auto 10px", width: "80px" }}>Status :</strong>
        <DropDown
          id="select_status"
          value={searchForm.statusId}
          onChange={(e) =>
            setSearchForm((prev) => ({
              ...prev,
              [API_LOG_MONITORING.STATUS_ID]: e.target.value,
            }))
          }
          menu={logStatusData?.map((val) => ({
            key: val.cd,
            value: val.value,
          }))}
          placeholder="<All>"
          defaultValue=""
        />
      </Grid>
    </React.Fragment>
  );
}
