import { Grid } from "@mui/material";
import { DatePickerField, DropDown } from "components/UI";
import { FORM_DEFAULT_R_PACKAGE_CUSTOMS } from "pages/Report/constants/constants";
import React, { useEffect, useState } from "react";
import ButtonAction from "../ButtonAction";
import {
  useExcelDownloadOnlineMutation,
  useGetAvailableStatusListQuery,
  useGetDestinationCompanyListQuery,
  useGetDestinationCountryListQuery,
  useGetNumOfRecordListQuery,
  useGetSourceCompanyListQuery,
  useGetSourceCountryListQuery,
  useReportCommonGetRPackageOwnerQuery,
  useReportCommonGetRPackageTypeQuery,
} from "shared/services/report";
import { userProfile } from "constant";
import { responseErrors } from "utils";
import { useLoadingContext } from "shared/contexts/LoadingContext";
import { waitAndDownloadExcel } from "shared/services/common-dowload/download-helper-service";
import { useSelector } from "react-redux";

export default function RpackageCustomsBalanceReport(props) {
  const userInfo = useSelector(state => state.auth.user);
  const [form, setForm] = useState(FORM_DEFAULT_R_PACKAGE_CUSTOMS);
  const { data: rPkgOwnerList } = useReportCommonGetRPackageOwnerQuery();
  const { data: rPkgTypeList } = useReportCommonGetRPackageTypeQuery(form.rPkgTypeOwner);
  const { data: destinationList } = useGetDestinationCountryListQuery();
  const { data: sourceList } = useGetSourceCountryListQuery();
  const { data: statusList } = useGetAvailableStatusListQuery();
  const { data: numList } = useGetNumOfRecordListQuery();
  const { data: sourceCompanyList } = useGetSourceCompanyListQuery(form?.sourceCountryId);
  const { data: destinationCompanyList } = useGetDestinationCompanyListQuery(form?.destinationCountry);

  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(false);
  const generateReport = useExcelDownloadOnlineMutation();
  const { startLoading, stopLoading } = useLoadingContext();
  const handleDownload = async () => {
    try {
      props.setMsgError([]);
      props.setMsgAlert([]);
      const body = {
        userId: userInfo.userName,
        displayName: userInfo.displayName,
        dataOwner: userProfile.dataOwner,
        loginUserCompanyAbbr: userInfo.companyAbbr,
        rPackageOwner: form?.rPkgTypeOwner,
        rPackageType: form?.rPkgType,
        loadDataFrom: form?.loadDate,
        destinationCountry: form?.destinationCountry,
        sourceCountry: form?.sourceCountryId,
        destinationCompany: form?.destinationCompany,
        sourceCompany: form?.sourceCompanyId,
        customsBalanceStatus: form?.statusId,
        reportRecordsNumber: form?.numberOfRecords,
      };
      const pathReport = "generate-r-package-customs-balance-report";
      startLoading();
      const response = await generateReport(body, pathReport);
      waitAndDownloadExcel(60, response.data, props.setMsgError, stopLoading);
      setDisabledSubmitBtn(false);
      return;
    } catch (error) {
      const msgError = responseErrors(error);
      props.setMsgError(msgError);
      setDisabledSubmitBtn(false);
      stopLoading();
    }
  };

  useEffect(() => {
    document.getElementById("select-rpackage-owner").focus();
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={5} style={{ display: "flex", padding: "4px 0" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>*Report : </strong>
          <DropDown
            id="select-report"
            fullWidth
            required
            defaultValue=""
            placeholder="<Select>"
            menu={[{ key: "1", value: "R-Package Customs Balance Report" }]}
            value={form?.reportId}
            disabled
          />
        </Grid>
        <Grid item xs={7} />
        <Grid item xs={5} style={{ display: "flex", padding: "4px 0" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>*R-Package Owner : </strong>
          <DropDown
            id="select-rpackage-owner"
            fullWidth
            required
            value={form?.rPkgTypeOwner}
            defaultValue=""
            placeholder="<Select>"
            // autoFocus={true}
            menu={rPkgOwnerList?.map(val => ({
              key: val.rPkgOwnerCompAbbr,
              value: val.rPkgOwnerCompAbbr,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                rPkgTypeOwner: e.target.value,
                rPkgType: "",
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", padding: "4px 0" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>*R-Package Type : </strong>
          <DropDown
            id="select-rpackage-type"
            fullWidth
            value={form?.rPkgType}
            defaultValue=""
            placeholder="<Select>"
            // autoFocus={true}
            menu={rPkgTypeList?.map(val => ({
              key: val.rPkgType,
              value: val.rPkgType,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                rPkgType: e.target.value,
              }))
            }
            disabled={!form.rPkgTypeOwner?.length}
            required
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", padding: "4px 0" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>Destination Country : </strong>
          <DropDown
            id="select-destination-country"
            fullWidth
            value={form?.destinationCountry}
            defaultValue=""
            placeholder="<All>"
            // autoFocus={true}
            menu={destinationList?.map(val => ({
              key: val.cd,
              value: val.value,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                destinationCountry: e.target.value,
                destinationCompany: "",
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", padding: "4px 0" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>Destination Company : </strong>
          <DropDown
            id="select-destination-company"
            fullWidth
            value={form?.destinationCompany}
            defaultValue=""
            placeholder="<All>"
            // autoFocus={true}
            menu={destinationCompanyList?.map(val => ({
              key: val.companyAbbr,
              value: val.companyAbbr,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                destinationCompany: e.target.value,
              }))
            }
            disabled={!form?.destinationCountry?.length}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", padding: "4px 0" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>Source Country : </strong>
          <DropDown
            id="select-source-country"
            fullWidth
            value={form?.sourceCountryId}
            defaultValue=""
            placeholder="<All>"
            // autoFocus={true}
            menu={sourceList?.map(val => ({
              key: val.cd,
              value: val.value,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                sourceCountryId: e.target.value,
                sourceCompanyId: "",
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", padding: "4px 0" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>Source Company : </strong>
          <DropDown
            id="select-source-company"
            fullWidth
            value={form?.sourceCompanyId}
            defaultValue=""
            placeholder="<All>"
            // autoFocus={true}
            menu={sourceCompanyList?.map(val => ({
              key: val.companyAbbr,
              value: val.companyAbbr,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                sourceCompanyId: e.target.value,
              }))
            }
            disabled={!form?.sourceCountryId?.length}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={4} style={{ display: "flex", padding: "4px 0" }}>
          <strong style={{ margin: "auto 0px", width: "338px" }}>Load Date (From) : </strong>
          <DatePickerField
            id="input-load-date"
            className="input-load-date"
            views={["day", "month", "year"]}
            format="DD/MM/YYYY"
            mask="DD/MM/YYYY"
            fullWidth={true}
            value={form?.loadDate}
            onChange={e =>
              setForm(old => ({
                ...old,
                loadDate: e,
              }))
            }
          />
        </Grid>
        <Grid item xs={2} style={{ margin: "auto 0" }}>
          (DD/MM/YYYY)
        </Grid>
        <Grid item xs={5} style={{ display: "flex", padding: "4px 0" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>Status : </strong>
          <DropDown
            id="select-status"
            fullWidth
            value={form?.statusId}
            defaultValue=""
            placeholder="<All>"
            // autoFocus={true}
            menu={statusList?.map(val => ({
              key: val.cd,
              value: val.value,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                statusId: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", padding: "4px 0" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>Number of Records : </strong>
          <DropDown
            id="select-number-of-records"
            fullWidth
            value={form?.numberOfRecords}
            defaultValue=""
            placeholder="<All>"
            // autoFocus={true}
            menu={numList?.map(val => ({
              key: val.cd,
              value: val.value,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                numberOfRecords: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />

        <ButtonAction
          handleDownload={handleDownload}
          setFrom={setForm}
          defaultFrom={FORM_DEFAULT_R_PACKAGE_CUSTOMS}
          disabled={disabledSubmitBtn}
          setDisabled={setDisabledSubmitBtn}
          clearMsg={() => {
            props.setMsgAlert([]);
            props.setMsgError([]);
          }}
          firstField={"select-rpackage-owner"}
        />
      </Grid>
    </>
  );
}
