import React, { Fragment } from "react";
import { Grid } from "@mui/material";
import { CheckBox, DropDown, InputButton, Typography } from "components/UI";
// Utils
import { responseErrors, messageDisplay, responseSuccess } from "utils";
import { formatColForCreateBookmark } from "../hooks/formatCol";
// Constant
import { API_EXCEL, BUTTON_VALUE } from "shared/constants";
import { DEFAULT_EXCEL_SCREEN } from "../constants/excelDownload.constant";
import { MSG_TYPE } from "state/enum";
// Service
import {
  useExcelDownloadSchemaTableMutation,
  useCreateExcelDownloadBookmarksMutation,
} from "shared/services/common/monitoring";
import { useSelector } from "react-redux";

export default function FilterBar(props) {
  const {
    refetch,
    dataList: { tableViewList, bookMarkList },
    form,
    rows,
    setRows,
    setForm,
    setOnSearch,
    setMsg: { setMsgError, setMsgAlert },
    setTable: { setRowModesModel, setRowSelectionModel, rowSelectionModel },
  } = props;
  const userInfo = useSelector(state => state.auth.user);
  const role = useSelector(state => state.auth.role);
  const searchSchema = useExcelDownloadSchemaTableMutation();
  const createMark = useCreateExcelDownloadBookmarksMutation();
  const handleChangeTableName = async event => {
    try {
      event.preventDefault();
      setMsgError([]);
      setMsgAlert([]);
      setOnSearch(false);
      setRowModesModel({});
      setRowSelectionModel([]);
      setForm({
        [API_EXCEL.BOOK_MARKS]: "",
        [API_EXCEL.REPORT_NAME]: "",
        [API_EXCEL.REPORT_TITLE]: "",
        [API_EXCEL.START_ROW]: "",
        [API_EXCEL.START_COLUMN]: "",
        //
        [API_EXCEL.TABLE_NAME]: event.target.value,
        [API_EXCEL.BIG_DATA]: true,
        [API_EXCEL.SAVE_BOOK_MARK]: false,
        [API_EXCEL.DATA_OWNER]: form?.dataOwner,
        [API_EXCEL.NAME]: form?.name,
        [API_EXCEL.EMAIL]: form?.email,
        [API_EXCEL.ROLE_ID]: form?.roleId,
      });
      const body = {
        [API_EXCEL.DATA_OWNER]: form?.dataOwner,
        [API_EXCEL.TABLE_NAME]: event.target.value,
        [API_EXCEL.SETTING_ID]: "",
        [API_EXCEL.USER]: {
          [API_EXCEL.USER_INFO]: { [API_EXCEL.NAME]: form?.name },
          [API_EXCEL.ROLE]: form?.roleId,
        },
      };
      if (!body?.tableName) {
        return;
      }
      const searchData = await searchSchema(body);

      const data =
        searchData?.result?.columns?.map((item, index) => ({
          [API_EXCEL.ROW_NUMBER]: index + 1,
          [API_EXCEL.FIELD]: item?.field,
          [API_EXCEL.DISPLAY_NAME]: item?.displayName,
          [API_EXCEL.ORDER_DISPLAY]: item?.ordDisp,
          [API_EXCEL.PK]: item?.pk,
          [API_EXCEL.DATA_TYPE]: item?.dataType,
          [API_EXCEL.CRITERIA]: item?.criteria,
          [API_EXCEL.LOGICAL_OPERATION]: item?.logOpr,
          [API_EXCEL.DISPLAY_OPTION]: !item?.dispOpt ? true : item?.dispOpt,
          [API_EXCEL.SORT]: item?.[API_EXCEL.SORT],
        })) ?? [];

      setRows(data);
      setOnSearch(true);

      // refetch bookmark
      refetch();
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      return;
    }
  };
  const handleChangeBookMark = async event => {
    try {
      event.preventDefault();
      setMsgError([]);
      setMsgAlert([]);
      setOnSearch(false);
      setRowModesModel({});
      setRowSelectionModel([]);
      setForm(prev => ({
        ...prev,
        [API_EXCEL.BOOK_MARKS]: event.target.value,
      }));
      const body = {
        [API_EXCEL.DATA_OWNER]: form?.dataOwner,
        [API_EXCEL.TABLE_NAME]: form?.tableName,
        [API_EXCEL.SETTING_ID]: event.target.value,
        [API_EXCEL.USER]: {
          [API_EXCEL.USER_INFO]: { [API_EXCEL.NAME]: form?.name },
          [API_EXCEL.ROLE]: form?.roleId,
        },
      };
      if (!body?.tableName) {
        return;
      }
      const searchData = await searchSchema(body);

      const data =
        searchData?.result?.columns?.map((item, index) => ({
          [API_EXCEL.ROW_NUMBER]: index + 1,
          [API_EXCEL.FIELD]: item?.field,
          [API_EXCEL.DISPLAY_NAME]: item?.displayName,
          [API_EXCEL.ORDER_DISPLAY]: item?.ordDisp,
          [API_EXCEL.PK]: item?.pk,
          [API_EXCEL.DATA_TYPE]: item?.dataType,
          [API_EXCEL.CRITERIA]: item?.criteria,
          [API_EXCEL.LOGICAL_OPERATION]: item?.logOpr,
          [API_EXCEL.DISPLAY_OPTION]: !item?.dispOpt ? true : item?.dispOpt,
          [API_EXCEL.SORT]: item?.[API_EXCEL.SORT],
        })) ?? [];

      setRows(data);
      setOnSearch(true);
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      return;
    }
  };
  const handleClearBtn = () => {
    setMsgError([]);
    setMsgAlert([]);
    setForm({
      ...DEFAULT_EXCEL_SCREEN,
      [API_EXCEL.NAME]: userInfo.userName,
      [API_EXCEL.EMAIL]: userInfo.mail,
      [API_EXCEL.ROLE_ID]: role[0],
    });
    setOnSearch(false);
    setRows([]);
    setRowModesModel({});
    setRowSelectionModel([]);
    document.getElementById("select-table-view").focus();
  };
  const handleSaveMark = async e => {
    try {
      e.preventDefault();
      setMsgError([]);
      setMsgAlert([]);
      let msg;
      setForm(prev => ({
        ...prev,
        [API_EXCEL.SAVE_BOOK_MARK]: e.target.checked,
      }));
      if (!rows.length) {
        msg = messageDisplay({
          type: MSG_TYPE.ERROR,
          code: "MSTD0063AERR",
          msg: [""],
        });
        setMsgError([msg]);
        setForm(prev => ({
          ...prev,
          [API_EXCEL.SAVE_BOOK_MARK]: false,
        }));
        return;
      }

      const edited = formatColForCreateBookmark(rows, rowSelectionModel);
      const body = {
        [API_EXCEL.DATA_OWNER]: form?.dataOwner,
        [API_EXCEL.TABLE_NAME]: form?.tableName,
        [API_EXCEL.SETTING_ID]: form?.bookMasks,
        [API_EXCEL.REPORT_NAME]: form?.reportName,
        [API_EXCEL.REPORT_TITLE]: form?.reportTitle,
        [API_EXCEL.START_COLUMN]: form?.startColumn,
        [API_EXCEL.START_ROW]: form?.startRow,
        [API_EXCEL.BIG_DATA]: form?.bigData ? "1" : "0",
        [API_EXCEL.COLUMNS]: edited,
        [API_EXCEL.DATA_OWNER]: form?.dataOwner,
        [API_EXCEL.USER]: {
          [API_EXCEL.USER_INFO]: {
            [API_EXCEL.NAME]: form?.name,
            [API_EXCEL.EMAIL]: form?.mail,
          },
          [API_EXCEL.ROLE]: [
            {
              [API_EXCEL.ROLE_ID]: form?.roleId,
            },
          ],
        },
      };
      const response = await createMark(body);
      // refetch bookmark
      refetch();
      msg = responseSuccess(response);
      setMsgAlert([msg]);
      return;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };
  return (
    <Fragment>
      <Grid container spacing={1} sx={{ alignItems: "center" }}>
        <Typography
          content="*Table / View: "
          sx={{ color: "#000", fontWeight: "bold", fontFamily: "Kanit", pr: 2 }}
          noWrap={false}
        />
        <Grid item xs={3}>
          <DropDown
            name="tableName"
            id="select-table-view"
            required
            value={form?.tableName}
            onChange={handleChangeTableName}
            menu={tableViewList?.map(val => ({
              key: val.tableName,
              value: val.tableName,
            }))}
            disabled={false}
            defaultValue=""
            placeholder="<Select>"
          />
        </Grid>
        <Typography
          content="Bookmarks: "
          sx={{
            color: "#000",
            fontFamily: "Kanit",
            fontWeight: "bold",
            mr: 2,
            ml: 2,
          }}
          noWrap={false}
        />
        <Grid item xs={3}>
          <DropDown
            id="select-book-marks"
            value={form?.bookMasks}
            onChange={handleChangeBookMark}
            menu={bookMarkList?.map(val => ({
              key: val.settingId,
              value: val.settingId,
            }))}
            disabled={false}
            defaultValue=""
            placeholder=""
          />
        </Grid>
        <Grid item xs={2} sx={{ display: "flex", flexFlow: "row wrap" }}>
          <CheckBox
            id="checkbox-save-book-mark"
            placement="end"
            label="Save Bookmarks"
            sx={{
              color: "#000",
              fontFamily: "Kanit",
              fontWeight: "bold",
              ml: 1,
            }}
            checked={form?.saveBookMask}
            onChange={handleSaveMark}
          />
        </Grid>
        <Grid item xs={1}>
          <InputButton id="button-clear" value={BUTTON_VALUE.CLEAR} disabled={false} onClick={handleClearBtn} />
        </Grid>
      </Grid>
    </Fragment>
  );
}
