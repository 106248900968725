import React, { Fragment, useMemo, useState } from "react";
import { Box, Grid } from "@mui/material";
import { GridRowModes, useGridApiContext } from "@mui/x-data-grid";
import { InputEditTableCurrency, InputEditTableDropDown, InputEditTableText } from "components/UI";
import { userProfile } from "constant";
import { useConfirmDialogContext } from "context/confirmDialogContext";
import DataTable from "pages/DataTable";
import ActionBar from "pages/Partials/ActionBar";
import SubmitBar from "pages/Partials/SubmitBar";
import {
  useGetRpagkageInTable,
  useSearchOtherMaterials,
  useRPkgMasterOtherDownloadExcelMutation,
  useGetMakerAll,
} from "service/rpackagemaster";
import { MessageType, ModeAction } from "state/enum";
import { apiClient, formatCurrency, messageTypeDisplay, responseDownloadFileErrors, responseErrors } from "utils";
import { validationRequestErrors, validationSearchForm } from "utils/validation";
import { FIRST_PAGE } from "shared/constants";
import { useSelector } from "react-redux";

const OtherMaterialsTable = ({
  searchForm,
  setSearchForm,
  setMsgError,
  setMsgAlert,
  setOnSearch,
  onSearch,
  mode,
  setMode,
  refetchTypeData,
  refetchMakerData,
}) => {
  const userInfo = useSelector(state => state.auth.user);
  const confirmDialogCtx = useConfirmDialogContext();

  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [onSearchAdd, setOnSearchAdd] = useState(false);
  const downloadExcel = useRPkgMasterOtherDownloadExcelMutation();
  // Get Search Criterial
  const getSearchCriterial = () => {
    try {
      return {
        dataOwner: userProfile?.dataOwner,
        rPkgTypeId: searchForm?.rPkgTypeId,
        makerPlantId: searchForm.makerPlantId,
        poNo: searchForm.poNo,
        firstReceiveDtFrom: searchForm.firstReceiveDtFrom,
        firstReceiveDtTo: searchForm.firstReceiveDtTo,
        pageNumber: 1,
        rowsPerPage: 10,
      };
    } catch (error) {
      console.error(error);
    }
  };
  // 1. pagination
  const [pagination, setPagination] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const handleChangePagination = async (event, value) => {
    setMsgError([]);
    setMsgAlert([]);
    setPageNumber(value);
    await reload(value);
  };

  const reload = async pageNumber => {
    try {
      const searchData = await searchDataAsync({
        ...getSearchCriterial(),
        pageNumber: pageNumber,
        rowsPerPage: 10, // TODO: Forced Value
      });

      const data =
        searchData?.result?.data?.map((item, index) => ({
          rowNumber: item.rowNumber,
          ...item,
        })) ?? [];
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);

      setRows(data);
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };

  // 2. api
  const { mutateAsync: searchDataAsync } = useSearchOtherMaterials({
    dataOwner: userProfile.dataOwner,
  });

  const { data: rPackageTypeData } = useGetRpagkageInTable({
    dataOwner: userProfile.dataOwner, // TODO: get dataOwner from profile
    rPkgOwnerCompAbbr: userProfile.dataOwner, // TODO: get dataOwner from profile
    cd: searchForm?.rPkgCategoryId,
  });

  const { data: makerAllData } = useGetMakerAll({
    dataOwner: userProfile.dataOwner, // TODO: get dataOwner from profile
    cd: searchForm?.rPkgCategoryId,
  });

  // 4. columns
  const columns = useMemo(
    () => [
      {
        field: "rPkgTypeId",
        editable: true,
      },
      {
        field: "makerPlantId",
        editable: true,
      },
      {
        field: "rowNumber",
        sortable: false,
        headerName: "No",
        headerAlign: "center",
        align: "right",
        width: 50,
        editable: false,
        renderCell: params => {
          if (params.row.rowNumber === rows.slice(-1)?.[0].rowNumber && ModeAction.ADD === mode) {
            return <div></div>;
          }
          return params.value;
        },
      },
      {
        field: "rPkgType",
        // field: "rPkgTypeId",
        sortable: false,
        headerName: "R-Package Type",
        headerAlign: "center",
        minWidth: 120,
        editable: mode === ModeAction.ADD,
        rule: [
          {
            type: MessageType.EMPTY,
            key: ["R-Package Type"],
          },
        ],
        renderEditCell: params => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const apiRef = useGridApiContext();

          return (
            <InputEditTableDropDown
              {...params}
              autoFocus
              required
              setValue={value => {
                apiRef.current.setEditCellValue({
                  id: params.id,
                  field: "rPkgTypeId",
                  value: value,
                });
              }}
              memu={rPackageTypeData?.map(val => ({
                key: val.rPkgTypeId,
                value: val.rPkgType,
              }))}
              placeholder="<Select>"
              onSearchAdd={onSearchAdd}
            />
          );
        },
        flex: 3,
      },
      {
        field: "makerImpExpCd",
        sortable: false,
        headerName: "Other Material Maker",
        headerAlign: "center",
        type: "select",
        minWidth: 120,
        editable: mode === ModeAction.ADD,
        rule: [
          {
            type: MessageType.EMPTY,
            key: ["Other Material Maker"],
          },
        ],
        renderEditCell: params => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const apiRef = useGridApiContext();
          // console.log(`renderEditCell ${params.row.makerPlantId}`)
          return (
            <InputEditTableDropDown
              {...params}
              required
              setValue={value => {
                apiRef.current.setEditCellValue({
                  id: params.id,
                  field: "makerPlantId",
                  value: value,
                });
              }}
              memu={makerAllData?.map(val => ({
                key: val.plantId,
                value: val.impExpCd,
              }))}
              placeholder="<Select>"
              onSearchAdd={onSearchAdd}
            />
          );
        },
        flex: 4,
      },
      {
        field: "poNo",
        sortable: false,
        headerName: "P/O Number",
        headerAlign: "center",
        minWidth: 150,
        editable: mode === ModeAction.ADD,
        renderEditCell: params => {
          return (
            <InputEditTableText
              regularExp={/^[a-zA-Z0-9_]*$/}
              maxLength={20}
              {...params}
              style={{ width: "220px" }}
              upperCase
            />
          );
        },
        flex: 4,
      },
      {
        field: "qty",
        sortable: false,
        headerName: "Qty",
        editable: true,
        minWidth: 120,
        headerAlign: "center",
        align: "right",
        rule: [
          {
            type: MessageType.EMPTY,
            key: ["Qty"],
          },
          {
            type: MessageType.MORETHAN_ZERO,
            key: ["Qty", "0"],
          },
        ],
        renderEditCell: params => {
          return (
            <InputEditTableCurrency
              autoFocus={mode === ModeAction.EDIT}
              {...params}
              required
              decimalScale={0}
              maxLimit={1000000}
            />
          );
        },
        renderCell: params => {
          return <p>{formatCurrency({ number: params.value, digits: 0 })}</p>;
        },
        flex: 3,
      },
      {
        field: "firstReceiveDt",
        sortable: false,
        headerName: "First Received Date (DD/MM/YYYY)",
        minWidth: 150,
        editable: false,
        align: "center",
        headerAlign: "center",
        flex: 3,
      },
    ],
    [rows]
  );

  const getSearch = async () => {
    const body = {
      dataOwner: userProfile.dataOwner,
      rPkgTypeId: searchForm.rPkgTypeId,
      makerPlantId: searchForm.makerPlantId,
      poNo: searchForm.poNo,
      firstReceiveDtFrom: searchForm.firstReceiveDtFrom,
      firstReceiveDtTo: searchForm.firstReceiveDtTo,
      pageNumber: 1,
      rowsPerPage: 10,
    };
    const searchData = await searchDataAsync(body);

    const data =
      searchData?.result?.data?.map((item, index) => ({
        rowNumber: item.rowNumber,
        ...item,
      })) ?? [];
    setOnSearch(true);

    return { searchData, data };
  };
  const handleSearch = async e => {
    try {
      e.preventDefault();
      setMsgError([]);
      setMsgAlert([]);
      setRows([]);
      setRowSelectionModel([]);
      setOnSearch(false);
      setOnSearchAdd(true);
      const { searchData, data } = await getSearch();
      const { isSuccess, errors } = validationSearchForm({
        rule: [
          {
            data: data,
            type: MessageType.NOT_FOUND,
          },
        ],
      });

      if (!isSuccess) {
        setMsgError(errors);
        setOnSearchAdd(false);
        window.scrollTo(0, 0);
        return;
      }

      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };

  // 9. handleClear
  const handleClear = () => {
    setMode(ModeAction.VIEW);
    setMsgError([]);
    setMsgAlert([]);
    setRowSelectionModel([]);
    setRowModesModel({});
    setPagination({});
    setPageNumber(FIRST_PAGE);
    setOnSearch(false);
    setOnSearchAdd(false);
    setSearchForm(prev => ({
      ...prev,
      // rPkgCategoryId: "", Remain only Type
      rPkgTypeId: "",
      poNo: "",
      barcodeId: "",
      rMdMaker: "",
      location: "",
      makerPlantId: "",
      locationId: "",
      firstReceiveDtFrom: "",
      firstReceiveDtTo: "",
    }));
    setRows([]);
  };

  // 10. handleDownloadExcel
  const handleDownloadExcel = async () => {
    // validate
    setMsgError([]);
    setMsgAlert([]);
    try {
      const { data } = await getSearch();
      if (!data.length) {
        const msg = messageTypeDisplay(MessageType.NOT_FOUND);
        setMsgError(old => [...old, msg]);
        return;
      }
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
    try {
      //Try to search first if not data or any error should not download file
      const data = await getSearchCriterial();

      const { isSuccess, errors } = validationSearchForm({
        rule: [
          {
            data: data,
            type: MessageType.NOT_FOUND,
          },
        ],
      });

      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
      await downloadExcel(data);
      return;
    } catch (error) {
      const errors = responseDownloadFileErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };

  const handleDeleteClick = async () => {
    setMsgError([]);
    setMsgAlert([]);
    const confirm = await confirmDialogCtx.success({ type: "confirmDelete" });
    if (!confirm) {
      return;
    }

    try {
      const selectedNo = rowSelectionModel[0];
      const id = rows.find(v => v.rowNumber === selectedNo)?.rOthMatId;
      const updateDt = rows.find(v => v.rowNumber === selectedNo)?.updateDt;

      await apiClient.delete(`/other-materials/delete/${id}`, {
        data: {
          updateBy: userInfo.userName,
          updateDt: updateDt,
        },
      });

      await reload(pageNumber);
      refetchMakerData();
      setMode(ModeAction.VIEW);
      setRowSelectionModel([]);

      const msg = messageTypeDisplay(MessageType.DELETED);
      setMsgAlert([msg]);
      window.scrollTo(0, 0);
      refetchTypeData();
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };
  const handleCopyClick = () => {
    setMode(ModeAction.ADD);
    setMsgError([]);
    setMsgAlert([]);
    setOnSearch(true);

    const rowCopy = rows.find(v => v.rowNumber === rowSelectionModel[0]);
    const maxNo = !rows || rows.length === 0 ? 1 : Math.max(...rows.map(item => item.rowNumber));

    setRows(oldRows => [
      ...oldRows,
      {
        rowNumber: maxNo + 1,
        makerImpExpCd: rowCopy.makerPlantId, // Key of makerImpExpCd is makerPlantId [Combobox]
        makerPlantId: rowCopy.makerPlantId ?? "",
        rPkgTypeId: rPackageTypeData?.find(o => o.rPkgTypeId === rowCopy.rPkgTypeId) ? rowCopy.rPkgTypeId : "",
        rPkgType: rowCopy.rPkgTypeId, // Key of rPkgType is rPkgTypeId [Combobox]
        poNo: rowCopy.poNo,
        qty: rowCopy.qty,
        isNew: true,
      },
    ]);

    setRowModesModel(oldModel => ({
      ...oldModel,
      [maxNo + 1]: { mode: GridRowModes.Edit },
    }));

    setRowSelectionModel([maxNo + 1]);
  };
  const processRowUpdate = async newRow => {
    setMsgError([]);
    setMsgAlert([]);

    const { isSuccess, errors } = validationRequestErrors({
      columns: columns,
      data: newRow,
    });

    if (!isSuccess) {
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }

    if (newRow.isNew) {
      try {
        await apiClient.post(`/other-materials/create`, {
          dataOwner: userProfile.dataOwner, // TODO: get dataOwner from profile
          rPkgTypeId: rPackageTypeData?.find(o => o.rPkgTypeId === newRow.rPkgTypeId) ? newRow.rPkgTypeId : "",
          makerPlantId: newRow.makerPlantId ?? "",
          poNo: newRow.poNo,
          qty: parseInt(newRow.qty.replace(/,/g, "")),
          createBy: userInfo.userName,
        });

        const msg = messageTypeDisplay(MessageType.ADDED);
        setMsgAlert([msg]);
        window.scrollTo(0, 0);
        refetchTypeData();
      } catch (error) {
        const errors = responseErrors(error);
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
    } else {
      try {
        await apiClient.patch(`/other-materials/edit/${newRow.rOthMatId}`, {
          dataOwner: userProfile.dataOwner, // TODO: get dataOwner from profile
          rPkgTypeId: rPackageTypeData?.find(o => o.rPkgTypeId === newRow.rPkgTypeId) ? newRow.rPkgTypeId : "",
          makerPlantId: newRow.makerPlantId ?? "",
          poNo: newRow.poNo,
          qty: parseInt(newRow.qty.replace(/,/g, "")),
          updateBy: userInfo.userName,
          updateDt: newRow.updateDt,
        });

        const msg = messageTypeDisplay(MessageType.UPDATED);
        setMsgAlert([msg]);
        window.scrollTo(0, 0);
      } catch (error) {
        const errors = responseErrors(error);
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
    }

    setMode(ModeAction.VIEW);
    setRowSelectionModel([]);
    setRowModesModel({});
    setRows([]);
    await reload(pageNumber);
    refetchMakerData();
    const updatedRow = { ...newRow, isNew: false };
    return updatedRow;
  };

  return (
    <Fragment>
      <>
        <ActionBar
          mode={mode}
          rows={rows}
          columns={columns}
          setRows={setRows}
          setMode={setMode}
          setMsgError={setMsgError}
          setMsgAlert={setMsgAlert}
          onSearch={onSearch}
          setOnSearch={setOnSearch}
          setRowModesModel={setRowModesModel}
          setRowSelectionModel={setRowSelectionModel}
          rowSelectionModel={rowSelectionModel}
          rowModesModel={rowModesModel}
          handleSearch={handleSearch}
          handleClear={handleClear}
          handleDownloadExcel={handleDownloadExcel}
          handleDeleteClick={handleDeleteClick}
          handleCopyClick={handleCopyClick}
          firstField={"select_rPkgCategoryId"}
          functionId={"WDN91020"}
        />
        <Box sx={{ padding: "1rem" }}>
          <Grid>
            {onSearch && (
              <DataTable
                mode={mode}
                onSearch={onSearch}
                rowSelectionModel={rowSelectionModel}
                setRowSelectionModel={setRowSelectionModel}
                setMode={setMode}
                rows={rows}
                rowModesModel={rowModesModel}
                columns={columns}
                setRowModesModel={setRowModesModel}
                processRowUpdate={processRowUpdate}
                pagination={pagination}
                pageNumber={pageNumber}
                handleChangePagination={handleChangePagination}
                columnGroupingModel={null}
                columnVisibilityModel={{
                  rPkgTypeId: false,
                  makerPlantId: false,
                }}
              />
            )}
            <SubmitBar
              mode={mode}
              rows={rows}
              setMode={setMode}
              setRows={setRows}
              setRowModesModel={setRowModesModel}
              setMsgError={setMsgError}
              rowModesModel={rowModesModel}
              rowSelectionModel={rowSelectionModel}
              setRowSelectionModel={setRowSelectionModel}
              functionId={"WDN91020"}
            />
          </Grid>
        </Box>
      </>
    </Fragment>
  );
};

export default OtherMaterialsTable;
