import React, { useState, useEffect, Fragment } from "react";
import { Grid, Divider } from "@mui/material";
//UTIL
import { messageDisplay, responseDownloadFileErrors, responseErrors, responseSuccess } from "utils";
import transformResponse from "../hooks/transformData";
// COMPONENT
import { InputButton, LinkFile } from "components/UI";
import ActionTableBar from "./ActionTableBar";
// Hooks
import useLocalStorage from "shared/hooks/useLocalStorage";
import { useButtonMode } from "../hooks/useButtonMode";
import { useConfirmDialogContext } from "context/confirmDialogContext";
// TYPE
import { ModeAction, MSG_TYPE } from "state/enum";
import {
  API_MONTHLY,
  PAGINATION,
  BUTTON_VALUE,
  TEMPLATE_FILE_CODE,
  FIRST_PAGE,
  DEFAULT_LOCAL_STATE,
} from "shared/constants";
import { TEMPLATE_NAME } from "../constants/column.constant";
import { userProfile } from "constant";
//API
import {
  useMonthlyActivateMutation,
  useMonthlySearchMutation,
  useDownloadTemplateMutation,
} from "shared/services/inventory-forecast";
import { useLoadingContext } from "shared/contexts/LoadingContext";
import { useSelector } from "react-redux";
export default function ButtonBar(props) {
  const {
    dataList: {
      uploadStatusList,
      modeList,
      // dataTypeList: dataTypeList,
    },
    form,
    setSearchForm,
    getSearch,
    setOnOpen,
    rows,
    setRows,
    columns,
    mode,
    setMode,
    setMsg: { setMsgError, setMsgAlert },
    setOnSearch,
    rowModesModel,
    setRowModesModel,
    rowSelectionModel,
    setRowSelectionModel,
    setPagination,
    pageNumber,
    setPageNumber,
    functionId,
    setOnSearchAdd,
  } = props;
  const userInfo = useSelector(state => state.auth.user);

  const [logDetail, setLogDetail] = useLocalStorage("logDetail");
  const confirmDialogCtx = useConfirmDialogContext();
  const { startLoading, stopLoading } = useLoadingContext();
  // Button
  const [activateForecastButton, setActivateForecastButton] = useState(false);
  const [searchButton, setSearchButton] = useState(false);
  const [clearButton, setClearButton] = useState(false);
  const [addBtn, setAddBtn] = useState(false);
  const [editBtn, setEditBtn] = useState(true);
  const [deleteBtn, setDeleteBtn] = useState(true);

  //? API
  const searchMonthly = useMonthlySearchMutation();
  const monthlyActivate = useMonthlyActivateMutation();
  const exportTemplate = useDownloadTemplateMutation();
  useEffect(() => {
    if (!rows?.length) {
      setAddBtn(false);
      setEditBtn(true);
      setDeleteBtn(true);
    }
  }, [rows]);
  useEffect(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useButtonMode(
      mode,
      setActivateForecastButton,
      setSearchButton,
      setClearButton,
      setAddBtn,
      setEditBtn,
      setDeleteBtn
    );
  }, [mode]);
  // ? HANDLE BUTTON SEARCH
  const handleSearch = async e => {
    try {
      e.preventDefault();
      setMsgError([]);
      setMsgAlert([]);
      // Reset Data of Table
      setPageNumber(FIRST_PAGE);
      setOnSearch(false);
      setOnOpen(false);
      setOnSearchAdd(true);

      setMode(ModeAction.VIEW);
      setRows([]);
      setPagination({});
      setRowSelectionModel([]);
      setRowModesModel({});
      setSearchForm(old => ({
        ...old,
        [API_MONTHLY.FILE]: {},
        [API_MONTHLY.ORG_FILE_NAME]: "",
      }));

      const body = {
        [API_MONTHLY.FORECAST_TYPE_CD]: form?.forecastTypeCd,
        [API_MONTHLY.MODE_CD]: form?.modeCd ? modeList.find(v => v.cd === form.modeCd).value : "",
        [API_MONTHLY.FORECAST_MONTH]: form?.forecastMonth,
        [API_MONTHLY.DATA_TYPE_CD]: form?.dataTypeCd,
        [API_MONTHLY.UPLOAD_STATUS_CD]: form?.uploadStatusCd,
        [API_MONTHLY.UPLOAD_COMPANY_ABBR]: userInfo.companyAbbr,
        [PAGINATION.PAGE_NUMBER]: FIRST_PAGE,
      };
      // const { isSuccess, errors } = validationSearchForm({
      //   data: body,
      //   rule: [
      //     {
      //       field: API_MONTHLY.FORECAST_TYPE_CD,
      //       type: MessageType.EMPTY,
      //       key: ["Forecast Type"],
      //     },
      //     {
      //       field: API_MONTHLY.FORECAST_MONTH,
      //       type: MessageType.MONTH_INVALID_45,
      //       key: ["Forecast Month", "MM/YYYY"],
      //     },
      //     {
      //       field: API_MONTHLY.MODE_CD,
      //       type: MessageType.EMPTY,
      //       key: ["Mode"],
      //     },
      //   ],
      // });
      // if (!isSuccess) {
      //   setMsgError(errors);
      //   setOnSearchAdd(false);
      //   window.scrollTo(0, 0);
      //   return;
      // }
      startLoading();
      const searchData = await searchMonthly(body);
      stopLoading();
      if (!searchData?.result?.data?.length) {
        setMsgError([
          messageDisplay({
            type: MSG_TYPE.ERROR,
            code: "MSTD0059AERR",
            msg: "",
          }),
        ]);
        return;
      }

      const data = transformResponse(searchData?.result?.data);
      setRows(data);
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setOnSearch(true);
    } catch (error) {
      stopLoading();
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      return;
    }
  };
  // ?HANDLE BUTTON CLEAR
  const handleClear = () => {
    setMsgError([]);
    setMsgAlert([]);
    setOnSearchAdd(false);
    setLogDetail(DEFAULT_LOCAL_STATE);
    // reset form
    setSearchForm(prev => ({
      ...prev,
      // [API_MONTHLY.FORECAST_TYPE_CD]: form.forecastTypeCd,
      [API_MONTHLY.FORECAST_MONTH]: "",
      [API_MONTHLY.MODE_CD]: "",
      [API_MONTHLY.DATA_TYPE_CD]: "",
      [API_MONTHLY.UPLOAD_STATUS_CD]: "",
      [API_MONTHLY.FILE_UPLOAD_ID]: "",
      // [API_MONTHLY.DATA_OWNER]: userProfile.dataOwner,
      // [API_MONTHLY.UPLOAD_COMPANY_ABBR]: userInfo.companyAbbr,
      // [API_MONTHLY.USER_ID]: userInfo.userName,
      // [API_MONTHLY.COMPANY_ABBR]: userInfo.companyAbbr,
      [API_MONTHLY.ORG_FILE_NAME]: "",
      [API_MONTHLY.FILE]: {},
    }));

    setMode(ModeAction.VIEW);
    setOnSearch(false);
    setOnOpen(false);
    setRowSelectionModel([]);
    setRowModesModel({});
    setRows([]);
    setPagination({});
    setPageNumber(FIRST_PAGE);
    document.getElementById("input-monthly-mode").focus();
  };

  // ? HANDLE BUTTON ACTIVATE
  const handleActivate = async () => {
    setMsgError([]);
    setMsgAlert([]);

    const activateConfirm = await confirmDialogCtx.success({
      type: "FORECAST_MONTHLY_CONFIRM_ACTIVATE",
    });
    if (!activateConfirm) return;

    try {
      const activateData = {
        [API_MONTHLY.FORECAST_TYPE_CD]: form.forecastTypeCd,
        [API_MONTHLY.MODE_CD]: form.modeCd ? modeList.find(element => element.cd === form.modeCd)?.value : "",
        [API_MONTHLY.FORECAST_MONTH]: form.forecastMonth,
        [API_MONTHLY.DATA_TYPE_CD]: form.dataTypeCd,
        [API_MONTHLY.UPLOAD_STATUS_CD]: form.uploadStatus
          ? uploadStatusList.find(element => element.value === form.uploadStatus).cd
          : "",
        [API_MONTHLY.UPDATE_BY]: form.userId,
        [API_MONTHLY.USER_COMPANY_ABBR]: form.companyAbbr,
      };
      // const { isSuccess, errors } = validationSearchForm({
      //   data: activateData,
      //   rule: [
      //     {
      //       field: API_MONTHLY.FORECAST_MONTH,
      //       type: MessageType.EMPTY,
      //       key: ["Forecast Month"],
      //     },
      //     {
      //       field: API_MONTHLY.MODE_CD,
      //       type: MessageType.EMPTY,
      //       key: ["Mode"],
      //     },
      //   ],
      // });
      // if (!isSuccess) {
      //   setMsgError(errors);
      //   window.scrollTo(0, 0);
      //   return;
      // }
      startLoading();
      await monthlyActivate(activateData);
      stopLoading();
      // const msg = responseSuccess(response);
      const msg = messageDisplay({
        type: MSG_TYPE.INFO,
        code: "MDN92015ACFM",
        msg: ["Activate"],
      });
      setMsgAlert([msg]);
      window.scrollTo(0, 0);
      return;
    } catch (error) {
      stopLoading();
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };

  // ? HANDLE LINK DOWNLOAD FILE TEMPLATE
  const handleDownloadTemplate = async () => {
    setMsgError([]);
    setMsgAlert([]);
    try {
      const infoFile = TEMPLATE_FILE_CODE.find(v => v.value === TEMPLATE_NAME.DELIVERY_PLAN);
      const body = {
        [API_MONTHLY.DATA_OWNER]: userProfile.dataOwner,
        cd: infoFile?.cd,
      };
      startLoading();
      await exportTemplate(body);
      stopLoading();
    } catch (error) {
      stopLoading();
      const err = responseDownloadFileErrors(error);
      setMsgError(err);
      window.scroll(0, 0);
      return;
    }
  };
  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end", flexWrap: "wrap" }}>
          <LinkFile
            id="template-delivery-plan"
            component="button"
            variant="body2"
            value={TEMPLATE_NAME.DELIVERY_PLAN}
            onClick={handleDownloadTemplate}
          />
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end", flexWrap: "wrap" }}>
          <InputButton
            id="btn_activate_forecasting"
            value={"Activate Forecasting"}
            disabled={activateForecastButton}
            onClick={handleActivate}
            name={`${functionId}ActivateForecasting`}
          />
          <InputButton
            id="btn_search"
            value={BUTTON_VALUE.SEARCH}
            disabled={searchButton}
            onClick={handleSearch}
            name={`${functionId}Search`}
          />
          <InputButton
            id="btn_clear"
            value={BUTTON_VALUE.CLEAR}
            disabled={clearButton}
            onClick={handleClear}
            name={`${functionId}Cancel`}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end", flexWrap: "wrap" }}>
          <ActionTableBar
            setSearchForm={setSearchForm}
            form={form}
            modeList={modeList}
            rows={rows}
            setRows={setRows}
            columns={columns}
            setMode={setMode}
            setOnSearch={setOnSearch}
            getSearch={getSearch}
            stateButton={{ addBtn, editBtn, deleteBtn }}
            setMsg={{ setMsgError, setMsgAlert }}
            rowModesModel={rowModesModel}
            setRowModesModel={setRowModesModel}
            rowSelectionModel={rowSelectionModel}
            setRowSelectionModel={setRowSelectionModel}
            pagination={{ pageNumber, setPageNumber, setPagination }}
            functionId={functionId}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}
