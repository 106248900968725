import React from "react";
import MenuItem from "@mui/material/MenuItem";
import { Select, FormControl, InputLabel, styled } from "@mui/material";
import InputBase from "@mui/material/InputBase";

const StyledSelectField = styled(InputBase)(({ theme, error }) => ({
  "& .MuiInputBase-input": {
    border: `1.5px solid ${error ? theme.palette.primary.main : theme.palette.secondary.medium}`,
    padding: "4px 8px",
    "&:focus": {
      border: `1.5px solid black !important`,
      borderRadius: ".3rem",
      color: "#000",
    },
  },
}));

const InputDropDown = ({
  id,
  value,
  name = "",
  memu,
  onChange,
  disabled = false,
  bgColor = "white",
  className = "",
  required,
  placeholder = "<Select>",
  defaultValue = "",
  focus = false,
  rest,
}) => {
  return (
    <FormControl style={{ width: "100%", margin: "4px 0px" }}>
      <InputLabel
        id="test-select-label"
        shrink={false}
        variant="outlined"
        style={{
          top: "50%",
          left: "5%",
          transform: "translateY(-50%)",
        }}
      >
        {!memu
          ? placeholder
          : memu &&
            !memu?.map((item) => item.key).includes(value) &&
            placeholder}
      </InputLabel>
      <Select
        {...rest}
        data-testid={id}
        id={id}
        name={name}
        sx={{ minWidth: "100%", background: bgColor }}
        value={value}
        label=""
        onChange={onChange}
        disabled={disabled}
        className={className}
        required={required}
        placeholder={placeholder}
        autoFocus={focus}
        style={{ fontSize: "12px", border : required ? "1.7px solid" : "1px solid" }}
        // renderValue={(selected) => {
        //   if (selected.length === 0) {
        //     return <span>{placeholder}</span>;
        //   }

        //   return selected;
        // }}
        input={<StyledSelectField />}
      >
        <MenuItem value={defaultValue}>
          <span>{placeholder}</span>
        </MenuItem>
        {memu &&
          memu.map((val, index) => (
            <MenuItem key={index} value={val.key}>
              {val.value}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default InputDropDown;
