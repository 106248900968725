import { useMutateData, useCustomQuery, useMutateDownload } from "shared/services/base.service";
import { saveAs } from "utils/download-file";
const BaseServicePathStandard = process.env.REACT_APP_API_STANDARD;
const BaseServicePathMaster = process.env.REACT_APP_API_PREFIX;

const ExcelDownloadApiService = () => ({
  // GET LOGICAL OPTION
  useGetExcelDownloadLogOptionQuery: () => {
    const data = useCustomQuery({
      key: "logical-option",
      url: `${BaseServicePathMaster}/category/COMMON/LOGICAL_OPTION/ASC`,
      method: "GET",
      enabled: true,
      data: {},
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  // GET TABLE NAME
  useGetExcelDownloadTableNameQuery: userInfo => {
    const data = useCustomQuery({
      key: "table-name",
      url: `${BaseServicePathStandard}/category/excel-download/table`,
      method: "POST",
      body: userInfo,
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  // GET BOOKS MARKS
  useGetExcelDownloadBookmarksQuery: (userInfo, tableName) => {
    const { data, refetch } = useCustomQuery({
      key: ["book-marks", tableName],
      url: `${BaseServicePathStandard}/category/excel-download/bookmarks`,
      method: "POST",
      body: { ...userInfo, tableName: tableName },
      enabled: !!tableName,
      select: data => data.result,
      // keepPreviousData: true,
    });
    return { data, refetch };
  },
  // POST CREATE Book marks
  useCreateExcelDownloadBookmarksMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: ["book-marks"],
    });
    return async createData => {
      return await mutate({
        url: `${BaseServicePathStandard}/excel-download/bookmarks/create`,
        method: "POST",
        data: createData,
      });
    };
  },
  // POST GET SCHEMA TABLE
  useExcelDownloadSchemaTableMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: ["book-marks"],
    });
    return async schemaData => {
      return await mutate({
        url: `${BaseServicePathStandard}/excel-download/schema`,
        method: "POST",
        data: schemaData,
      });
    };
  },
  //POST DOWNLOAD FILE
  useExcelDownloadToOnDemandMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async (body, type) => {
      return await mutate({
        url: `${BaseServicePathStandard}/excel-download/${type}`,
        method: "POST",
        data: body,
      });
    };
  },
  //POST DOWNLOAD EXCEL FILE
  useExcelDownloadExcelFileMutation: () => {
    const { mutate } = useMutateDownload({
      invalidateKeys: [],
    });
    return async (body, type) => {
      return await mutate(
        {
          url: `${BaseServicePathStandard}/excel-download/${type}`,
          method: "POST",
          data: body,
          responseType: "arraybuffer",
        },
        {
          onSettled: response => {
            saveAs(response);
          },
        }
      );
    };
  },
  //POST DOWNLOAD CSV FILE
  useExcelDownloadCsvFileMutation: () => {
    const { mutate } = useMutateDownload({
      invalidateKeys: [],
    });
    return async (body, type) => {
      return await mutate(
        {
          url: `${BaseServicePathStandard}/excel-download/${type}`,
          method: "POST",
          data: body,
        },
        {
          onSuccess: response => {
            saveAs(response);
          },
        }
      );
    };
  },
  //POST DOWNLOAD FILE
  useExcelDownloadConfirmRecordMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async confirmData => {
      return await mutate({
        url: `${BaseServicePathStandard}/excel-download/confirm`,
        method: "POST",
        data: confirmData,
      });
    };
  },
});

export const {
  useGetExcelDownloadLogOptionQuery,
  useGetExcelDownloadTableNameQuery,
  useGetExcelDownloadBookmarksQuery,
  useCreateExcelDownloadBookmarksMutation,
  useExcelDownloadSchemaTableMutation,
  useExcelDownloadExcelFileMutation,
  useExcelDownloadCsvFileMutation,
  useExcelDownloadToOnDemandMutation,
  useExcelDownloadConfirmRecordMutation,
} = ExcelDownloadApiService();
