import { Grid } from "@mui/material";
import { DatePickerField, DropDown } from "components/UI";
import { FORM_DEFAULT_RETURNING } from "pages/Report/constants/constants";
import React, { useEffect, useState } from "react";
import ButtonAction from "../ButtonAction";

import { useExcelDownloadOnlineMutation, useReportCommonGetRPackageOwnerQuery } from "shared/services/report";
import { responseErrors } from "utils";
import { userProfile } from "constant";
import { useLoadingContext } from "shared/contexts/LoadingContext";
import { waitAndDownloadExcel } from "shared/services/common-dowload/download-helper-service";
import { useSelector } from "react-redux";

export default function ReturningPunctualityReport(props) {
  const userInfo = useSelector(state => state.auth.user);
  const [form, setForm] = useState(FORM_DEFAULT_RETURNING);

  const { data: rPkgOwnerList } = useReportCommonGetRPackageOwnerQuery();

  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(false);

  const generateReport = useExcelDownloadOnlineMutation();
  const { startLoading, stopLoading } = useLoadingContext();
  const handleDownload = async () => {
    try {
      props.setMsgError([]);
      props.setMsgAlert([]);
      const body = {
        userId: userInfo.userName,
        displayName: userInfo.displayName,
        dataOwner: userProfile.dataOwner,
        rPackageOwner: form?.rPkgTypeOwner,
        monthFrom: form?.monthForm,
        monthTo: form?.monthTo,
      };
      const pathReport = "generate-returning-punctuality-report";
      startLoading();
      const response = await generateReport(body, pathReport);
      response.data.docId = response.data.data;
      waitAndDownloadExcel(180, response.data, props.setMsgError, stopLoading);
      setDisabledSubmitBtn(false);
      return;
    } catch (error) {
      if (error.errors && error.errors.length > 0) {
        const res = error.errors?.map(val => {
          const code = val?.code;
          const message = val?.message;

          if (code) {
            return `${code}: ${message}\n`;
          }
          return null;
        });
        props.setMsgError(res);
      } else {
        const msgError = responseErrors(error);
        props.setMsgError(msgError);
      }

      setDisabledSubmitBtn(false);
      stopLoading();
    }
    stopLoading();
  };

  useEffect(() => {
    document.getElementById("select-rpackage-owner").focus();
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={5} style={{ display: "flex", paddingTop: "7px" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>*Report : </strong>
          <DropDown
            id="select-report"
            fullWidth
            required
            defaultValue=""
            placeholder="<Select>"
            menu={[{ key: "1", value: "Returning Punctuality Report" }]}
            value={form?.reportId}
            disabled
          />
        </Grid>
        <Grid item xs={7}></Grid>
        <Grid item xs={5} style={{ display: "flex", paddingTop: "7px" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>*R-Package Owner : </strong>
          <DropDown
            id="select-rpackage-owner"
            fullWidth
            required
            value={form?.rPkgTypeOwner}
            defaultValue=""
            placeholder="<Select>"
            menu={rPkgOwnerList?.map(val => ({
              key: val.rPkgOwnerCompAbbr,
              value: val.rPkgOwnerCompAbbr,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                rPkgTypeOwner: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={7}></Grid>
        <Grid item xs={4} style={{ display: "flex", paddingTop: "3px" }}>
          <strong style={{ margin: "auto 0px", width: "338px" }}>*Month (From) : </strong>
          <DatePickerField
            views={["month", "year"]}
            format="MM/YYYY"
            mask="MM/YYYY"
            fullWidth={true}
            value={form?.monthForm}
            onChange={e =>
              setForm(old => ({
                ...old,
                monthForm: e,
              }))
            }
            required
          />
        </Grid>
        <Grid item xs={2} style={{ margin: "auto 0px", paddingTop: "3px" }}>
          (MM/YYYY)
        </Grid>
        <Grid item xs={4} style={{ display: "flex", paddingTop: "3px" }}>
          <strong style={{ margin: "auto 0px", width: "338px" }}>*Month (To) : </strong>
          <DatePickerField
            views={["month", "year"]}
            format="MM/YYYY"
            mask="MM/YYYY"
            fullWidth={true}
            value={form?.monthTo}
            onChange={e =>
              setForm(old => ({
                ...old,
                monthTo: e,
              }))
            }
            required
          />
        </Grid>
        <Grid item xs={2} style={{ margin: "auto 0px", paddingTop: "3px" }}>
          (MM/YYYY)
        </Grid>
        <ButtonAction
          handleDownload={handleDownload}
          setFrom={setForm}
          defaultFrom={FORM_DEFAULT_RETURNING}
          disabled={disabledSubmitBtn}
          setDisabled={setDisabledSubmitBtn}
          clearMsg={() => {
            props.setMsgAlert([]);
            props.setMsgError([]);
          }}
          firstField={"select-rpackage-owner"}
        />
      </Grid>
    </>
  );
}
